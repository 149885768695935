import uuid from 'src/nativeModules/UUID';
import Events from 'src/logging/Events';
import moment from 'moment';
import type {ScreenProps} from 'src/types/Screen';
import TransactionActions from 'src/actions/TransactionActions';
import CartStore from 'src/stores/CartStore';
import Util from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import NavActions from 'src/actions/NavActions';
import AccountConstants from 'src/constants/AccountConstants';
import AppRoutes from 'src/AppRoutes';
import TransactionStore from 'src/stores/TransactionStore';
import CapSvrApi from 'src/api/CapSvrApi';
import type {CartItem} from 'src/types/TransactionDetail';
import type {TimeSlotType} from 'src/types/Menu';
import {alertError} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import LockerTypes from 'src/constants/LockerTypes';
import {ModifierTypes} from 'src/components/elements/orderAhead/ProductModifier';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import {BalanceTypes} from 'src/types/serverTypes/Account';
import {store} from 'src/redux/store';
import {adjustDefaultBalance} from 'src/redux/slices/accountSlice';
import AppRatingService from 'src/services/AppRatingService';

const SCAN_ERROR = 2007;
const TIME_UNAVAILABLE_STATUS = 'TIME_UNAVAILABLE';
const DC_FREE_TEXT_MODIFIER = 'Phone Number for Locker Pickup';

class CartService {
  clearCart(sessionStartTime: moment.Moment) {
    Events.CartSession.trackEvent(
      sessionStartTime,
      CartStore.LocationId,
      CartStore.LocationName,
      TransactionStore.getNumItems(),
      TransactionStore.getDiscount(),
      TransactionStore.getDueAmount(),
      'CANCELED',
      CartStore.Payments,
      CartStore.TransactionId,
      CartStore.Coupons,
      TransactionStore.getNutritionCalorieTotal(),
      TransactionStore.getNutritionDiscountAmount(),
      TransactionStore.getNutritionDisplayColor(),
      TransactionStore.getNutritionDisplayLetter(),
      TransactionStore.getNutritionPromoName(),
    );
    TransactionActions.shoppingCartCleared();
    CartStore.resetCart();
  }

  async updateCart(
    locationId: string,
    accountId: string,
    coupons: Array<string>,
    items: Array<CartItem>,
    locationType: string,
    newBarcode: string,
    pickupTime: TimeSlotType | null = null,
    preparationMethod: string,
    props: ScreenProps,
    discountCode?: string | null,
  ): Promise<boolean> {
    try {
      // Only populate the pickupDate, if the pickupTime is populated.
      const pickupDate = moment(pickupTime?.date).format('YYYY-MM-DD');
      const response: any = await TransactionActions.updateCart(
        locationId,
        accountId,
        coupons,
        items,
        locationType,
        pickupDate,
        pickupTime?.time ?? '',
        preparationMethod,
        discountCode,
      );

      if (response && response.Status === 'OK') {
        const barcodeMatches =
          !newBarcode ||
          response.Items.some(
            (item) => item.BarCode === newBarcode && item.Status !== 'NRF',
          );

        if (!barcodeMatches) {
          Events.BarcodeNotRecognized.trackEvent(
            newBarcode,
            locationType,
            'OK',
          );
          alertError(
            `${Localized.Errors.barcode_not_recognized}\n(${newBarcode})`,
          );
        } else {
          return true;
        }
      } else if (response.errcode === SCAN_ERROR) {
        Events.BarcodeNotRecognized.trackEvent(
          newBarcode,
          locationType,
          'SCAN_ERROR',
        );
        alertError(
          `${Localized.Errors.barcode_not_recognized}\n(${newBarcode})`,
        );
      } else {
        throw new Error(JSON.stringify(response));
      }
    } catch (e) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'CartService:UpdateCart',
        e.message ? e.message : e.toString(),
        guid,
        {
          barcode: newBarcode,
        },
      );
      alertError(Localized.Errors.error, guid);
    } finally {
      props.actions.hideSpinner();
    }

    return false;
  }

  async checkout(
    props: ScreenProps,
    locationType: string,
    salesContext: string | null = null,
    pickupTime: TimeSlotType | null = null,
    preparationMethod = '',
    pickupInstructions: string | null = null,
    handleTimeUnvailable: ((response: any) => void) | null = null,
    orderNote: string | null = null,
    defaultPreparationMethod: PreparationMethodValues = PreparationMethodValues.None,
    balanceType: string = BalanceTypes.Default,
    discountCode?: string | null,
  ) {
    try {
      // Only populate the pickupDate, if the pickupTime is populated.
      const pickupDate = pickupTime
        ? moment(pickupTime.date).format('YYYY-MM-DD')
        : null;
      const numItems = TransactionStore.getNumItems();
      const dueAmount = TransactionStore.getDueAmount();
      const transactionId = CartStore.TransactionId;
      const payments = CartStore.Payments;
      const pickupLocation = TransactionStore.getPickupLocationName();
      const pickupLocationId = TransactionStore.getPickupLocationId();
      const totalCalories = TransactionStore.getNutritionCalorieTotal();
      const healthyLivingDiscount =
        TransactionStore.getNutritionDiscountAmount();
      const displayColor = TransactionStore.getNutritionDisplayColor();
      const displayLetter = TransactionStore.getNutritionDisplayLetter();
      const promoName = TransactionStore.getNutritionPromoName();

      if (!pickupInstructions) {
        pickupInstructions = AccountStore.getPickupInstruction();
      }

      const isValid = this.validateDataIfDCSale(preparationMethod);
      if (!isValid) {
        return;
      }
      Events.CartSession.trackEvent(
        CartStore.SessionStartTime,
        CartStore.LocationId,
        CartStore.LocationName,
        numItems,
        0,
        dueAmount,
        'SUBMITTED',
        payments,
        transactionId,
        [],
        totalCalories,
        healthyLivingDiscount,
        displayColor,
        displayLetter,
        promoName,
      );

      const transaction = TransactionStore.getTransaction();

      const transDate = Util.getCurrentDate();

      const response = await TransactionActions.checkout(
        CartStore.LocationId,
        AccountStore.getAccountId(),
        payments,
        CartStore.Coupons,
        TransactionStore.getAllItems(),
        locationType,
        transDate,
        transactionId,
        CartStore.SequenceNumber,
        CartStore.LocationName,
        pickupTime?.time ?? '',
        preparationMethod,
        pickupDate,
        pickupLocation,
        pickupLocationId,
        orderNote,
        defaultPreparationMethod,
        balanceType,
        salesContext,
        discountCode,
      );

      if (response && response.Status === 'OK') {
        const discount = response.PromotionDiscount * 1;
        Events.CartSession.trackEvent(
          CartStore.SessionStartTime,
          CartStore.LocationId,
          CartStore.LocationName,
          numItems,
          discount,
          dueAmount,
          'CHECKOUT',
          payments,
          transactionId,
          [],
          totalCalories,
          healthyLivingDiscount,
          displayColor,
          displayLetter,
          promoName,
        );

        if (balanceType === BalanceTypes.Default) {
          store.dispatch(
            adjustDefaultBalance({
              amount: -Number(response.Total),
              reason: Localized.Labels.purchase,
            }),
          );
        }

        await AppRatingService.showPrompt();
        NavActions.replace(AppRoutes.PurchaseHistoryDetail, {
          transaction,
          transactionId: CartStore.TransactionId,
          transactionType: AccountConstants.SALE_TYPE,
          checkout: true,
          pickupNotes: pickupInstructions,
          pickupLocation,
          pickupTime: {
            ...pickupTime,
            date: moment(pickupTime?.date ?? undefined).format('YYYY-MM-DD'),
          },
          locationName: CartStore.LocationName,
          payments,
          transactionDate: transDate,
          discount,
        });
      } else if (
        response &&
        response.Status === TIME_UNAVAILABLE_STATUS &&
        handleTimeUnvailable
      ) {
        handleTimeUnvailable(response.AvailableTimes);
      } else {
        alertError(Localized.Errors.error_finalizing_order);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'CartService:Checkout',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.error_finalizing_order, guid);
    } finally {
      props.actions.hideSpinner();
    }
  }

  async checkCartForZeroInventory(
    _: ScreenProps,
    locationId: string,
    displayItems: Array<CartItem>,
  ) {
    try {
      const itemIds = displayItems.map((item) => item.Id || '');
      const response = await CapSvrApi.checkCartForZeroInventory(
        locationId,
        itemIds,
      );
      let cartItemsAvailable = true;

      if (response?.return === 'ok') {
        const soldOutItems: Array<string> = response?.items
          .filter((item) => item.hidezeroinv === 'Y' && item.qtyonhand <= 0)
          .map((item) => item.product);

        if (Array.isArray(soldOutItems) && soldOutItems.length) {
          cartItemsAvailable = false;
        }

        const items = displayItems.map((item) =>
          soldOutItems.includes(item.Id) ? {...item, SoldOut: true} : item,
        );
        TransactionActions.productSoldOutUpdated(items);
        return cartItemsAvailable;
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'CartService:checkCartForZeroInventory',
        error.message ? error.message : error.toString(),
      );
    }
  }

  //Validates the data if the order is DC Locker Order
  validateDataIfDCSale(preparationMethod: string) {
    try {
      if (
        AccountStore.getLocationLockerType() === LockerTypes.DC &&
        preparationMethod === PreparationMethodValues.Locker
      ) {
        // For DC Locker Orders, Ensure Phone number is included
        let phoneNumberFound = false;
        TransactionStore.getAllItems()?.forEach((item) => {
          item?.Modifiers?.forEach((mod) => {
            if (
              mod.Type === ModifierTypes.FreeText &&
              mod.ModifierName === DC_FREE_TEXT_MODIFIER &&
              mod.Name !== ''
            ) {
              phoneNumberFound = true;
            }
          });
        });

        if (!phoneNumberFound) {
          alertError(Localized.Errors.please_add_phone_number);
          return false;
        }
      }
      return true;
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'CartService:validateDataIfDCSale',
        error.message ? error.message : error.toString(),
      );
      return false;
    }
  }
}

export default new CartService();
