import moment, {Moment} from 'moment';
import {Platform} from 'react-native';
import AccountApi from 'src/api/AccountApi';
import AppApi from 'src/api/AppApi';
import AVLiveApi from 'src/api/AVLiveApi';
import EatItApi from 'src/api/EatItApi';
import GmaApi from 'src/api/GmaApi';
import MoblicoApi from 'src/api/MoblicoApi';
import PaymentApi from 'src/api/PaymentApi';
import PlatformApi from 'src/api/PlatformApi';
import ReceiptApi from 'src/api/ReceiptApi';
import AccountConstants from 'src/constants/AccountConstants';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import {authStore} from 'src/init';
import Events from 'src/logging/Events';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import uuid from 'src/nativeModules/UUID';
import {
  fetchAccount,
  fetchPaymentCredentials,
  logout,
} from 'src/redux/slices/accountSlice';
import {store} from 'src/redux/store';
import MessageRepository from 'src/services/aws/MessageRepository';
import DealService from 'src/services/DealService';
import MoblicoService from 'src/services/MoblicoService';
import MoblicoTokenService from 'src/services/MoblicoTokenService';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import {CCModel} from 'src/types/CCModel';
import {MessageType} from 'src/types/MessageType';
import {MoblicoPromotionType} from 'src/types/Promotions';
import {
  AccountUpdateModel,
  Auth0Signup,
  SetupModel,
} from 'src/types/SetupModel';
import {MarketAccountIdentifierTypes} from 'src/types/MarketAccountIdentifier';
import {getMobileAuth0Client, getWebAuth0Client} from '../nativeModules/Auth0';
import ReferralActions from './ReferralActions';
import Util from '../Util';
import {loadGiftFeed} from '../redux/thunks/snackThunks';
import Localized from 'src/constants/AppStrings';

const BALANCE_THRESHOLD_SECONDS = 15;

const GooglePay = {
  App: 'google-pay-app',
  Web: 'google-pay-web',
};

class AccountActions {
  lastUpdated!: Moment;
  newBalanceChecked = 0;

  async setupAccount(
    setupModel: SetupModel,
    email: string,
    pinRequired: boolean,
  ) {
    const response = await AppApi.setupAccount(setupModel);

    if (response) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.PIN_CHANGED,
        data: {
          pinRequired: pinRequired,
          username: email,
        },
      });
      this.getCurrentPrivacyVersion()
        .then((resp) => {
          if (resp.status === 'ok') {
            this.acceptPrivacy(
              setupModel.accountId,
              resp.version,
              Util.getCurrentDate(),
            );
          }
        })
        .catch((error) => {
          Events.Error.trackEvent(
            'Exception',
            'CreateAccountPrivacyPolicyHandler:getCurrentPrivacyVersion',
            error.message ? error.message : error.toString(),
          );
        });
    }

    return response;
  }

  async setupAccountAuth0(signupModel: Auth0Signup) {
    const response = await AccountApi.setupAccountAuth0(signupModel);
    return response;
  }

  verifyEmail(
    email: string,
    buildType: string,
    locale: string,
    location: string,
    version: string,
    referralId: string | null | undefined,
  ) {
    return AccountApi.verifyEmail(
      email,
      buildType,
      locale,
      location,
      version,
      referralId,
    );
  }

  acceptPrivacy(accountId: string, version: string, date: string) {
    return AccountApi.acceptPrivacy(accountId, version, date);
  }

  async getCurrentPrivacyVersion(accountId?: string) {
    try {
      const response = await GmaApi.getCurrentPrivacyVersion(accountId ?? '');
      if (response.status === 'ok') {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.PRIVACY_VERSION_LOADED,
          data: {
            response,
          },
        });
      }
      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:getCurrentPrivacyVersion',
        error.message ? error.message : error.toString(),
        guid,
        {
          accountId,
        },
      );
    }
  }

  async getAccountLocation(locationId: string) {
    try {
      const response = await PlatformApi.fetchLocation(locationId);
      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        error.toString(),
        guid,
      );
    }
  }

  async getAccountPromotions(locationId: string) {
    try {
      const response = await AppApi.loadPromotionsV2(locationId);
      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        error.toString(),
        guid,
      );
    }
  }

  async getAccountOrg(locationId: string) {
    if (!locationId) {
      return;
    }

    try {
      const response = await PlatformApi.fetchOrg(locationId);
      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        error.toString(),
        guid,
      );
    }
  }

  async getAccountDetails(response) {
    try {
      const location = await this.getAccountLocation(response.locationId);
      const promotions = await this.getAccountPromotions(response.locationId);
      const orgDetails = await this.getAccountOrg(response.orgId);
      return {
        ...response,
        ...location,
        ...promotions,
        orgUserKey: orgDetails?.userKey,
      };
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        error.toString(),
        guid,
      );
    }
  }

  async loginLegacy(shortCircuit = false) {
    const accountId = await authStore.getAccountId();
    const response = await AccountApi.fetchAccount(accountId ?? '');

    if (response && response?.accountId) {
      const accountData = await this.getAccountDetails(response);

      if (accountData && shortCircuit) {
        return accountData;
      }
      await this.finishLogin(accountData);
      return accountData;
    }
  }

  async logout() {
    try {
      if ((await authStore.getAccessToken()) !== null) {
        if (Platform.OS === 'web') {
          await authStore.clearSession();
          getWebAuth0Client().logout({
            returnTo: window.location.origin,
          });
        } else if (Platform.OS === 'android') {
          await getMobileAuth0Client().webAuth.clearSession();
        }
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();

      Events.Error.trackEvent(
        'Exception',
        'Logout:Error',
        error.toString(),
        guid,
      );
    }
    AccountStore.logout();
    store.dispatch(logout());
    await authStore.clearSession();

    AppDispatcher.handleViewAction({
      actionType: AccountConstants.LOGOUT,
    });
  }

  /**currently not in use */
  async loginGma(
    username: string,
    password: string,
    buildType: string,
  ): Promise<any> {
    const response = await GmaApi.loginGma(username, password, buildType);
    await this.finishLogin(response);
    return response;
  }

  async finishLogin(response: any) {
    if (response) {
      const qrResponse = await GmaApi.getGmaQR(response.accountId);
      if (qrResponse.status === 'ok') {
        response.qrcode = qrResponse.data.value;
      }

      if (response.localization.region === 'ITA') {
        const taxResponse = await GmaApi.getTaxId(response.accountId);

        if (
          taxResponse.status === 'ok' &&
          taxResponse.data &&
          taxResponse.data.length > 0
        ) {
          response.taxid = taxResponse.data[0].value;
        } else {
          response.taxid = '';
        }
      }

      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOGIN_GMA_SUCCESS,
        data: {
          response,
        },
      });
    }
  }

  loadAccountByMarketCard(code: string) {
    return GmaApi.loginMarketCard(code);
  }

  loadAccountByQR(qrCode: string) {
    return GmaApi.loginQR(qrCode);
  }

  async addOrUpdateMarketCard(code: string) {
    const marketaccount = AccountStore.getAccountId();
    const currentCardsResponse = await GmaApi.getMki(
      marketaccount,
      MarketAccountIdentifierTypes.ScanCode,
    );

    if (currentCardsResponse.status !== 'ok') {
      return currentCardsResponse;
    }

    const currentCards = currentCardsResponse.data.filter(
      (mkii) => mkii.isdisabled === 'N',
    );

    if (currentCards.some((mkii) => mkii.value === code)) {
      // already have card, don't need to update
      return currentCardsResponse;
    }

    try {
      await AccountApi.addOrUpdateMarketCard(
        marketaccount,
        code,
        MarketAccountIdentifierTypes.ScanCode,
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.MARKET_CARD_UPDATED,
        data: {
          marketCard: code,
        },
      });
      return {status: 'ok'};
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:addOrUpdateMarketCard',
        error.message ? error.message : error.toString(),
        guid,
      );
      return error;
    }
  }

  async deleteCreditCard(
    accountId: string,
    token: string,
    accountBalanceId: string,
    isDefault?: boolean,
  ) {
    try {
      const response = await AppApi.deleteCreditCard(
        accountId,
        token,
        accountBalanceId,
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.DELETE_CREDIT_CARD_SUCCESS,
        data: {
          response,
          isDefault,
        },
      });
      return {status: 'ok'};
    } catch (error) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.DELETE_CREDIT_CARD_FAIL,
        data: {
          error,
        },
      });
      return error;
    }
  }

  async updateDefaultToken(
    accountId: string,
    accountBalanceId: string,
    token: string,
  ) {
    try {
      const response = await AccountApi.updateDefaultToken(
        accountId,
        accountBalanceId,
        token,
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.UPDATE_DEFAULT_TOKEN_SUCCESS,
        data: {
          response,
        },
      });
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:updateDefaultToken',
        error.message ? error.message : error.toString(),
        guid,
        {
          accountId,
          accountBalanceId,
          token,
        },
      );
    }
  }

  async getProcessorCreds(accountId: string) {
    return await AppApi.getPaymentCredentials(accountId);
  }

  async addCreditCardTokenStripe(ccModel: CCModel) {
    const addCardResponse = await PaymentApi.addCreditCard(ccModel);
    return this.addCreditCardSuccess(ccModel, addCardResponse);
  }

  addCreditCardSuccess(ccModel: CCModel, addCardResponse: any) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.ADD_CREDIT_CARD_SUCCESS,
      data: {
        ccModel,
        response: addCardResponse,
      },
    });
    return addCardResponse;
  }

  async addCreditCard(
    ccModel: CCModel,
    paymentCredentials: PaymentCredentials,
    useTally = false,
    isDefault = false,
  ): Promise<any> {
    if (
      Settings.processors[paymentCredentials.type] ===
      Settings.processors.heartland
    ) {
      return this.processHeartland(
        ccModel,
        paymentCredentials,
        useTally,
        isDefault,
      );
    }
  }

  async processHeartland(
    ccModel: CCModel,
    paymentCredentials: PaymentCredentials,
    useTally = false,
    isDefault = false,
  ) {
    const processorUrl = paymentCredentials.url;

    if (processorUrl) {
      const tokenResponse = await PaymentApi.getToken(
        ccModel,
        paymentCredentials.key,
        processorUrl,
      );

      if (tokenResponse.card) {
        ccModel.CardNumberMask = tokenResponse.card.number;
      }

      ccModel.CardNumber = '';
      ccModel.CvvCode = '';

      if (tokenResponse.token_value) {
        await this.ensureDefaultBalance(ccModel.AccountId, useTally);
        const verifyResponse = await AppApi.setupAndAttachToken(
          AccountStore.getAccountBalanceId(),
          ccModel.AccountId,
          '',
          ccModel.Issuer,
          ccModel.ExpirationMonth,
          ccModel.ExpirationYear,
          ccModel.CardNumberMask,
          Settings.processors.heartland,
          tokenResponse.token_value,
          ccModel.BillingZip,
          useTally,
          isDefault,
        );

        if (!verifyResponse?.balanceTokenId) {
          throw verifyResponse;
        }

        if (verifyResponse) {
          return this.addCreditCardSuccess(ccModel, verifyResponse);
        }

        if (verifyResponse && verifyResponse.msg) {
          throw new Error(`Verify Token Failed: ${verifyResponse.msg}`);
        } else {
          throw new Error('Verify Token Failed');
        }
      }

      throw new Error('Unable to tokenize card');
    } else {
      throw new Error('Empty ProcessorUrl!');
    }
  }

  itemVended(mdbKey: string, price: number, discount: number) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.ITEM_VENDED,
      data: {
        key: mdbKey,
        price,
        discount,
      },
    });
  }

  async loadPurchaseHistory(accountId: string, page = 1, pageSize = 25) {
    try {
      let checkedAccountID = accountId;
      if (!accountId || accountId === '-1') {
        checkedAccountID = AccountStore.getAccountId();
      }

      const response = await AppApi.getAccountHistory(
        checkedAccountID,
        (page - 1) * pageSize,
        pageSize,
      );

      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_PURCHASE_HISTORY_SUCCESS_GMA,
        data: {
          response,
          page,
          pageSize,
        },
      });
      return response;
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:loadPurchaseHistory',
        error.message ? error.message : error.toString(),
      );
    }
  }

  emailReceipt(
    email: string,
    transactionId: string,
    type: string,
    region: string,
    balance: number,
  ): Promise<any> {
    return ReceiptApi.sendEmailReceipt(
      email,
      transactionId,
      type,
      region,
      balance,
    );
  }

  visitedEatItOrDeleteItWelcomeScreen() {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.VISITED_EAT_IT_OR_DELETE_IT_WELCOME,
    });
  }

  async getLocationByBeacon(code, gmaID) {
    return await AccountApi.beaconLookUp(code, gmaID);
  }

  deviceLookUp(locationId: string, query: string) {
    return AccountApi.deviceLookUp(locationId, query);
  }

  async getLocation(id: string, mkaId?: string) {
    const response = await PlatformApi.getLocationFromId(id);
    if (response) {
      const orgDetails = await this.getAccountOrg(response.orgId);
      if (mkaId && mkaId !== '-1') {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.LOCATION_UPDATED,
          data: {...response, orgUserKey: orgDetails?.userKey},
        });
      } else {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.SELECT_SHOP,
          data: {...response, orgUserKey: orgDetails?.userKey},
        });
      }
    }

    return response;
  }

  async getLocationV1(id: string, mkaId?: string) {
    try {
      const response = await GmaApi.getLocationFromId(id, mkaId);
      return response;
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:fetchLocation',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async addLocationLink(accountId: string, locationId: string) {
    try {
      await AccountApi.addLocationLink(accountId, locationId);

      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOCATION_LINK_ADDED,
        data: {id: locationId},
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:addLocationLink',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async removeLocationLink(marketAccount: string, location: any) {
    const response = await GmaApi.removeLocationLink(
      marketAccount,
      location.deviceId,
    );

    if (response.status === 'ok') {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOCATION_LINK_REMOVED,
        data: location,
      });
    }

    return response;
  }

  async fetchLinkedLocations(accountId: string) {
    try {
      const response = await AccountApi.getLinkedLocations(accountId);
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LINKED_LOCATIONS_FETCHED,
        data: {
          response,
        },
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:fetchLinkedLocations',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async fetchNearbyLocations(latitude: number, longitude: number) {
    try {
      const response = await PlatformApi.fetchNearbyLocations(
        latitude,
        longitude,
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.NEARBY_LOCATIONS_FETCHED,
        data: {
          response,
        },
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:fetchNearbyLocations',
        error.message ? error.message : error.toString(),
      );
    }
  }

  emailFundingReceipt(
    to: string,
    subject: string,
    amount: string,
    date: string,
    time: string,
    location: string,
    transID: string,
    newBalance: string,
    firstName: string,
  ): Promise<any> {
    return ReceiptApi.sendFundingReceipt(
      to,
      subject,
      amount,
      date,
      time,
      location,
      transID,
      newBalance,
      firstName,
    );
  }

  emailRefundReceipt(
    to: string,
    amount: string,
    date: string,
    time: string,
    last4ofCC: string,
    transID: string,
    newBalance: string,
  ): Promise<any> {
    return ReceiptApi.sendRefundReceipt(
      to,
      Settings.buildType,
      Settings.getLocale(),
      amount,
      date,
      time,
      last4ofCC,
      transID,
      newBalance,
    );
  }

  issueRefund(
    transactionId: string,
    marketAccount: string,
    transDate: string,
    accountBalanceId: string,
  ): Promise<any> {
    return AppApi.issueRefund(
      transactionId,
      marketAccount,
      transDate,
      accountBalanceId,
    );
  }

  updateAppEvent(
    accountId: string,
    eventName: string,
    utcEventDate: string,
    localEventDate: string,
    localEventDateTZ: string,
    additionalData?: string,
  ): Promise<any> {
    return AppApi.updateAppEvent(
      accountId,
      eventName,
      utcEventDate,
      localEventDate,
      localEventDateTZ,
      additionalData,
    );
  }

  deleteAccount(accountId: string, email: string) {
    return AppApi.deleteAccount(accountId, email);
  }

  async loadPurchaseDetail(
    accountId: string,
    transactionId: string,
    type: string,
    transactionDate: string,
  ) {
    if (type === AccountConstants.SALE_TYPE) {
      const response = await PlatformApi.loadSaleDetail(
        accountId,
        transactionId,
        transactionDate,
      );

      const updatedResponse = {
        Msg: response.msg,
        Status: response.status,
        ErrorCode: response.errorcode,
        Data: {
          Id: response.transactionId,
          DateString: response.transactionDate,
          Delivery: response.delivery,
          Amount: response.total,
          Tax: response.totalTax,
          Tax1: response.totalTax1,
          Tax1Label: response.tax1Label,
          Tax2: response.totalTax2,
          Tax2Label: response.tax2Label,
          Tax3: response.totalTax3,
          Tax3Label: response.tax3Label,
          Tax4: response.totalTax4,
          Tax4Label: response.tax4Label,
          Deposit: response.totalDeposit,
          Location: response.locationName,
          LocationAddress: response.address,
          LocationCity: response.city,
          LocationState: response.state,
          PickupNotes: response.pickupInstructions,
          Points: response.points,
          PickupTime: response.pickupTime,
          PickupDate: response.pickupDate,
          OrderStatus: response.orderAheadStatus,
          PickupLocation:
            response.pickupLocationName ?? response.pickupLocationId,
          IsPaid: response.ispaid,
          Timer: response.timer,
          Discount: response.discount,
          Items: response.salesItems.map((item) => ({
            Id: item.salesItemId,
            Name: item.name,
            Price: item.price,
            Quantity: item.quantity,
            Modifiers: item.modifiers
              ? item.modifiers.map((modifier) => ({
                  Id: modifier.id,
                  ModifierName: modifier.modifiername,
                  Name: modifier.name,
                  Price: modifier.price,
                }))
              : [],
            TransactionChargeName: response.salesCharges
              ? Localized.Labels.sup_fee
              : '',
            TransactionChargeAmount: response.salesCharges
              .filter((a) => a.salesItemId === item.salesItemId)
              .map((a) => a.calculatedAmount)
              .reduce((a, b) => a + b, 0),
          })),
          charityAmount: response.charityAmount,
          Payments: response.salesPayments
            ? response.salesPayments.map((payment) => ({
                Id: payment.transactionId,
                Amount: payment.amount,
                Type: payment.type,
              }))
            : [],
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_SALE_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (
      type === AccountConstants.FUNDING_TYPE ||
      type === AccountConstants.REDEMPTION_TYPE
    ) {
      const response = await PlatformApi.loadFundingDetail(
        accountId,
        transactionId,
        transactionDate,
      );
      const updatedResponse = {
        Status: response.status,
        Msg: response.msg,
        ErrorCode: response.errorcode,
        Data: {
          Id: response.transactionId,
          DateLong: response.date,
          DateString: response.transactionDate,
          Amount: response.amount,
          CardIssuer: response.creditCardType,
          Name: response.creditCardPan,
          Processor: response.processor,
          Status: response.status,
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_FUNDING_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (type === AccountConstants.REFUND_TYPE) {
      const response = await PlatformApi.loadRefundDetail(
        accountId,
        transactionId,
        transactionDate,
      );
      const updatedResponse = {
        Status: response.status,
        Msg: response.msg,
        ErrorCode: response.errorcode,
        Data: {
          Id: response.transactionId,
          DateLong: response.date,
          DateString: response.transactionDate,
          Amount: response.amount,
          CardIssuer: response.creditCardType,
          Name: response.creditCardPan,
          Processor: response.processor,
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_REFUND_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (
      type === AccountConstants.REFERRAL_TYPE ||
      type === AccountConstants.REFERRAL_SIGNUP_TYPE
    ) {
      const response = await ReferralActions.fetchReferralSummary(
        transactionId,
      );
      const updatedResponse = {
        Status: response.status,
        Msg: response?.msg,
        ErrorCode: response?.errorcode,
        Data: {
          referralId: response.referralId,
          orgId: response.orgId,
          orgName: response.orgName,
          locationId: response.locationId,
          locationName: response.locationName,
          actionType: response.actionType,
          amount: response.amount,
          transactionDate: response.transactionDate,
          transactionId: response.transactionId,
          name: response.name,
          reason: response.reason,
          accountId: response.accountId,
          balance: response.balance,
          dateCreated: response.dateCreated,
          currency: response.currency,
          referenceId: response.referenceId,
          receiver: response.receiver,
          receiverName: response.receiverName,
          sender: response.sender,
          senderName: response.senderName,
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_REFERRAL_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (
      type === AccountConstants.SEND_SNACK ||
      type === AccountConstants.SEND_SNACK_ACCEPT ||
      type === AccountConstants.SEND_SNACK_CANCEL ||
      type === AccountConstants.SEND_SNACK_EXPIRE ||
      type === AccountConstants.SEND_SNACK_REVERSE ||
      type === AccountConstants.TRANSFER_IN ||
      type === AccountConstants.TRANSFER_OUT ||
      type === AccountConstants.ADJUSTMENT
    ) {
      return {
        Data: {
          transactionId,
          DateString: transactionDate,
          type,
        },
      };
    }

    return Promise.resolve();
  }

  async pinChanged(accountId: string, pin: string, pinRequired: boolean) {
    await AccountApi.setPin(accountId, pin);
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.PIN_CHANGED,
      data: {
        pinRequired,
      },
    });
    return Promise.resolve(true);
  }

  async verifyPin(accountId: string, pin: string, qrValue: string) {
    const response = await AccountApi.verifyPinQR(pin, qrValue);
    return (
      response &&
      (response?.accountId === accountId || response?.id === accountId)
    );
  }

  verifyPinScanCode(pin: string, scanCode: string) {
    return AccountApi.verifyPinScanCode(pin, scanCode);
  }

  getAccountInfoByTally(accountId: string) {
    return AccountApi.fetchAccountTally(accountId);
  }

  async getRedemptionThresholds(accountId: string) {
    const response = await AppApi.fetchRedemptionThresholds(accountId);
    if (response?.length > 0) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.THRESHOLDS_LOADED,
        data: {
          response: {
            data: response.map((i) => ({
              ...i,
              additionalpoints: i.additionalPoints,
              redeemvalue: i.redeemValue,
            })),
            msg: 'success',
            status: 'ok',
          },
        },
      });
    }

    return response;
  }

  async redeemRewards(accountId: string, points: number, transDate: string) {
    const response = await AccountApi.redeemRewards(
      accountId,
      points,
      transDate,
      Settings.buildType,
    );
    await this.getBalance(accountId);
    return response;
  }

  async loadPromotions(locationId: string) {
    const response = await AppApi.loadPromotions(locationId);
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.PROMOTIONS_LOADED,
      data: {
        response,
      },
    });
    return response;
  }

  async loadMoblicoPromotions(email: string) {
    try {
      const token = await MoblicoTokenService.getToken(email);
      const originalResponse: Array<MoblicoPromotionType> =
        await MoblicoApi.getPromos(token);
      const dealResponse: Array<MoblicoPromotionType> =
        DealService.matchPromosWithDeals(
          AccountStore.getAccountId(),
          originalResponse,
        );
      const filteredResponse: Array<MoblicoPromotionType> =
        DealService.removePromosWithRedeemedDeals(dealResponse);
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.MOBLICO_PROMOS_LOADED,
        data: {
          response: filteredResponse,
        },
      });
    } catch (error) {
      // Only log the error if it's something other than a 404 from the request.
      if (
        error &&
        error.networkResponse &&
        error.networkResponse.status !== 404
      ) {
        Events.Error.trackEvent(
          'Exception',
          'AccountActions:loadMoblicoPromotions',
          error.message ? error.message : error.toString(),
        );
      }
    }
  }

  acceptMoblicoDeal(email: string, accountId: string, id: number) {
    return DealService.acceptMoblicoDeal(email, id);
  }

  async reloadConsumerData({
    accountId,
    accountBalanceId,
    email,
    firstLauch,
    newBalance,
    userInitiated,
  }: {
    accountId: string;
    accountBalanceId: string;
    email: string;
    firstLauch?: boolean;
    newBalance?: number;
    userInitiated?: boolean;
  }) {
    try {
      if (userInitiated || this.shouldUpdateAccount(newBalance)) {
        this.lastUpdated = moment();
        store.dispatch(fetchAccount(accountId));
        store.dispatch(fetchPaymentCredentials(accountId));
        this.getRedemptionThresholds(accountId);
        this.retrieveAutoFund(accountId, accountBalanceId);
        const privacyVersion = await this.getCurrentPrivacyVersion(accountId);
        this.loadPurchaseHistory(accountId, 1);
        this.loadReferralDetails();

        if (AccountStore.getLocationId()) {
          this.getLocation(
            AccountStore.getLocationId(),
            AccountStore.getAccountId(),
          );
          this.loadPromotions(AccountStore.getLocationId());
          store.dispatch(
            loadGiftFeed({
              locationId: AccountStore.getLocationId(),
            }),
          );
        }

        const balanceResponse: any = await this.getBalance(
          accountId,
          true,
          true,
          firstLauch,
        );
        // Need to call this after the balance/account has been loaded
        if (
          AccountStore.isConsumerEngagementEnabled() &&
          balanceResponse.consumerEngagementId
        ) {
          // Load deals first because promotions depend on deals.
          await DealService.loadMoblicoDeals(email);
          this.loadMoblicoPromotions(email);
        }

        return {...balanceResponse, ...privacyVersion};
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:reloadConsumerData',
        error.message ? error.message : error.toString(),
        guid,
        {
          accountId,
          accountBalanceId,
          email,
          firstLauch,
          newBalance,
          userInitiated,
        },
      );
    }
    return Promise.resolve();
  }

  async loadReferralDetails() {
    if (AccountStore.getLocationId()) {
      try {
        const result = await ReferralActions.getCampaignDetails(
          AccountStore.getLocationId(),
          true,
        );
        const referralCampaign = result?.items ? result?.items?.[0] : undefined;
        const referralStat = await ReferralActions.getReferralStats(
          AccountStore.getAccountId(),
        );
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.REFERRAL_DETAILS_LOADED,
          data: {
            referralStat,
            referralCampaign,
          },
        });
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'AccountActions:loadReferralDetails',
          error.message ? error.message : error.toString(),
        );
      }
    }
  }

  // never gets here
  removeRecentOrder(index: number) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.REMOVE_RECENT_ORDER,
      data: {
        index,
      },
    });
  }

  shouldUpdateAccount(newBalance?: number) {
    if (!this.lastUpdated) {
      return true;
    }

    if (
      newBalance &&
      newBalance !== store.getState().account.account.defaultBalance.balance &&
      newBalance !== this.newBalanceChecked
    ) {
      // Record that we've already fetched this new balance, so we don't fetch it again
      this.newBalanceChecked = newBalance;
      return true;
    }

    const threshold = moment().subtract({
      seconds: BALANCE_THRESHOLD_SECONDS,
    });
    return this.lastUpdated.isBefore(threshold);
  }

  async getBalance(
    accountId: string,
    forceUpdate?: boolean,
    dispatch = true,
    firstLaunch = false,
  ) {
    if (forceUpdate || this.shouldUpdateAccount()) {
      try {
        let data: any;
        const response = await AccountApi.fetchAccount(accountId);

        if (response && response?.accountId) {
          try {
            const accountData = await this.getAccountDetails(response);
            if (accountData) {
              if (dispatch) {
                AppDispatcher.handleViewAction({
                  actionType: AccountConstants.GET_BALANCE_SUCCESS,
                  data: accountData,
                });
              }

              try {
                // This needs to be after the dispatch so data in the AccountStore is populated
                // ORIGINAL CODE FOR MOBLICO
                // const consumerEngagementId = await MoblicoService.registerUser(
                //   response.email,
                //   response.accountId,
                //   response.consumerEngagementId,
                //   response.orgId,
                //   response.locationId,
                //   firstLaunch,
                // );
                const consumerEngagementId = await MoblicoService.registerUser(
                  accountData.email,
                  accountData.accountId,
                  accountData.consumerEngagementId,
                  accountData.orgId,
                  accountData.locationId,
                  firstLaunch,
                );
                // ORIGINAL CODE FOR MOBLICO
                // if (consumerEngagementId && consumerEngagementId !== response.consumerEngagementId))
                if (
                  consumerEngagementId &&
                  consumerEngagementId !== accountData.consumerEngagementId
                ) {
                  AppDispatcher.handleViewAction({
                    actionType: AccountConstants.CONSUMER_ENGAGEMENT_REGISTER,
                    data: consumerEngagementId,
                  });
                }
              } catch (error) {
                Events.Error.trackEvent(
                  'Exception',
                  'AccountActions:getBalance',
                  error.message ? error.message : error.toString(),
                );
              }
            } else {
              AppDispatcher.handleViewAction({
                actionType: AccountConstants.LOGOUT,
              });
            }
            data = accountData;
          } catch (error) {
            AppDispatcher.handleViewAction({
              actionType: AccountConstants.GET_BALANCE_ERROR,
              data: {
                error,
              },
            });
          }
        } else if (!response) {
          AppDispatcher.handleViewAction({
            actionType: AccountConstants.LOGOUT,
          });
        }

        return data;
      } catch (error) {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.GET_BALANCE_ERROR,
          data: {
            error,
          },
        });
      }
    } else {
      return {};
    }
  }

  async ensureDefaultBalance(accountId: string, useTally = false) {
    const balanceId = AccountStore.getAccountBalanceId();

    if (!balanceId) {
      try {
        // Create a balance if we can and update the account tokens.
        const balance = await AccountApi.convertLegacyBalanceAndTokens(
          accountId,
          useTally,
        );
        AccountStore.setAccountBalanceId(balance.accountBalanceId);
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'AccountActions:ensureDefaultBalance',
          error.message ? error.message : error.toString(),
        );
      }
    }
  }

  async addFunds(tokenId: string, amount: number, transdate: string) {
    const accountId = AccountStore.getAccountId();
    await this.ensureDefaultBalance(accountId);
    const response = await AppApi.addFundsToken(
      accountId,
      AccountStore.getAccountBalanceId(),
      tokenId,
      amount,
      transdate,
    );
    return response.status === 'ok';
  }

  async createPaymentIntent(
    accountId: string,
    token: string,
    amount: number,
    transdate: string,
  ) {
    return await PaymentApi.createPaymentIntent(
      accountId,
      token,
      amount,
      transdate,
    );
  }

  async confirmPaymentIntent(
    accountId: string,
    paymentIntentId: string,
    transId: string,
  ) {
    return await PaymentApi.confirmPaymentIntent(
      accountId,
      paymentIntentId,
      transId,
    );
  }

  async addFundsPayroll(
    accountId: string,
    accountBalanceId: string,
    payrollIdentifierId: string,
    amount: number,
    transdate: string,
    transid = '',
  ) {
    return await AppApi.addFundsPayrollDeduct(
      accountId,
      accountBalanceId,
      amount,
      transdate,
      payrollIdentifierId,
      transid,
    );
  }

  chargeCard(
    accountId: string,
    amount: number,
    locationId: string,
    accountBalanceId: string,
    balanceTokenId: string,
  ): Promise<any> {
    return AppApi.chargeCard(
      accountId,
      amount,
      locationId,
      accountBalanceId,
      balanceTokenId,
    );
  }

  setupAutoFund(
    accountId: string,
    accountBalanceId: string,
    token: string,
    amount: number,
    fallBelowAmount: number,
  ) {
    return AccountApi.setupAutoFund(
      accountId,
      accountBalanceId,
      token,
      amount,
      fallBelowAmount,
    );
  }

  async retrieveAutoFund(accountId: string, accountBalanceId: string) {
    try {
      const response = await AccountApi.retrieveAutoFund(
        accountId,
        accountBalanceId,
      );

      if (response?.statusCode === 404) {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.AUTO_FUND_LOADED,
          data: {
            response: null,
          },
        });
        return;
      }

      if (response) {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.AUTO_FUND_LOADED,
          data: {
            response: {
              ...response,
              isDisabled: response.isDisabled ? 'Y' : 'N',
            },
          },
        });
      }

      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:retrieveAutoFund',
        error.message ?? error.toString(),
        guid,
        {
          accountId,
          accountBalanceId,
        },
      );
    }
  }

  turnOffAutoFund(accountId: string, accountBalanceId: string) {
    return PaymentApi.turnOffAutoFund(accountId, accountBalanceId);
  }

  addFundsApplePay(
    apObject: any,
    accountId: string,
    _accountBalanceId: string,
    amount: number,
    transdate: string,
    test: boolean,
    processor: number,
  ) {
    Events.Info.trackEvent('AccountActions:addFundsApplePay', {
      apObject,
      accountId,
      accountBalanceId: AccountStore.getAccountBalanceId(),
      amount,
      transdate,
      processor,
      test,
    });

    if (processor !== Settings.processors.stripe) {
      if (typeof apObject === 'string') {
        apObject = JSON.parse(apObject);
      }
    }

    return PaymentApi.addFundsApplePay(
      apObject,
      accountId,
      amount,
      transdate,
      test,
      processor,
    );
  }

  addFundsGooglePay(
    token: string,
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transdate: string,
    test: boolean,
    processor: number,
  ) {
    return AppApi.addFundsThirdParty(
      accountId,
      accountBalanceId,
      amount,
      transdate,
      token,
      processor,
      test,
      Platform.OS === 'web' ? GooglePay.Web : GooglePay.App,
    );
  }

  getServerTime() {
    return AVLiveApi.getServerTime();
  }

  //actionType not use
  useGmaChanged(useGma: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.GMA_CHANGED,
      data: {
        useGma,
      },
    });
  }

  isDebugChanged(debug: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.DEBUG_CHANGED,
      data: {
        isDebug: debug,
      },
    });
  }

  isDemoChanged(demo: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.DEMO_CHANGED,
      data: {
        demo,
      },
    });
  }

  async changePassword(
    accountId: string,
    password: string,
    newPassword: string,
  ) {
    try {
      const response = await AccountApi.changePassword(
        accountId,
        newPassword,
        password,
      );
      if (response?.statusCode === 401) {
        return {
          InvalidPassword: true,
        };
      }
      return {
        Success: true,
      };
    } catch (error) {
      return {
        InvalidPassword: error.statusCode !== 400,
      };
    }
  }

  async validatePin(email: string, pin: string) {
    const validateResponse = await AccountApi.validateEmailPin(email, pin);

    if (validateResponse && validateResponse.accountId) {
      return AccountApi.fetchAccountTally(validateResponse.accountId);
    }

    return validateResponse;
  }

  setPassword(accountId: string, password: string, useTally = false) {
    if (useTally) {
      return AccountApi.tallyChangePassword(accountId, password);
    }
    return AccountApi.changePassword(accountId, password);
  }

  changeEmail(accountId: string, newEmail: string, buildType: string) {
    return AccountApi.changeEmail(accountId, newEmail, buildType);
  }

  async updateInfo(accountId: string, info: AccountUpdateModel) {
    const response = await AccountApi.updateAccount(info, accountId);

    if (response) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.ACCOUNT_INFO_UPDATED,
        data: {
          info,
        },
      });
    }

    return response;
  }

  async updateSendSnackStatus(accountId: string, snackStatus: boolean) {
    let response = await AccountApi.updateSendSnackStatus(accountId, {
      sendaSnackEnabled: snackStatus,
    });

    if (response) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.ACCOUNT_SEND_SNACK_STATUS_UPDATED,
        data: {
          snackStatus: snackStatus,
        },
      });
      response = {...response, sendaSnackEnabled: snackStatus};
    }

    return response;
  }

  setBluetoothChecked(bluetoothChecked: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.BLUETOOTH_CHECKED,
      data: {
        bluetoothChecked,
      },
    });
  }

  retrieveEatItLocation(baseUrl: string, orgUserKey: string) {
    return EatItApi.retrieveEatItLocation(baseUrl, orgUserKey);
  }

  retrieveEatItItems(
    baseUrl: string,
    eatItLocation: string,
    idfa: string,
    count = 5,
  ) {
    return EatItApi.retrieveEatItItems(baseUrl, eatItLocation, idfa, count);
  }

  voteEatItItem(
    baseUrl: string,
    item_id: string,
    locationId: string,
    skip: boolean,
    idfa: string,
    latitude: number,
    longitude: number,
    locationUserKey: string,
    like?: boolean,
  ) {
    return EatItApi.voteEatItItem(
      baseUrl,
      item_id,
      locationId,
      skip,
      idfa,
      latitude,
      longitude,
      locationUserKey,
      like,
    );
  }

  async deleteMessage(message: MessageType) {
    await MessageRepository.deleteMessage(AccountStore.getAccountId(), message);
  }

  async messageRead(message: MessageType) {
    message.read = true;
    await MessageRepository.updateMessage(AccountStore.getAccountId(), message);
  }

  async refreshMessages() {
    await MessageRepository.refresh();
  }

  async createSetupIntent(accountId: string) {
    return await AppApi.createSetupIntent(accountId);
  }

  async validateDiscountCode(locationId: string, scanCode: string) {
    return await PlatformApi.validateDiscountCode(locationId, scanCode);
  }

  async associateDiscountCode(scanCode: string) {
    const accountId = AccountStore.getAccountId();
    return await AccountApi.addOrUpdateMarketCard(
      accountId,
      scanCode,
      MarketAccountIdentifierTypes.ConsumerRole,
    );
  }

  async deleteAssociatedDiscountCode(identifierId: string) {
    const accountId = AccountStore.getAccountId();
    return await AccountApi.deleteAssociatedDiscountCode(
      accountId,
      identifierId,
    );
  }

  async fetchPaymentIntentClientSecret(
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transactionDate: string,
    processor: number,
    test: boolean,
  ) {
    return await AppApi.fetchPaymentIntentClientSecret(
      '',
      accountId,
      accountBalanceId,
      amount,
      transactionDate,
      processor,
      test,
    );
  }
}

export default AccountActions;
