import React from 'react';
import {PixelRatio, ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import AVText from '../../../elements/AVText';
import BaseScreen from '../../BaseScreen';
import ScreenContext from '../../../ScreenContext';
import Util from 'src/Util';
import Styles from '../../../Styles';
import AccountStore from 'src/stores/AccountStore';
import DashedLine from 'react-native-dashed-line';
import AVFormattedCurrency from '../../../elements/AVFormattedCurrency';
import type {ReferralRewardSummary} from 'src/types/Referral';
import Events from 'src/logging/Events';
import type {HistoryItem} from 'src/types/TransactionDetail';
import LoadingScreen from '../../LoadingScreen';
import Localized from 'src/constants/AppStrings';
import ReferralActions from 'src/actions/ReferralActions';
import {alertError} from '../../../helpers/AlertHelper';
type ReferralSummaryScreenProps = WithGlobalizeProps & {
  historyItem: HistoryItem;
};
type ReferralSummaryScreenState = {
  summary: ReferralRewardSummary;
};

class ReferralSummaryScreen extends React.Component<
  ReferralSummaryScreenProps,
  ReferralSummaryScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.renderLabel = this.renderLabel.bind(this);
    this.renderUser = this.renderUser.bind(this);
    this.fetchReferralSummaryDetails =
      this.fetchReferralSummaryDetails.bind(this);
    this.state = {
      summary: null,
    };
  }

  componentDidMount() {
    this.fetchReferralSummaryDetails();
  }

  async fetchReferralSummaryDetails() {
    try {
      const summary = await ReferralActions.fetchReferralSummary(
        this.props.historyItem.Id,
      );
      this.setState({
        summary,
      });
    } catch (e) {
      const errMsg = e.message ? e.message : e.toString();
      Events.Error.trackEvent(
        'Exception',
        'ReferralSummaryScreen:fetchReferralSummaryDetails',
        errMsg,
      );
      alertError(errMsg);
    }
  }

  renderLabel(isSender: boolean) {
    const label = isSender
      ? Localized.Labels.referral_reward
      : Localized.Labels.sign_up_reward;
    return <AVText style={styles.referral}>{label}</AVText>;
  }

  renderUser(isSender) {
    const {summary} = this.state;
    const {label, value} = isSender
      ? {
          label: Localized.Labels.new_sign_up,
          value: summary.receiverName,
        }
      : {
          label: Localized.Labels.referrer_name,
          value: summary.senderName,
        };
    return (
      <AVText style={styles.user}>
        {Localized.Labels.formatString(label, value)}
      </AVText>
    );
  }

  render() {
    const {summary} = this.state;

    if (!summary) {
      return <LoadingScreen />;
    }

    const isSender = summary.sender === AccountStore.getAccountId();
    return (
      <BaseScreen title={'Reward Summary'}>
        <View style={styles.purchaseContent}>
          <ScrollView style={styles.historyContainer}>
            <AVText style={styles.date}>
              {Util.formatDate(summary.transactionDate, 'LLL')}
            </AVText>
            <AVText style={styles.location}>{summary.locationName}</AVText>
            <AVText style={styles.transactionId}>
              {summary.transactionId}
            </AVText>
            <DashedLine
              dashGap={PixelRatio.roundToNearestPixel(4)}
              dashLength={PixelRatio.roundToNearestPixel(2)}
              dashThickness={PixelRatio.roundToNearestPixel(2)}
              dashColor="#999"
              style={styles.dashStyle}
            />
            {this.renderLabel(isSender)}
            <DashedLine
              dashGap={PixelRatio.roundToNearestPixel(4)}
              dashLength={PixelRatio.roundToNearestPixel(2)}
              dashThickness={PixelRatio.roundToNearestPixel(2)}
              dashColor="#999"
              style={styles.dashStyle}
            />
            {this.renderUser(isSender)}
            <View style={[Styles.Style.summarySection, styles.summary]}>
              <View style={Styles.Style.labelColumn}>
                <AVText style={styles.boldLabel}>
                  {Localized.Labels.total}
                </AVText>
              </View>
              <View style={Styles.Style.amountColumn}>
                <AVFormattedCurrency
                  style={styles.boldLabel}
                  value={summary.amount || 0}
                  accessibilityLabel="total"
                  currency={
                    this.props.historyItem.currency ??
                    AccountStore.getCurrency()
                  }
                />
              </View>
            </View>
          </ScrollView>
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  date: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    paddingBottom: Styles.Spacing.m2,
  },
  location: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  purchaseContent: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  historyContainer: {
    alignSelf: 'stretch',
    flex: 1,
  },
  transactionId: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
  },
  boldLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  referral: {
    fontSize: Styles.Fonts.f2,
  },
  user: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    fontWeight: 'bold',
  },
  summary: {
    marginBottom: Styles.Spacing.m1,
  },
  summaryLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
  },
});
export default withForwardedNavigationParams<ReferralSummaryScreenProps>()(
  withGlobalize(ReferralSummaryScreen),
);
