import type {PayrollDeductInfo} from 'src/types/serverTypes/Account';
import {Balance} from './Balance';

export interface PayrollDeductInformation {
  payCycle: string;
  spendLimit: number;
  resetDate: string;
  availableBalance: number;
}

export const defaultPayrollDeductInformation = {
  payCycle: '',
  spendLimit: 0,
  resetDate: '',
  availableBalance: 0,
};
export default class PayrollDeductInformationBuilder {
  static build(
    serverPayrollDeductInfo: PayrollDeductInfo,
    payrollBalance: Balance,
  ): PayrollDeductInformation {
    return {
      payCycle: serverPayrollDeductInfo.payCycle,
      spendLimit: serverPayrollDeductInfo.spendLimit,
      resetDate: serverPayrollDeductInfo.resetDate,
      availableBalance:
        serverPayrollDeductInfo.spendLimit - payrollBalance.balance,
    };
  }
}
