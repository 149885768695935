import React from 'react';
import {SafeAreaView, StyleSheet} from 'react-native';
import {PulseIndicator} from 'react-native-indicators';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {connect} from 'react-redux';
import {compose} from 'redux';
import ActionsFactory from 'src/actions/ActionsFactory';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Localized from 'src/constants/AppStrings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import {get3LetterCountryCode} from 'src/constants/Countries';
import Events from 'src/logging/Events';
import uuid from 'src/nativeModules/UUID';
import Settings from 'src/Settings';
import AVText from '../elements/AVText';
import {alertError} from '../helpers/AlertHelper';
import Styles from '../Styles';
import {WelcomeAuth0Screen} from './WelcomeAuth0Screen';

const mapStateToProps = (_, ownProps) => {
  return {
    context: ownProps.context,
    initialTabRoute: ownProps.initialTabRoute,
    snack: ownProps.snack,
  };
};

type RedirectScreenProps = {
  context: any;
  initialTabRoute: any;
  snack: any;
};

const RedirectScreen = (props: RedirectScreenProps) => {
  React.useEffect(() => {
    (async ({context, initialTabRoute, snack}) => {
      let account = await ActionsFactory.getAccountActions().loginLegacy(true);

      if (
        account.type?.length === 0 ||
        (account.type === 'PANTRY' &&
          Settings.buildType === BuildTypes.pantry) ||
        (account.type !== 'PANTRY' && Settings.buildType !== BuildTypes.pantry)
      ) {
        account = await ActionsFactory.getAccountActions().loginLegacy();
        try {
          if (!(account.localization.region && account.country)) {
            const locale = Settings.getLocale();
            const country = locale.substring(locale.length - 2);
            const code = get3LetterCountryCode(country);

            await ActionsFactory.getAccountActions().updateInfo(
              account.accountId,
              {
                country: account.country ?? code,
                region: account.localization.region ?? code,
              },
            );
          }
        } catch (error) {
          const guid = await uuid.getRandomUUID();

          Events.Error.trackEvent(
            'Error',
            'Redirect:Error',
            error.toString(),
            guid,
          );
        } finally {
          await WelcomeAuth0Screen.onLoginSuccess(
            account,
            context,
            initialTabRoute,
            snack,
          );
        }
      } else {
        alertError(
          Localized.Errors.user_login_credentials_is_not_valid,
          null,
          () => {
            NavActions.reset(AppRoutes.WelcomeAuth0);
            ActionsFactory.getAccountActions().logout();
          },
          Localized.Errors.uh_oh,
        );
      }
    })(props);
  }, []);
  return (
    <SafeAreaView style={styles.background}>
      <PulseIndicator
        size={120}
        color={Styles.bgColor}
        style={styles.indicator}
      />
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
        style={styles.caption}
      >
        {Localized.Labels.redirect_message}
      </AVText>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: Styles.primaryColor,
    minHeight: Styles.ScreenHeight,
    minWidth: Styles.ScreenWidth,
  },
  caption: {
    color: Styles.bgColor,
    fontSize: Styles.Fonts.f5,
    fontWeight: '700',
    marginTop: 0,
    paddingTop: 0,
    marginStart: 8,
    marginEnd: 8,
    textAlign: 'center',
  },
  indicator: {
    flex: 0.5,
  },
});

export default compose(
  withForwardedNavigationParams(),
  connect(mapStateToProps),
)(RedirectScreen);
