import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import CountryDropdown from '../../elements/CountryDropdown';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import {get3LetterCountryCode, getLabel} from 'src/constants/Countries';
import Events from 'src/logging/Events';
import Settings from 'src/Settings';
import type {Referral} from 'src/types/Referral';
import ReferralActions from 'src/actions/ReferralActions';
import Localized from 'src/constants/AppStrings';
import {confirm} from '../../helpers/AlertHelper';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import {AccessibilityInfo, View, StyleSheet} from 'react-native';
import {CountryArray} from 'src/constants/Countries';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Styles from 'src/components/Styles';

type CountrySelectionScreenProps = {
  email: string;
  accountId?: string;
  firstName?: string;
  lastName?: string;
  showPin?: boolean;
  referralId: string | null | undefined;
  token: string | null | undefined;
  navigation?: NavigationProp<CountrySelectionScreen>;
};
type CountrySelectionScreenState = {
  selectedCountry: string;
  countryMenuVisible: boolean;
  referral: Referral | null | undefined;
  previousRoute?: string;
};

class CountrySelectionScreen extends React.Component<
  CountrySelectionScreenProps,
  CountrySelectionScreenState
> {
  defaultCountry: string;
  getDisplayName: (countryCode: string) => string;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: CountrySelectionScreenProps) {
    super(props);
    const locale = Settings.getLocale();
    const country = locale.substring(locale.length - 2);
    this.defaultCountry = get3LetterCountryCode(country);
    this.state = {
      selectedCountry: this.defaultCountry,
      referral: undefined,
      previousRoute: null,
      countryMenuVisible: false,
    };
    this.countrySelected = this.countrySelected.bind(this);
    this.countryDoesntMatch = this.countryDoesntMatch.bind(this);
    this.navigateToAccountSetup = this.navigateToAccountSetup.bind(this);
    this.fetchReferralDetails = this.fetchReferralDetails.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'CountrySelectionScreen', {
      ...this.props,
      ...this.state,
    });

    if (this.props.referralId) {
      this.fetchReferralDetails();
    }
    const previousRoute = getPreviousRouteName(
      this.props?.navigation?.getState()?.routes,
    );
    this.setState({previousRoute: previousRoute});
  }

  handleMenuVisibilityChange = (visible: boolean) => {
    FirebaseAnalytic.trackEvent(
      'handleMenuVisibilityChange',
      'CountrySelectionScreen',
      {
        ...this.props,
        ...this.state,
        visible,
      },
    );
    this.setState({countryMenuVisible: visible});
  };

  async fetchReferralDetails() {
    try {
      const referral = await ReferralActions.getReferralDetails(
        this.props.referralId ?? '',
      );
      FirebaseAnalytic.trackEvent(
        'fetchReferralDetails',
        'CountrySelectionScreen',
        {
          ...this.props,
          ...this.state,
          referral,
        },
      );
      this.setState({
        referral,
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'CountrySelectionScreen:GetReferralDetails',
        error.message ? error.message : error.toString(),
      );
    }
  }

  countryDoesntMatch(country: string) {
    return country !== this.defaultCountry;
  }

  getNameFromCountryCode(countryCode: string) {
    return CountryArray.filter((item) => item.code3 === countryCode);
  }

  countrySelected(countryCode: string) {
    FirebaseAnalytic.trackEvent('countrySelected', 'CountrySelectionScreen', {
      ...this.props,
      ...this.state,
      countryCode,
    });

    const countryNameObject = this.getNameFromCountryCode(countryCode);
    const selectedString = countryNameObject[0].label + ' selected';
    this.setState(
      {
        selectedCountry: countryCode,
        countryMenuVisible: false,
      },
      () => {
        AccessibilityInfo.announceForAccessibility(selectedString);
      },
    );
  }

  handleNext() {
    FirebaseAnalytic.trackEvent('handleNext', 'CountrySelectionScreen', {
      ...this.props,
      ...this.state,
    });

    try {
      if (this.countryDoesntMatch(this.state.selectedCountry)) {
        confirm(
          Localized.Labels.formatString(
            Localized.Labels.verify_country_location,
            getLabel(this.state.selectedCountry),
          ).toString(),
          () => {
            this.navigateToAccountSetup(this.state.selectedCountry);
          },
          undefined,
          Localized.Labels.is_this_correct,
          Localized.Buttons.cancel,
          Localized.Labels.yes,
        );
      } else {
        this.navigateToAccountSetup(this.state.selectedCountry);
      }
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'CountrySelectionScreen:handleNext',
        e.message ? e.message : e.toString(),
      );
    }
  }

  navigateToAccountSetup(country: string) {
    Events.CountrySelected.trackEvent(this.props.email, country);

    FirebaseAnalytic.trackEvent(
      'navigateToAccountSetup',
      'CountrySelectionScreen',
      {
        ...this.props,
        ...this.state,
        country,
        navigation: AppRoutes.AccountSetup,
      },
    );
    NavActions.push(AppRoutes.AccountSetup, {
      country,
      referral: this.state.referral,
      ...this.props,
    });
  }

  render() {
    return (
      <BaseAccountSetupScreen
        previousRoute={this.state.previousRoute}
        headlineText={Localized.Labels.create_account}
        instructionText={Localized.Labels.select_country}
      >
        <View
          accessible={true}
          accessibilityLabel="Country"
          accessibilityRole="menu"
          accessibilityValue={{text: this.state.selectedCountry}}
          accessibilityState={{
            expanded: this.state.countryMenuVisible,
          }}
          role="menu"
          aria-label={'Country'}
          aria-valuetext={this.state.selectedCountry}
          aria-expanded={this.state.countryMenuVisible}
        >
          <CountryDropdown
            selectedValue={this.state.selectedCountry}
            onSelect={this.countrySelected}
            onVisibilityChange={this.handleMenuVisibilityChange}
            accessibilityValue={{text: this.state.selectedCountry}}
          />
        </View>
        <RoundedButton
          hideAccessibleElements={this.state.countryMenuVisible}
          buttonType={ButtonType.action}
          accessibilityLabel="Next"
          onPress={this.handleNext}
          text={Localized.Buttons.next}
          textStyle={styles.nextButtonText}
        />
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});

export default withForwardedNavigationParams<CountrySelectionScreenProps>()(
  CountrySelectionScreen,
);
