import Api from './Api';
import {UrlTypes} from 'src/types/UrlTypes';
import {SetupModel} from 'src/types/SetupModel';
import {MarketAccountIdentifierTypes} from 'src/types/MarketAccountIdentifier';
import Settings from 'src/Settings';
import AccountConstants from 'src/constants/AccountConstants';
import {store} from '../redux/store';

export type PayrollDeductInfo = {
  payCycle: string;
  spendLimit: number;
  availableBalance: number;
  resetDate: string;
};

export type PayrollDeductResponse = {
  status: string;
  msg: string;
  data: PayrollDeductInfo;
};

export class GmaApi {
  setupAccount(setupModel: SetupModel) {
    const url = this.getFullUrl('/mka/updins');
    return Api.fetch(url, setupModel, 'POST');
  }

  confirmEmailAddress(
    marketAccount: string,
    emailAddress: string,
    emailVerificationToken: string,
  ) {
    const url = this.getFullUrl(`/mka/${marketAccount}`);
    const postObject: any = {
      commandType: 'VERIFY_EMAIL_ADDRESS',
      emailAddress,
      emailVerificationToken,
    };
    return Api.fetch(url, postObject, 'PUT');
  }

  /** not used */
  async loginGma(username: string, password: string, buildType: string) {
    const url = this.getFullUrl('/mka/show', {
      apptype: buildType,
    });
    const body = {
      email: username,
      password,
      apptype: buildType,
    };
    const response = await Api.fetch(url, body, 'POST');
    return this.checkIfAccountDisabled(response);
  }

  getGmaQR(marketAccount: string) {
    const url = this.getFullUrl('/mki/showqr', {
      marketaccount: marketAccount,
    });
    return Api.fetch(url, null, 'GET');
  }

  getMki(marketAccount: string, type: number) {
    const url = this.getFullUrl('/mki/show', {
      marketaccount: marketAccount,
      type,
    });
    return Api.fetch(url, null, 'GET');
  }

  loadPurchaseHistory(accountId: string, page: number, pageSize: number) {
    const url = this.getFullUrl('/transactions/v3/show', {
      marketAccount: accountId,
      page,
      pageSize,
    });
    return Api.fetch(url, null, 'GET');
  }

  getCurrentPrivacyVersion(accountId: string) {
    const url = this.getFullUrl('/gma/privacyversion', {
      id: accountId,
    });
    return Api.fetch(url, null, 'GET');
  }

  async getBalance(accountId: string) {
    const url = this.getFullUrl('/mka/show', {
      id: accountId,
      app: Settings.buildType,
    });
    const response = await Api.fetch(url, null, 'GET');
    return this.checkIfAccountDisabled(response);
  }

  getRedemptionThresholds(accountId: string) {
    const url = this.getFullUrl('/org/redemptionthreshold', {
      marketaccount: accountId,
    });
    return Api.fetch(url, null, 'GET');
  }

  setPin(accountId: string, pin: string) {
    const url = this.getFullUrl('/mka/resetpin');
    const postObject = {
      id: accountId,
      pin,
    };
    return Api.fetch(url, postObject, 'POST');
  }

  loginMki(type: number, code: string) {
    const url = this.getFullUrl('/mka/showbyvalue', {
      type,
    });
    const postObject = {
      value: code,
    };
    return Api.fetch(url, postObject, 'POST');
  }

  loadPromotions(mkaId: string) {
    const url = this.getFullUrl(`/mka/${mkaId}/promotions`);
    return Api.fetch(url, null, 'GET');
  }

  getLocationFromId(id: string, gmaId?: string) {
    const params: any = {
      id,
    };

    if (gmaId && gmaId !== '-1') {
      params.marketaccount = gmaId;
    }

    const url = this.getFullUrl('/location/v2/show', params);
    return Api.fetch(url, null, 'GET');
  }

  getLocationFromScannedId(scannedId: string, gmaId: string, type: string) {
    const params: any = {
      broadcastid: scannedId,
      type,
    };

    if (gmaId && gmaId !== '-1') {
      params.marketaccount = gmaId;
    }

    const url = this.getFullUrl('/location/v2/show', params);
    return Api.fetch(url, null, 'GET');
  }

  removeLocationLink(marketAccount: string, location: string) {
    const params: any = {
      marketAccount,
      location,
    };
    const url = this.getFullUrl('/location/linked', params);
    return Api.fetch(url, null, 'DELETE');
  }

  getRecentOrders(marketAccountId: string, locationId: string) {
    const url = this.getFullUrl('/sales/recent', {
      marketAccountId,
      locationId,
    });
    return Api.fetch(url, null, 'GET');
  }

  getTaxId(marketAccount: string) {
    return this.getMki(marketAccount, MarketAccountIdentifierTypes.TaxId);
  }

  loginQR(qrCode: string) {
    return this.loginMki(2, qrCode);
  }

  loginMarketCard(code: string) {
    return this.loginMki(0, code);
  }

  retrievePayrollDeductData(
    accountId: string,
    payrollId: string,
    currentDate: string,
  ): Promise<PayrollDeductResponse> {
    const url = this.getFullUrl('/mka/payrollDeductInfo', {
      accountId,
      payrollId,
      currentDate,
    });
    return Api.fetch(url, null, 'GET');
  }

  checkIfAccountDisabled(response: any) {
    if (response.status === 'ok' && response.data.isdisabled === 'Y') {
      response.status = 'err';
      response.msg = AccountConstants.ENTITY_NOT_FOUND;
    }

    return response;
  }

  getFullUrl(path: string, params?: any) {
    const baseUrl = store.getState().environment.serviceUrls[UrlTypes.gmaapi];
    return Api.getFullUrl(baseUrl, path, params);
  }
}
export default new GmaApi();
