import {createAsyncThunk} from '@reduxjs/toolkit';
import PlatformApi from 'src/api/PlatformApi';
import {GiftItem as GiftItemType} from 'src/types/Snack';

export type LoadGiftFeedResponse = {
  items: GiftItemType[];
  metadata: {
    pagination: {
      currentPage: number;
      limit: number;
      nextOffset: number;
      offset: number;
      pageCount: number;
      previousOffset: number | null;
      totalCount: number;
    };
    sortedBy: [];
  };
  offset: number;
  limit: number;
};
export type LoadGiftFeedParams = {
  locationId: string;
  offset?: number;
  limit?: number;
};
export const loadGiftFeed = createAsyncThunk<
  LoadGiftFeedResponse,
  LoadGiftFeedParams
>('snack/loadGiftFeed', async ({locationId, offset = 0, limit = 10}) => {
  const response = await PlatformApi.fetchSnacks(locationId, offset, limit);

  return {
    ...response,
    offset,
    limit,
  };
});
