import moment from 'moment';
import React from 'react';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import MainConsumerContext from '../../MainConsumerContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Util, {getPreviousRouteName} from 'src/Util';
import BackSubheader from '../../elements/BackSubheader';
import HeaderButton from '../../elements/HeaderButton';
import ScanArea from '../../elements/cart/ScanArea';
import YourItems from '../../elements/cart/ScanItems';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import {TransactionDetail, CartItem} from 'src/types/TransactionDetail';
import {LocationType} from 'src/types/Location';
import Localized from 'src/constants/AppStrings';
import ActionsFactory from 'src/actions/ActionsFactory';
import UIManager from '../../elements/ui/UIManager';
import CartService from 'src/services/CartService';
import PreparationMethods from '../../elements/orderAhead/PreparationMethods';
import MenuService from 'src/services/MenuService';
import {
  StyleSheet,
  View,
  Text,
  Platform,
  AccessibilityInfo,
} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import TransactionStore from 'src/stores/TransactionStore';
import TransactionActions from 'src/actions/TransactionActions';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import AVTouchableOpacity from '../../elements/AVTouchableOpacity';
import ItemAddedFeedback from '../../elements/cart/ItemAddedFeedback';
import CartTypes from 'src/constants/cart/CartTypes';
import {TimeSlotType} from 'src/types/Menu';
import LocationTypes from 'src/constants/LocationTypes';
import CartStore from 'src/stores/CartStore';
import PaymentTypes from 'src/constants/cart/PaymentTypes';
import PaymentStatus from 'src/constants/cart/PaymentStatus';
import AppApi from 'src/api/AppApi';
import {alertError, alertSuccess, confirm} from '../../helpers/AlertHelper';
import AVText from '../../elements/AVText';
import LocationFeatures from 'src/models/LocationFeatures';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import LockerTypes from 'src/constants/LockerTypes';
import {AppDispatch, RootState, store} from 'src/redux/store';
import {connect} from 'react-redux';
import {CreditCard} from 'src/models/CreditCard';
import {
  fetchAccountBalanceCreditCards,
  fetchAccountBalances,
  adjustDefaultBalance,
} from 'src/redux/slices/accountSlice';
import {BalanceTypes} from 'src/types/serverTypes/Account';
import {Balance} from 'src/models/Balance';
import MenuActions from 'src/actions/MenuActions';
import PurchaseTypes from 'src/constants/cart/PurchaseTypes';
import {Identifier} from 'src/types/serverTypes/Account';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import PromotionTypes from 'src/constants/cart/PromotionTypes';

type ScanScreenProps = WithGlobalizeProps & {
  navigation: NavigationProp<ScanScreen>;
  locationId: string;
  locationType: string;
  marketName: string;
  beaconId: string;
  promotionDescription: string;
  applePayAvailable: boolean;
  googlePayAvailable: boolean;
  location?: LocationType;
  cartType: CartTypes;
  isPayrollAvailable: boolean;
  payrollIdentifierId: string;
  creditCards: Array<CreditCard>;
  accountId: string;
  defaultBalanceId: string;
  balances: Array<Balance>;
  dispatch: AppDispatch;
  discountCode?: string;
};
type ScanScreenState = {
  balance: number;
  checkout: boolean;
  checkingOut: boolean;
  transaction: TransactionDetail | null;
  preparationMethod: PreparationMethodValues;
  pickupTime?: TimeSlotType;
  displayItems: Array<CartItem>;
  togoAvailable: boolean;
  dineInAvailable: boolean;
  lockerAvailable: boolean;
  hasOrderNote: boolean;
  orderNote: string | null;
  isFocused: boolean;
  hasConsumerRoles: boolean;
  consumerRoleButton?: string;
  unlockDiscountAvailable?: boolean;
  consumerRoleIdentifier: Identifier | null;
  previousRoute: string | null;
};

class ScanScreen extends React.Component<ScanScreenProps, ScanScreenState> {
  static defaultProps = {
    locationType: 'sos',
    showScanArea: true,
    showSearch: true,
  };
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;
  scanArea: ScanArea | null;
  sessionStartTime: moment.Moment;
  willFocusSubscription: any;
  willBlurSubscription: any;

  constructor(props: ScanScreenProps) {
    super(props);
    const transaction = TransactionStore.getTransaction();
    const displayItems = TransactionStore.getDisplayItems();
    const checkout = this.canCheckout(displayItems);
    const defaults = TransactionStore.getPreparationMethodDefaults(
      props.cartType === CartTypes.ScanAndPay ? null : props.location,
    );
    const locationFeatures = new LocationFeatures(
      props.location?.locationFeatures,
    );
    const hasOrderNote = locationFeatures.HasOrderNote;
    const hasConsumerRoles = locationFeatures?.HasConsumerRoles;
    const consumerRoleButton = locationFeatures?.consumerRoleButton;
    this.state = {
      balance: AccountStore.getAccountBalance(),
      transaction,
      checkout,
      preparationMethod: defaults.selectedMethod,
      togoAvailable: defaults.togoAvailable,
      dineInAvailable: defaults.dineInAvailable,
      lockerAvailable: defaults.lockerAvailable,
      pickupTime: TransactionStore.getPickupTime(),
      displayItems,
      hasOrderNote,
      orderNote: null,
      checkingOut: false,
      isFocused: false,
      hasConsumerRoles,
      consumerRoleButton,
      unlockDiscountAvailable: false,
      consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
      previousRoute: null,
    };
    CartStore.initializeSession(
      props.beaconId,
      props.locationId,
      props.marketName,
    );
    this.handleScanClick = this.handleScanClick.bind(this);
    this.onAccountStoreChanged = this.onAccountStoreChanged.bind(this);
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    this.onPickupLocationChanged = this.onPickupLocationChanged.bind(this);
    this.onPickupTimeChanged = this.onPickupTimeChanged.bind(this);
    this.barcodeScanned = this.barcodeScanned.bind(this);
    this.handleCheckoutClick = this.handleCheckoutClick.bind(this);
    this.handleTrashClick = this.handleTrashClick.bind(this);
    this.handleNoteClick = this.handleNoteClick.bind(this);
    this.onOrderNoteChanged = this.onOrderNoteChanged.bind(this);
    this.onBarcodeAlertClosed = this.onBarcodeAlertClosed.bind(this);
    this.stopScanning = this.stopScanning.bind(this);
    this.startScanning = this.startScanning.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.internalCheckout = this.internalCheckout.bind(this);
    this.cardAdded = this.cardAdded.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
    this.onBackSelect = this.onBackSelect.bind(this);
    this.onPreparationMethodSelected =
      this.onPreparationMethodSelected.bind(this);
    this.goToPickupTimeScreen = this.goToPickupTimeScreen.bind(this);
    this.handleEditItemClicked = this.handleEditItemClicked.bind(this);
    this.goToPickupLocationScreen = this.goToPickupLocationScreen.bind(this);
    this.getRightView = this.getRightView.bind(this);
    this.getTopView = this.getTopView.bind(this);
    this.getBalanceContainer = this.getBalanceContainer.bind(this);
    this.getOrderAheadTopView = this.getOrderAheadTopView.bind(this);
    this.getPickupLocationComponent =
      this.getPickupLocationComponent.bind(this);
    this.getPreparationArea = this.getPreparationArea.bind(this);
    this.getNoteView = this.getNoteView.bind(this);
    this.handleTimeUnvailable = this.handleTimeUnvailable.bind(this);
    this.fundAdded = this.fundAdded.bind(this);
    this.validateDiscountCode = this.validateDiscountCode.bind(this);
    this.addDiscountToCart = this.addDiscountToCart.bind(this);
    this.handleAddDiscountCodeClick =
      this.handleAddDiscountCodeClick.bind(this);
    this.sessionStartTime = moment();
    this.getDiscountCode = this.getDiscountCode.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ScanScreen', {
      ...this.props,
      ...this.state,
    });

    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});

    AccountStore.addChangeListener(this.onAccountStoreChanged);
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
    TransactionStore.addPickupLocationCHangedListener(
      this.onPickupLocationChanged,
    );
    TransactionStore.addPickupTimeChangedListener(this.onPickupTimeChanged);
    this.willFocusSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        this.setState({isFocused: true});
      },
    );
    this.willBlurSubscription = this.props.navigation.addListener(
      'blur',
      () => {
        this.setState({isFocused: false});
      },
    );
  }

  componentWillUnmount() {
    this.willFocusSubscription();
    this.willBlurSubscription();
    AccountStore.removeChangeListener(this.onAccountStoreChanged);
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
    TransactionStore.removeChangeListener(this.onPickupLocationChanged);
    TransactionStore.removePickupTimeChangedListener(this.onPickupTimeChanged);
  }

  onAccountStoreChanged() {
    FirebaseAnalytic.trackEvent('onAccountStoreChanged', 'ScanScreen', {
      ...this.props,
      ...this.state,
      balance: AccountStore.getAccountBalance(),
      consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
    });

    this.setState({
      balance: AccountStore.getAccountBalance(),
      consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
    });
  }

  onBackSelect() {
    FirebaseAnalytic.trackEvent('onBackSelect', 'ScanScreen', {
      ...this.props,
      ...this.state,
    });

    try {
      if (this.props.cartType !== CartTypes.OrderAhead) {
        if (this.state.displayItems && this.state.displayItems.length > 0) {
          confirm(
            Localized.Labels.cancel_order_confirm,
            () => {
              CartService.clearCart(this.sessionStartTime);
              NavActions.pop();
            },
            undefined,
            Localized.Labels.cancel_order,
            Localized.Labels.no,
            Localized.Labels.yes,
          );
        } else {
          CartService.clearCart(this.sessionStartTime);
          NavActions.pop();
        }
      } else {
        NavActions.pop();
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:onBackSelect',
        error.message ? error.message : error.toString(),
      );
    }
  }

  onPickupLocationChanged() {
    try {
      const defaults = TransactionStore.getPreparationMethodDefaults(
        this.props.location,
      );
      FirebaseAnalytic.trackEvent('onPickupLocationChanged', 'ScanScreen', {
        ...this.props,
        ...this.state,
        preparationMethod: defaults.selectedMethod,
        togoAvailable: defaults.togoAvailable,
        dineInAvailable: defaults.dineInAvailable,
        lockerAvailable: defaults.lockerAvailable,
      });
      this.setState({
        preparationMethod: defaults.selectedMethod,
        togoAvailable: defaults.togoAvailable,
        dineInAvailable: defaults.dineInAvailable,
        lockerAvailable: defaults.lockerAvailable,
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:onPickupLocationChanged',
        error.message ? error.message : error.toString(),
      );
    }
  }

  onTransactionStoreChanged() {
    try {
      const transaction = TransactionStore.getTransaction();
      const displayItems = TransactionStore.getDisplayItems();
      const canCheckout = this.canCheckout(displayItems);

      FirebaseAnalytic.trackEvent('onTransactionStoreChanged', 'ScanScreen', {
        ...this.props,
        ...this.state,
        transaction,
        checkout: canCheckout,
        pickupTime: TransactionStore.getPickupTime(),
        displayItems,
      });

      this.setState({
        transaction,
        checkout: canCheckout,
        pickupTime: TransactionStore.getPickupTime(),
        displayItems,
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:onTransactionStoreChanged',
        error.message ? error.message : error.toString(),
      );
    }
  }

  onPickupTimeChanged() {
    this.addDiscountToCart();
  }

  onBarcodeAlertClosed() {
    this.startScanning();
  }

  onSearchClicked() {
    this.stopScanning();
    FirebaseAnalytic.trackEvent('onSearchClicked', 'ScanScreen', {
      ...this.props,
      ...this.state,
      itemSelected: this.barcodeScanned,
      navigate: AppRoutes.ProductCategorySearch,
    });

    NavActions.push(AppRoutes.ProductCategorySearch, {
      ...this.props,
      itemSelected: this.barcodeScanned,
    });
  }

  onPreparationMethodSelected(method: PreparationMethodValues) {
    FirebaseAnalytic.trackEvent('onPreparationMethodSelected', 'ScanScreen', {
      ...this.props,
      ...this.state,
      method,
    });
    this.setState(
      {
        preparationMethod: method,
      },
      () => {
        const items = [...TransactionStore.getAllItems()];
        if (items.length > 0) {
          this.updateCart(items, null, this.getDiscountCode());
        }
      },
    );
  }

  goToPickupTimeScreen(availableTimesFromServer?: Array<TimeSlotType>) {
    const availableTimes = MenuService.getAvailableTimeSlotsFromList(
      availableTimesFromServer ??
        TransactionStore.getAvailableTimesFromServer(),
      this.props.location?.onlineOrderConfig?.kitchenSchedule,
    );
    FirebaseAnalytic.trackEvent('goToPickupTimeScreen', 'ScanScreen', {
      ...this.props,
      ...this.state,
      availableTimes,
      navigate: AppRoutes.PickupTime,
      param: {
        strings: Localized,
        location: this.props.location,
        availableTimes,
        fromCart: true,
      },
    });
    NavActions.push(
      AppRoutes.PickupTime,
      {
        strings: Localized,
        location: this.props.location,
        availableTimes,
        fromCart: true,
      },
      'cart-pickup',
    );
  }

  goToPickupLocationScreen() {
    const pickupLocationsProduct = MenuService.getPickupLocations();
    FirebaseAnalytic.trackEvent('goToPickupLocationScreen', 'ScanScreen', {
      ...this.props,
      ...this.state,
      navigate: AppRoutes.PickupLocation,
      param: {
        strings: Localized,
        location: this.props.location,
        fromCart: true,
        pickupLocationsProduct,
      },
    });
    NavActions.push(
      AppRoutes.PickupLocation,
      {
        strings: Localized,
        location: this.props.location,
        fromCart: true,
        pickupLocationsProduct,
      },
      'cart-pickup-location',
    );
  }

  canCheckout(displayItems: Array<CartItem>) {
    return displayItems.length > 0;
  }

  handleScanClick() {
    this.startScanning();
  }

  startScanning() {
    if (this.scanArea) {
      this.scanArea.handleScanNewItem();
    }
  }

  stopScanning() {
    if (this.scanArea) {
      FirebaseAnalytic.trackEvent('stopScanning', 'ScanScreen', {
        ...this.props,
        ...this.state,
      });

      this.scanArea.stopScanning();
    }
  }

  async barcodeScanned(barcode: string) {
    try {
      this.context.actions.showSpinner();
      const items = [...TransactionStore.getAllItems()];
      items.unshift({
        BarCode: barcode,
      });
      FirebaseAnalytic.trackEvent('barcodeScanned', 'ScanScreen', {
        ...this.props,
        ...this.state,
        items,
      });
      await this.updateCart(items, barcode, this.getDiscountCode());
      this.startScanning();
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:barcodeScanned',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async updateCart(items: CartItem[], barcode?: string, discountCode?: string) {
    FirebaseAnalytic.trackEvent('updateCart', 'ScanScreen', {
      ...this.props,
      ...this.state,
      items,
      discountCode,
    });
    const response = await CartService.updateCart(
      this.props.locationId,
      AccountStore.getAccountId(),
      [],
      items,
      this.props.locationType,
      barcode,
      this.state.pickupTime,
      this.state.preparationMethod,
      this.context,
      discountCode,
    );
    FirebaseAnalytic.trackEvent('updateCart response', 'ScanScreen', {
      ...this.props,
      ...this.state,
      items,
      discountCode,
      response,
    });
  }

  formatCurrency(value: number): string {
    try {
      return Util.formatCurrency(this.props, value, AccountStore.getCurrency());
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:formatCurrency',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async internalCheckout(fetchBalance = true) {
    Events.Scan.trackEvent('Start internalCheckout', {
      accountId: this.props.accountId,
    });
    this.context.actions.showSpinner(`${Localized.Labels.processing_sale}...`);
    this.setState({checkingOut: true});

    FirebaseAnalytic.trackEvent('internalCheckout', 'ScanScreen', {
      ...this.props,
      ...this.state,
    });
    try {
      let latestBalances = this.props.balances;
      if (fetchBalance) {
        // Get the most-recent balances
        latestBalances = await this.props
          .dispatch(fetchAccountBalances(this.props.accountId))
          .unwrap();
      }

      // Make sure there aren't any lingering payments from a previous failed attempt.
      CartStore.clearPayments();

      // subsidy => $8.50
      const subsidyBalance = latestBalances.find(
        (balance) => balance.isSubsidy,
      );
      let remainingDue = TransactionStore.getDueAmount();

      FirebaseAnalytic.trackEvent(
        'internalCheckout latestBalances',
        'ScanScreen',
        {
          ...this.props,
          ...this.state,
          latestBalances,
          subsidyBalance,
        },
      );

      if (
        subsidyBalance &&
        subsidyBalance.balance &&
        subsidyBalance.balance > 0 &&
        !subsidyBalance.isDisabled
      ) {
        this.applyBalance(subsidyBalance);
        remainingDue -= subsidyBalance.balance;
      }

      const defaultBalance = latestBalances.find(
        (balance) => balance.isDefault,
      );

      this.applyBalance(
        defaultBalance,
        TransactionStore.getDueAmount() - remainingDue,
      );
      remainingDue -= defaultBalance.balance;

      let balanceType = null;
      if (
        subsidyBalance === undefined ||
        subsidyBalance?.isDisabled ||
        subsidyBalance?.balance === 0 ||
        TransactionStore.getDueAmount() - subsidyBalance?.balance ===
          defaultBalance.balance
      ) {
        balanceType = BalanceTypes.Default;
      } else if (
        subsidyBalance &&
        subsidyBalance.balance >= TransactionStore.getDueAmount()
      ) {
        balanceType = BalanceTypes.TopOff;
      } else {
        balanceType = BalanceTypes.Split;
      }

      // due to issues with floating point values
      if (remainingDue >= 0.01) {
        await this.handleRemainingDue(
          TransactionStore.getDueAmount() - remainingDue,
          defaultBalance.id,
          balanceType,
        );
      } else if (this.props.cartType === CartTypes.TouchlessCoffee) {
        NavActions.push(AppRoutes.BunnDispense, {
          locationName: this.props.marketName,
        });
      } else {
        await this.checkout(balanceType);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:InternalCheckout',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.error_finalizing_order, guid, () =>
        this.setState({checkingOut: false}),
      );
    } finally {
      this.context.actions.hideSpinner();
      this.setState({checkingOut: false});
    }
  }

  applyBalance(balance: Balance, applied = 0) {
    // 0.75
    const amountDue = TransactionStore.getDueAmount() - applied;

    FirebaseAnalytic.trackEvent('applyBalance', 'ScanScreen', {
      ...this.props,
      ...this.state,
      amountDue,
      applied,
      balance,
    });

    if (amountDue > 0) {
      CartStore.addPayment(
        this.getPayment(
          Math.min(amountDue, balance.balance),
          balance.balance,
          balance.id,
        ),
      );
    }
  }

  getPayment(due: number, balance: number, balanceId: string) {
    FirebaseAnalytic.trackEvent('getPayment', 'ScanScreen', {
      ...this.props,
      ...this.state,
      balance: balance,
      amount: due,
      type: PaymentTypes.Account,
      status: PaymentStatus.Accepted,
      GMABalanceId: balanceId,
    });
    return {
      balance: balance,
      amount: due,
      type: PaymentTypes.Account,
      status: PaymentStatus.Accepted,
      GMABalanceId: balanceId,
    };
  }

  async handleRemainingDue(
    balance: number,
    balanceId: string,
    balanceType: string,
  ) {
    FirebaseAnalytic.trackEvent('handleRemainingDue', 'ScanScreen', {
      ...this.props,
      ...this.state,
      buildType: Settings.buildType,
      balance,
      balanceId,
      balanceType,
    });
    try {
      if (Settings.buildType === BuildTypes.pantry) {
        await this.handleCheckoutRemainingDuePantry(
          balance,
          balanceId,
          balanceType,
        );
      } else if (
        this.props.isPayrollAvailable &&
        AccountStore.getPayrollAutoComplete()
      ) {
        await this.checkoutPayrollAutoComplete(balance, balanceType);
      } else {
        this.navigateToFunding(balance);
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleRemainingDue',
        error.message ? error.message : error.toString(),
      );
    }
  }

  navigateToFunding(balance: number) {
    FirebaseAnalytic.trackEvent('navigateToFunding', 'ScanScreen', {
      ...this.props,
      ...this.state,
      remainingDue: TransactionStore.getRemainingDue(balance),
      addFundsSuccess: this.fundAdded,
      transDate: CartStore.SessionStartTime.format('YYYY-MM-DD HH:mm:ss'),
    });
    try {
      this.context.actions.navigateToFunding(true, {
        remainingDue: TransactionStore.getRemainingDue(balance),
        addFundsSuccess: this.fundAdded,
        transDate: CartStore.SessionStartTime.format('YYYY-MM-DD HH:mm:ss'),
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:navigateToFunding',
        error.message ? error.message : error.toString(),
      );
    }
  }

  fundAdded() {
    try {
      const defaultBalance = this.props.balances.find(
        (balance) => balance.isDefault,
      );
      const remainingDue =
        TransactionStore.getDueAmount() - defaultBalance.balance;

      FirebaseAnalytic.trackEvent('fundAdded', 'ScanScreen', {
        ...this.props,
        ...this.state,
        amount: remainingDue,
        defaultBalance,
        reason: Localized.Labels.add_funds,
      });

      store.dispatch(
        adjustDefaultBalance({
          amount: remainingDue,
          reason: Localized.Labels.add_funds,
        }),
      );
      this.internalCheckout(false);
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:fundAdded',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async checkoutPayrollAutoComplete(balance: number, balanceType: string) {
    FirebaseAnalytic.trackEvent('checkoutPayrollAutoComplete', 'ScanScreen', {
      ...this.props,
      ...this.state,
      balance,
    });

    try {
      const remainderDue = TransactionStore.getRemainingDue(balance);
      const fundResponse = await AppApi.addFundsPayrollDeduct(
        AccountStore.getAccountId(),
        AccountStore.getAccountBalanceId(),
        remainderDue,
        CartStore.SessionStartTime.format('YYYY-MM-DD HH:mm:ss'),
        this.props.payrollIdentifierId,
        CartStore.TransactionId,
      );
      FirebaseAnalytic.trackEvent(
        'checkoutPayrollAutoComplete addFundsPayrollDeduct',
        'ScanScreen',
        {
          ...this.props,
          ...this.state,
          balance,
          remainderDue,
          fundResponse,
        },
      );
      if (fundResponse.status === 'ok') {
        await this.checkout(balanceType);
      } else if (fundResponse.statusCode !== 409) {
        this.navigateToFunding(balance);
      }
    } catch (error) {
      const reason = error.message ?? error.toString();
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:CheckoutPayrollAutoComplete',
        reason,
      );
      if (reason !== 'Duplicate entry') {
        this.navigateToFunding(balance);
      }
    }
  }

  async handleCheckoutRemainingDuePantry(
    balance: number,
    balanceId: string,
    balanceType: string,
  ) {
    const cards: Array<CreditCard> = this.props.creditCards;
    FirebaseAnalytic.trackEvent(
      'handleCheckoutRemainingDuePantry',
      'ScanScreen',
      {
        ...this.props,
        ...this.state,
        cards,
        balance,
        balanceId,
        balanceType,
      },
    );
    if (TransactionStore.getRemainingDue(balance) > 0 && cards.length > 0) {
      await this.handleCheckoutPantryWithCard(
        cards,
        balance,
        balanceId,
        balanceType,
      );
    } else if (cards.length === 0) {
      this.handleCheckoutPantryNoCards(balance);
    }
  }

  async handleCheckoutPantryWithCard(
    cards: Array<CreditCard>,
    balance: number,
    balanceId: string,
    balanceType: string,
  ) {
    const defaultCard = cards[0];

    FirebaseAnalytic.trackEvent('handleCheckoutPantryWithCard', 'ScanScreen', {
      ...this.props,
      ...this.state,
      cards,
      balance,
      balanceId,
      balanceType,
    });
    try {
      const addFundsResponse =
        await ActionsFactory.getAccountActions().chargeCard(
          AccountStore.getAccountId(),
          TransactionStore.getRemainingDue(balance),
          this.props.locationId,
          AccountStore.getAccountBalanceId(),
          defaultCard?.id,
        );
      FirebaseAnalytic.trackEvent(
        'handleCheckoutPantryWithCard chargeCard',
        'ScanScreen',
        {
          ...this.props,
          ...this.state,
          cards,
          balance,
          balanceId,
          balanceType,
          addFundsResponse,
        },
      );
      if (addFundsResponse) {
        this.handleCheckoutPantryChargeSuccess(
          addFundsResponse,
          balance,
          balanceId,
          balanceType,
        );
      } else {
        alertError(Localized.Errors.error_finalizing_order);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:HandleCheckoutPantryWithCard',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.error_finalizing_order);
    }
  }

  async handleCheckoutPantryChargeSuccess(
    addFundsResponse: any,
    balance: number,
    balanceId: string,
    balanceType: string,
  ) {
    FirebaseAnalytic.trackEvent(
      'handleCheckoutPantryChargeSuccess',
      'ScanScreen',
      {
        ...this.props,
        ...this.state,
        balance,
        balanceId,
        balanceType,
        addFundsResponse,
      },
    );
    try {
      if (balance > 0) {
        CartStore.addPayment(this.getPayment(balance, 0, balanceId));
      }

      CartStore.addPayment({
        ...addFundsResponse,
        type: PaymentTypes.Credit,
        balance: 0,
        amount: TransactionStore.getRemainingDue(balance),
      });
      await this.checkout(balanceType);
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleCheckoutPantryChargeSuccess',
        error.message ? error.message : error.toString(),
      );
    }
  }

  handleCheckoutPantryNoCards(balance: number) {
    this.context.actions.hideSpinner();

    FirebaseAnalytic.trackEvent('handleCheckoutPantryNoCards', 'ScanScreen', {
      ...this.props,
      ...this.state,
      marketName: this.props.marketName,
      balance,
      cardAdded: this.cardAdded,
      navigate: AppRoutes.InsufficientCredit,
    });

    NavActions.push(AppRoutes.InsufficientCredit, {
      marketName: this.props.marketName,
      balance,
      cardAdded: this.cardAdded,
    });
  }

  getDiscountCode() {
    if (
      this.props.location.locationFeatures.is365PayAfterTaxPromo &&
      TransactionStore.isAfterTaxPromoApplied()
    ) {
      return PromotionTypes.AfterTaxPromo;
    } else {
      return this.state.unlockDiscountAvailable
        ? AccountStore.getConsumerIdentifier()?.value
        : null;
    }
  }

  async checkout(balanceType: string) {
    try {
      const defaultPreparationMethod = this.getDefaultPreparationMethod(
        AccountStore.getLocationLockerType(),
      );

      FirebaseAnalytic.trackEvent('checkout', 'ScanScreen', {
        ...this.props,
        ...this.state,
        defaultPreparationMethod,
      });
      let saleContext: PurchaseTypes;
      if (this.props.cartType === CartTypes.OrderAhead) {
        saleContext = PurchaseTypes.OrderAhead;
      } else if (this.props.cartType === CartTypes.ScanAndPay) {
        saleContext = PurchaseTypes.ScanAndPay;
      } else if (this.props.cartType === CartTypes.TouchlessCoffee) {
        saleContext = PurchaseTypes.TouchlessCoffee;
      }

      const response = await CartService.checkout(
        this.context,
        this.props.locationType,
        saleContext,
        this.state.pickupTime,
        this.state.preparationMethod,
        this.props.location && this.props.cartType !== CartTypes.ScanAndPay
          ? this.props.location.onlineOrderConfig.pickupInstruction
          : null,
        this.handleTimeUnvailable,
        this.state.orderNote,
        defaultPreparationMethod,
        balanceType,
        this.getDiscountCode(),
      );

      FirebaseAnalytic.trackEvent('checkout response', 'ScanScreen', {
        ...this.props,
        ...this.state,
        defaultPreparationMethod,
        response,
      });

      //Link location if order is placed from nearby location (Geolocation).
      if (
        !AccountStore.getLinkedLocations().some(
          (loc) => loc.id === this.props.locationId,
        ) &&
        AccountStore.getNearbyLocations().some(
          (loc) => loc.id === this.props.locationId,
        )
      ) {
        ActionsFactory.getAccountActions().addLocationLink(
          AccountStore.getAccountId(),
          this.props.locationId,
        );
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:checkout',
        error.message ? error.message : error.toString(),
      );
    }
  }

  getDefaultPreparationMethod(lockerType = LockerTypes.None) {
    try {
      const defaults = TransactionStore.getPreparationMethodDefaults(
        this.props.location,
      );

      FirebaseAnalytic.trackEvent('getDefaultPreparationMethod', 'ScanScreen', {
        ...this.props,
        ...this.state,
        defaults,
        lockerType,
      });

      return lockerType === LockerTypes.DC &&
        defaults.selectedMethod === PreparationMethodValues.Locker
        ? PreparationMethodValues.ToGo
        : defaults.selectedMethod;
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:getDefaultPreparationMethod',
        error.message ? error.message : error.toString(),
      );
    }
  }

  handleTimeUnvailable(availableTimes: Array<string>) {
    try {
      const availableTimeSlots = MenuService.getTimesSlotsFromTimes(
        availableTimes,
        MenuService.getLocationDayOfWeek(),
        MenuService.getPickupDate(),
      );
      FirebaseAnalytic.trackEvent('handleTimeUnvailable', 'ScanScreen', {
        ...this.props,
        ...this.state,
        availableTimeSlots,
      });

      alertError(Localized.Errors.pickup_time_not_available, null, () =>
        this.goToPickupTimeScreen(availableTimeSlots),
      );
      TransactionActions.availableTimesUpdated(availableTimeSlots);
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleTimeUnvailable',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async handleCheckoutClick() {
    FirebaseAnalytic.trackEvent('handleCheckoutClick', 'ScanScreen', {
      ...this.props,
      ...this.state,
    });

    if (this.props.cartType === CartTypes.OrderAhead) {
      const pickupTime = TransactionStore.getPickupTime();
      const timeZoneOffsetMinutes = MenuService.getTimezoneOffsetMinutes();
      const leadTime = this.props.location.onlineOrderConfig.kitchenSchedule;
      const now = moment()
        .add(leadTime, 'minutes')
        .add(timeZoneOffsetMinutes, 'minutes');
      if (!moment(pickupTime.date).isAfter(now)) {
        try {
          this.context.actions.showSpinner();
          const availableTimes = await MenuActions.getAvailableTimeSlots(
            this.props.location,
            TransactionStore.getPickupLocationId(),
          );
          alertError(
            Localized.Errors.pickup_time_not_available,
            undefined,
            () => {
              TransactionActions.shoppingCartTransactionsCleared();
              NavActions.replace(AppRoutes.PickupTime, {
                strings: Localized,
                location: this.props.location,
                availableTimes,
              });
            },
          );
        } catch (error) {
          const guid = await uuid.getRandomUUID();
          Events.Error.trackEvent(
            'Exception',
            'ScanScreen:PickupTime',
            error.message ? error.message : error.toString(),
            guid,
          );
        } finally {
          this.context.actions.hideSpinner();
        }
      } else {
        this.context.actions.showSpinner();
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        if (availableTimes && availableTimes.length > 0) {
          const isTimeSlotPresent = availableTimes.some(
            (obj) =>
              obj.timeString === pickupTime.timeString &&
              moment(pickupTime.date).isSame(moment(obj.date), 'day'),
          );
          if (moment(pickupTime.date).isAfter(now) && isTimeSlotPresent) {
            try {
              this.context.actions.hideSpinner();
              const allCartItemsAvailable =
                this.props.cartType === CartTypes.OrderAhead
                  ? await CartService.checkCartForZeroInventory(
                      this.context,
                      this.props.locationId,
                      this.state.displayItems,
                    )
                  : true;

              if (allCartItemsAvailable) {
                if (this.state.checkout && !this.state.checkingOut) {
                  this.stopScanning();
                  this.internalCheckout();
                }
              } else {
                alertError(
                  Localized.Errors.sold_out_remove_item,
                  null,
                  undefined,
                  Localized.Labels.sold_out,
                );
              }
            } catch (error) {
              const guid = await uuid.getRandomUUID();
              Events.Error.trackEvent(
                'Exception',
                'ScanScreen:HandleCheckoutClick',
                error.message ? error.message : error.toString(),
                guid,
              );
              alertError(Localized.Errors.error_finalizing_order, guid);
            }
          } else {
            this.context.actions.hideSpinner();
            alertError(
              Localized.Errors.order_limit_reached_for_pickup_time,
              undefined,
              () => {
                TransactionActions.shoppingCartTransactionsCleared();
                NavActions.replace(AppRoutes.PickupTime, {
                  strings: Localized,
                  location: this.props.location,
                  availableTimes,
                });
              },
            );
          }
        } else {
          this.context.actions.hideSpinner();
          const guid = await uuid.getRandomUUID();
          alertError(Localized.Errors.error_finalizing_order, guid);
        }
      }
    } else {
      try {
        if (this.state.checkout && !this.state.checkingOut) {
          this.stopScanning();
          this.internalCheckout();
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        Events.Error.trackEvent(
          'Exception',
          'ScanScreen:HandleCheckoutClick',
          error.message ? error.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.error_finalizing_order, guid);
      }
    }
  }

  handleNoteClick() {
    FirebaseAnalytic.trackEvent('handleNoteClick', 'ScanScreen', {
      ...this.props,
      ...this.state,
      note: this.state.orderNote,
      navigate: AppRoutes.OrderNote,
    });

    NavActions.push(AppRoutes.OrderNote, {
      note: this.state.orderNote,
      onNoteChange: this.onOrderNoteChanged,
    });
  }

  onOrderNoteChanged(orderNote: string) {
    FirebaseAnalytic.trackEvent('onOrderNoteChanged', 'ScanScreen', {
      ...this.props,
      ...this.state,
      orderNote,
    });
    this.setState({
      orderNote,
    });
  }

  async handleEditItemClicked(item: CartItem) {
    FirebaseAnalytic.trackEvent('handleEditItemClicked', 'ScanScreen', {
      ...this.props,
      ...this.state,
      item,
    });
    if (item.Id) {
      const product = MenuService.getProduct(item.Id);
      try {
        this.context.actions.showSpinner();
        const response = await MenuActions.getModifiers(
          this.props.location.locationId,
          product,
        );

        FirebaseAnalytic.trackEvent(
          'handleEditItemClicked getModifiers',
          'ScanScreen',
          {
            ...this.props,
            ...this.state,
            response,
            item,
            navigate: AppRoutes.ProductDetail,
          },
        );
        NavActions.push(AppRoutes.ProductDetail, {
          edit: true,
          location: this.props.location,
          product,
          lineNumber: item.LineNumber,
          modifierSelections: item.Modifiers,
        });
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        Events.Error.trackEvent(
          'Exception',
          'ScanScreen:handleEditItemClicked',
          error.message ? error.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.internet_issue, guid);
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  async handleTrashClick(itemNumbers: Array<number>) {
    this.context.actions.showSpinner();
    const updatedItems = TransactionStore.getAllItems().filter(
      (item) => !itemNumbers.includes(item.LineNumber),
    );

    FirebaseAnalytic.trackEvent('handleTrashClick', 'ScanScreen', {
      ...this.props,
      ...this.state,
      updatedItems,
    });

    try {
      await this.updateCart(updatedItems, null, this.getDiscountCode());

      AccessibilityInfo.announceForAccessibility('Item deleted!');
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:HandleTrashClick',
        e.message ? e.message : e.toString(),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  handleAddDiscountCodeClick() {
    try {
      this.setState({isFocused: false}, () => {
        if (Platform.OS === 'web') {
          FirebaseAnalytic.trackEvent(
            'handleAddDiscountCodeClick',
            'ScanScreen',
            {
              ...this.props,
              ...this.state,
              navigate: AppRoutes.EnterDiscountCode,
            },
          );

          NavActions.navigate(AppRoutes.EnterDiscountCode, {
            locationId: this.props.locationId,
            consumerRoleIdentifier: this.state.consumerRoleIdentifier,
            addDiscountToCart: this.addDiscountToCart,
            location: this.props.location,
            cartType: this.props.cartType,
          });
        } else {
          FirebaseAnalytic.trackEvent(
            'handleAddDiscountCodeClick',
            'ScanScreen',
            {
              ...this.props,
              ...this.state,
              navigate: AppRoutes.ScanDiscountCode,
            },
          );

          NavActions.navigate(AppRoutes.ScanDiscountCode, {
            locationId: this.props.locationId,
            consumerRoleIdentifier: this.state.consumerRoleIdentifier,
            addDiscountToCart: this.addDiscountToCart,
            location: this.props.location,
            cartType: this.props.cartType,
          });
        }
      });
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleAddDiscountCodeClick',
        e.message ? e.message : e.toString(),
      );
    }
  }

  async validateDiscountCode() {
    this.context.actions.showSpinner();
    const items = [...TransactionStore.getAllItems()];

    FirebaseAnalytic.trackEvent('validateDiscountCode', 'ScanScreen', {
      ...this.props,
      ...this.state,
      items,
    });
    try {
      const response =
        await ActionsFactory.getAccountActions().validateDiscountCode(
          this.props.locationId,
          this.state.consumerRoleIdentifier?.value,
        );
      if (response?.statusCode === 404) {
        throw response;
      }

      FirebaseAnalytic.trackEvent(
        'validateDiscountCode response',
        'ScanScreen',
        {
          ...this.props,
          ...this.state,
          items,
          response,
        },
      );

      await this.updateCart(
        items,
        null,
        this.state.consumerRoleIdentifier?.value,
      );

      alertSuccess(
        Localized.Success.any_applicable_promotions_will_now_apply,
        () => {
          this.setState({
            unlockDiscountAvailable: true,
          });
        },
        Localized.Success.discount_recognized,
      );
    } catch (error) {
      alertError(
        Localized.Errors.we_couldnt_recognize_that_code,
        null,
        () => {
          this.setState({
            unlockDiscountAvailable: false,
          });
        },
        Localized.Errors.sorry,
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:validateDiscountCode',
        error.message ? error.message : error.toString(),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }
  async addDiscountToCart() {
    this.context.actions.showSpinner();
    const items: any = [...TransactionStore.getAllItems()];
    let discountCode: string;
    if (this.props.location.locationFeatures.is365PayAfterTaxPromo) {
      discountCode = PromotionTypes.AfterTaxPromo;
      TransactionActions.afterTaxPromoUpdated();
      alertSuccess(
        Localized.Success.any_applicable_promotions_will_now_apply,
        null,
        Localized.Success.discount_recognized,
      );
    } else {
      discountCode =
        this.state.consumerRoleIdentifier?.value ?? this.props.discountCode;
    }

    FirebaseAnalytic.trackEvent('addDiscountToCart', 'ScanScreen', {
      ...this.props,
      ...this.state,
      items,
      discountCode,
    });

    try {
      await this.updateCart(items, null, discountCode);
      this.setState({
        unlockDiscountAvailable: true,
      });
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:HandleAddDiscountToCart',
        e.message ? e.message : e.toString(),
      );
      this.setState({
        unlockDiscountAvailable: false,
      });
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  async cardAdded() {
    try {
      await this.props.dispatch(
        fetchAccountBalanceCreditCards({
          accountId: this.props.accountId,
          accountBalanceId: this.props.defaultBalanceId,
        }),
      );

      FirebaseAnalytic.trackEvent('cardAdded', 'ScanScreen', {
        ...this.props,
        ...this.state,
      });

      NavActions.pop();
      this.handleCheckoutClick();
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:cardAdded',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getBalanceContainer(smallText: boolean) {
    if (Platform.OS === 'web') {
      return null;
    }

    FirebaseAnalytic.trackEvent('getBalanceContainer', 'ScanScreen', {
      ...this.props,
      ...this.state,
    });
    try {
      return UIManager.getBalanceContainer(smallText, Localized);
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:getBalanceContainer',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getRightView(): React.ReactNode | null {
    if (
      this.props.cartType !== CartTypes.ScanAndPay ||
      this.props.locationType.toLowerCase() !== LocationTypes.sos
    ) {
      return this.getBalanceContainer(false);
    } else {
      return (
        <View style={styles.rightContainer}>
          {this.getBalanceContainer(true)}
          <HeaderButton
            type="Search"
            onPress={this.onSearchClicked}
            label={Localized.Labels.search}
          />
        </View>
      );
    }
  }

  getPickupLocationComponent(): React.ReactNode | null {
    let pickupLocation = null;
    const pickupLocationName = TransactionStore.getPickupLocationName();

    if (pickupLocationName) {
      pickupLocation = (
        <View style={styles.pickupRow}>
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            accessible={true}
            numberOfLines={2}
            accessibilityLabel={`Pickup at ${pickupLocationName}`}
            accessibilityRole="text"
            aria-label={`Pickup at ${pickupLocationName}, text`}
            style={styles.pickupText}
          >
            {pickupLocationName}
          </Text>
          <AVTouchableOpacity
            accessible={true}
            accessibilityLabel={Localized.Labels.change_pickup_location}
            accessibilityRole="link"
            accessibilityHint="Double tap tp select a new pickup location"
            aria-label={Localized.Labels.change_pickup_location}
            role="link"
            style={styles.pickupChange}
            onPress={this.goToPickupLocationScreen}
          >
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              style={styles.pickupChangeText}
            >
              {Localized.Labels.change}
            </Text>
          </AVTouchableOpacity>
        </View>
      );
    }

    return pickupLocation;
  }

  getOrderAheadTopView(): React.ReactNode {
    const pickupLocation = this.getPickupLocationComponent();
    const getLocationAddress = Util.getLocationAddress(this.props.location);
    return (
      <View style={styles.locationContainer}>
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          style={{color: Styles.darkColor}}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
        >
          {Localized.Labels.pickup_at}
        </Text>
        {pickupLocation}
        {getLocationAddress !== '' && (
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            accessible={true}
            accessibilityLabel={`Location address ${Util.getLocationAddress(
              this.props.location,
            )}`}
            accessibilityRole="text"
            aria-label={`Location address ${Util.getLocationAddress(
              this.props.location,
            )}, text`}
            style={styles.locationAddress}
          >
            {Util.getLocationAddress(this.props.location)}
          </Text>
        )}
        <View style={[styles.pickupRow, styles.pickupTimeRow]}>
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            accessible={true}
            aria-label={`Pickup time is at ${this.state.pickupTime?.time}, text`}
            accessibilityLabel={`Pickup time is at ${this.state.pickupTime?.time}`}
            accessibilityRole="text"
            style={styles.pickupText}
          >
            {this.state.pickupTime?.time}
          </Text>
        </View>
      </View>
    );
  }

  getTopView(): React.ReactNode | null {
    if (this.props.cartType === CartTypes.ScanAndPay && this.state.isFocused) {
      return (
        <ScanArea
          ref={(scanArea) => {
            this.scanArea = scanArea;
          }}
          barcodeScanned={this.barcodeScanned}
          promotionDescription={this.props.promotionDescription}
        />
      );
    }

    if (
      this.props.cartType === CartTypes.TouchlessCoffee ||
      !this.props.location
    ) {
      return null;
    }

    return this.getOrderAheadTopView();
  }

  getPreparationArea(): React.ReactNode | null {
    let preparationArea = null;

    if (this.state.displayItems && this.state.displayItems.length > 0) {
      preparationArea = (
        <PreparationMethods
          onMethodSelected={this.onPreparationMethodSelected}
          selectedMethod={this.state.preparationMethod}
          togoAvailable={this.state.togoAvailable}
          dineInAvailable={this.state.dineInAvailable}
          lockerAvailable={this.state.lockerAvailable}
        />
      );
    }

    return preparationArea;
  }

  getNoteView(): React.ReactNode | null {
    let noteView = null;
    const noteText = this.state.orderNote
      ? Localized.Buttons.edit
      : Localized.Buttons.special_instructions;

    if (this.state.hasOrderNote) {
      noteView = (
        <View style={styles.noteContainer}>
          {this.state.orderNote && (
            <View
              accessible={true}
              accessibilityLabel={`${Localized.Labels.order_note} ${this.state.orderNote}`}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.order_note} ${this.state.orderNote}, text`}
            >
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                style={styles.label}
              >
                {Localized.Labels.order_note}
              </AVText>
              <AVText maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}>
                {this.state.orderNote}
              </AVText>
            </View>
          )}
          <RoundedButton
            accessible={true}
            accessibilityLabel={noteText}
            accessibilityRole="button"
            aria-label={noteText}
            role="button"
            containerStyle={styles.button}
            buttonType={ButtonType.normal}
            onPress={this.handleNoteClick}
            text={noteText}
          />
        </View>
      );
    }

    return noteView;
  }

  render() {
    const rightView = this.getRightView();
    const topView = this.getTopView();
    const preparationArea = this.getPreparationArea();
    const noteView = this.getNoteView();

    // <YourItems /> is the ScanItems component
    const content = (
      <View style={styles.content}>
        {topView}
        {preparationArea}
        <YourItems
          trashClick={this.handleTrashClick}
          editClicked={this.handleEditItemClicked}
          balance={this.state.balance}
          hideYourItems={false}
          showGrandTotal={false}
          cartType={this.props.cartType}
          onAddItem={this.onBackSelect}
          locationId={this.props.locationId}
          noteView={noteView}
          preparationMethod={this.state.preparationMethod}
          hasConsumerRoles={this.state.hasConsumerRoles}
          consumerRoleButton={this.state.consumerRoleButton}
          validateDiscountCode={this.validateDiscountCode}
          addDiscountToCart={this.addDiscountToCart}
          location={this.props.location}
          discountCodeButtonAction={this.handleAddDiscountCodeClick}
        />
        {this.state.checkout && (
          <RoundedButton
            accessible={true}
            accessibilityLabel={Localized.Buttons.checkout}
            accessibilityRole="button"
            aria-label={Localized.Buttons.checkout}
            role="button"
            disabled={this.state.checkingOut}
            buttonType={ButtonType.action}
            color={Styles.positiveColor}
            onPress={this.handleCheckoutClick}
            text={`${Localized.Buttons.checkout} ${Util.formatCurrency(
              this.props,
              parseFloat(this.state.transaction?.Due),
              AccountStore.getCurrency(),
            )}`}
          />
        )}
      </View>
    );
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={Localized.Buttons.back_arrow}
        aria-label={Localized.Buttons.back_arrow}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={this.props.marketName}
        onBackSelect={this.onBackSelect}
        pop={false}
        rightView={rightView}
      >
        {content}
        <ItemAddedFeedback
          strings={Localized}
          extraMargin={Styles.Spacing.m2}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    flex: 1,
  },
  rightContainer: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'flex-end',
    paddingTop: Styles.Spacing.m2,
  },
  yourItems: {
    flex: 1,
  },
  locationName: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '600',
    color: Styles.darkColor,
  },
  locationAddress: {
    fontSize: Styles.Fonts.f0,
    color: Styles.darkColor,
  },
  pickupText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  locationContainer: {
    alignSelf: 'stretch',
    padding: Styles.Spacing.m3,
  },
  locationText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    fontWeight: '600',
  },
  pickupRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pickupTimeRow: {
    marginTop: Styles.Spacing.m1,
  },
  pickupChange: {
    flex: 1,
    marginLeft: Styles.Spacing.m2,
  },
  pickupChangeText: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
  },
  noteContainer: {
    marginHorizontal: Styles.Spacing.m2,
  },
  label: {
    fontSize: Styles.Fonts.f0,
    fontWeight: '600',
    marginVertical: Styles.Spacing.m2,
  },
  button: {
    marginVertical: Styles.Spacing.m2,
  },
});

const ConnectedScanScreen = connect(
  (state: RootState) => ({
    isPayrollAvailable: state.account.account.isPayrollAvailable,
    payrollIdentifierId: state.account.account.payrollIdentifier?.id,
    creditCards: state.account.creditCards,
    accountId: state.account.account.id,
    defaultBalanceId: state.account.account.defaultBalance?.id,
    balances: state.account.account.displayBalances,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(ScanScreen);

export default withForwardedNavigationParams()(
  withGlobalize(ConnectedScanScreen),
);
