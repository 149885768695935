import moment from 'moment';
import React from 'react';
import {
  Animated,
  Platform,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {withGlobalize} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {compose, withProps, withState} from 'recompose';
import MainConsumerContext from '../MainConsumerContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Util, {getPreviousRouteName} from 'src/Util';
import AccountHistoryList, {
  filterTypes,
} from '../elements/account/AccountHistoryList';
import Promotion from '../elements/promotions/Promotion';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Styles from '../Styles';
import type {
  DiscountItem,
  MoblicoPromotionType,
  PromotionType,
} from 'src/types/Promotions';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import UIManager from '../elements/ui/UIManager';
import MoblicoPromotionsCarousel from '../elements/promotions/MoblicoPromotionsCarousel';
import withIsConnected from '../hoc/withIsConnected';
import AVText from '../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Header from '../elements/Header';
import FocusAwareStatusBar from '../elements/FocusAwareStatusBar';
import Localized from 'src/constants/AppStrings';
import HistoryItem from '../elements/account/HistoryItem';
import ReferralSection from '../elements/ReferralSection';
import type {ReferralCampaign, ReferralStat} from 'src/types/Referral';
import {networkError} from '../helpers/AlertHelper';
import OrderSection from '../elements/orderAhead/OrderSection';
import AccountConstants from 'src/constants/AccountConstants';
import type {SaleOrder} from 'src/types/TransactionDetail';
import SnackSection from '../elements/sendSnack/SnackSection';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import {connect} from 'react-redux';
import {RootState, store} from 'src/redux/store';
import {savedReceipt} from 'src/components/elements/DeepLinkFunctions';
import {deleteReceipt} from 'src/redux/slices/accountSlice';
import _ from 'lodash';
import {HistoryItem as HistoryItemType} from 'src/types/TransactionDetail';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import uuid from 'src/nativeModules/UUID';

type PromotionsScreenProps = IsConnectedProps & {
  scrollY: Animated.AnimatedValue;
  animationScrollY: unknown;
  navigation: NavigationProp<PromotionsScreen>;
  globalize: unknown;
  receipt: savedReceipt;
  orders: Array<SaleOrder>;
  giftFeeds: Array<GiftItemType>;
};
type PromotionsScreenState = {
  promotions: Array<PromotionType>;
  moblicoPromotions: Array<MoblicoPromotionType>;
  qrCode: string;
  mediaBaseUrl: string;
  refreshing: boolean;
  referralStat?: ReferralStat;
  referralCampaign?: ReferralCampaign;
  history: Array<HistoryItemType>;
  orders: Array<SaleOrder>;
  accountId: unknown;
  previousRoute: string | null;
};
const FULL_URL_START = 'http';
const IMG_EXTENSIONS = ['png', 'jpg'];
class PromotionsScreen extends React.Component<
  PromotionsScreenProps,
  PromotionsScreenState
> {
  discountItems: Array<DiscountItem>;
  promotionsViewed: Array<string | number> = [];
  started: moment.Moment;
  scrollView: any;
  connectTimeout: any;
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;

  constructor(props: PromotionsScreenProps) {
    super(props);
    this.visibilityChanged = this.visibilityChanged.bind(this);
    this.onAccountStoreChanged = this.onAccountStoreChanged.bind(this);
    this.loadPromotions = this.loadPromotions.bind(this);
    this.logPromotionViewedEvent = this.logPromotionViewedEvent.bind(this);
    this.toReferralScreen = this.toReferralScreen.bind(this);
    this.startConnectionTimeout = this.startConnectionTimeout.bind(this);
    this.handleConnectTimeout = this.handleConnectTimeout.bind(this);

    this.scrollView = React.createRef();
    const result = this.loadPromotions();

    this.state = {
      promotions: result.promotions,
      moblicoPromotions: result.moblicoPromotions,
      qrCode: AccountStore.getQrCode(),
      mediaBaseUrl: result.baseUrl,
      refreshing: false,
      referralCampaign: AccountStore.getReferralCampaign(),
      referralStat: AccountStore.getReferralStat(),
      history: [],
      orders: this.props.orders,
      accountId: AccountStore.getAccountId(),
      previousRoute: null,
    };
  }

  shouldComponentUpdate(
    nextProps: PromotionsScreenProps,
    nextState: PromotionsScreenState,
  ) {
    return (
      nextState.history.length !== this.state.history.length ||
      !_.isEqual(nextState.history, this.state.history) ||
      nextState.refreshing !== this.state.refreshing
    );
  }

  componentDidUpdate(): void {
    const data = AccountStore.getPurchaseHistory(filterTypes.all, 5);
    this.setState(
      {
        history: data,
      },
      () => {
        FirebaseAnalytic.trackEvent('componentDidUpdate', 'PromotionsScreen', {
          ...this.props,
          ...this.state,
        });
      },
    );
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'PromotionsScreen', {
      ...this.props,
      ...this.state,
    });
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({
      previousRoute: previousRoute,
    });
    if (this.props.receipt) {
      setTimeout(() => {
        NavActions.navigate(
          AppRoutes.PurchaseHistoryDetail,
          this.props.receipt,
        );
        store.dispatch(deleteReceipt());
      }, 0);
    }

    ActionsFactory.getAccountActions().loadPurchaseHistory(
      AccountStore.getAccountId(),
      1,
    );
    AccountStore.addChangeListener(this.onAccountStoreChanged);
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountStoreChanged);
    clearTimeout(this.connectTimeout);
  }

  onAccountStoreChanged() {
    const result = this.loadPromotions();

    this.setState(
      {
        promotions: result.promotions,
        moblicoPromotions: result.moblicoPromotions,
        mediaBaseUrl: result.baseUrl,
        qrCode: AccountStore.getQrCode(),
        referralCampaign: AccountStore.getReferralCampaign(),
        referralStat: AccountStore.getReferralStat(),
        history: AccountStore.getPurchaseHistory(filterTypes.all, 5),
        orders: this.props.orders,
        accountId: AccountStore.getAccountId(),
      },
      () => {
        FirebaseAnalytic.trackEvent(
          'onAccountStoreChanged',
          'PromotionsScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      },
    );
  }

  async handleConnectTimeout(showError = false) {
    FirebaseAnalytic.trackEvent('handleConnectTimeout', 'PromotionsScreen', {
      ...this.props,
      ...this.state,
      showError,
    });
    if (showError) {
      networkError();
    }

    this.setState({
      refreshing: false,
    });
  }

  startConnectionTimeout(timeout: number) {
    return setTimeout(() => {
      this.handleConnectTimeout(this.state.refreshing);
    }, timeout);
  }

  getFullUrl(promotion: PromotionType, imgServer: string) {
    FirebaseAnalytic.trackEvent('getFullUrl', 'PromotionsScreen', {
      ...this.props,
      ...this.state,
      promotion,
      imgServer,
    });

    if (promotion.local) {
      return '';
    }

    let fullUrl = promotion.imagePath;
    if (!fullUrl.startsWith(FULL_URL_START)) {
      fullUrl = `${imgServer}/${promotion.imagePath}`;
    }

    return fullUrl;
  }

  isVideo(promotion: PromotionType, url: string) {
    FirebaseAnalytic.trackEvent('isVideo', 'PromotionsScreen', {
      ...this.props,
      ...this.state,
      promotion,
      url,
    });
    if (promotion.local) {
      return false;
    }

    const imagesLength = IMG_EXTENSIONS.length;
    const lowerUrl = url.toLowerCase();

    for (let i = 0; i < imagesLength; i++) {
      const extension = IMG_EXTENSIONS[i];

      if (lowerUrl.endsWith(extension)) {
        return false;
      }
    }
    return true;
  }

  loadPromotions() {
    let moblicoResponse: MoblicoPromotionType[] = [];
    if (
      AccountStore.getConsumerEngagementId() &&
      AccountStore.isConsumerEngagementEnabled()
    ) {
      moblicoResponse = AccountStore.getMoblicoPromosResponse();
    }

    const now = moment();
    const response = AccountStore.getPromotionsResponse();
    FirebaseAnalytic.trackEvent('loadPromotions', 'PromotionsScreen', {
      ...this.props,
      ...this.state,
      moblicoResponse,
      response,
    });
    if (response && response.discountHeaders && response.discountItems) {
      const mediaPromotions = response.discountHeaders
        .filter(
          (promotion) =>
            promotion.imagePath &&
            (promotion.dateEnd === null ||
              moment(promotion.dateEnd).isAfter(now)),
        )
        .map((promotion) => {
          const fullUrl = this.getFullUrl(promotion, response.imageServer);
          const video = this.isVideo(promotion, fullUrl);
          return {...promotion, fullUrl, video};
        });

      this.discountItems = response.discountItems;
      return {
        promotions: [...mediaPromotions],
        moblicoPromotions: [...moblicoResponse],
        baseUrl: response.imageServer,
      };
    } else {
      return {
        promotions: [],
        moblicoPromotions: [...moblicoResponse],
        baseUrl: '',
      };
    }
  }

  visibilityChanged(isVisible: boolean, promotion: PromotionType) {
    if (isVisible) {
      this.logPromotionViewedEvent(promotion);
    }
  }

  logPromotionViewedEvent(
    promotion: PromotionType | MoblicoPromotionType,
    moblicoPromotion = false,
  ) {
    FirebaseAnalytic.trackEvent('logPromotionViewedEvent', 'PromotionsScreen', {
      ...this.props,
      ...this.state,
      promotion,
      moblicoPromotion,
    });
    const id: string | number =
      (promotion as PromotionType).discountHeaderId ??
      (promotion as MoblicoPromotionType).id;

    if (promotion && !this.promotionsViewed.includes(id)) {
      this.promotionsViewed.push(id);
      Events.Promotion.trackEvent(
        id,
        promotion.name,
        'VIEWED',
        moblicoPromotion,
      );
    }
  }

  logPromotionClickedEvent(promotion: PromotionType) {
    if (promotion) {
      Events.Promotion.trackEvent(
        promotion.discountHeaderId,
        promotion.name,
        'CLICKED',
      );
    }
  }

  render() {
    let promotions: JSX.Element[] = [];
    promotions.push(<View key={-2} style={styles.spacer} />);

    if (this.state.orders?.length > 0) {
      promotions.push(
        <OrderSection
          key={-8}
          onButtonClick={(order: SaleOrder) => {
            NavActions.navigate(AppRoutes.PurchaseHistoryDetail, {
              transactionId: order.transactionId,
              transactionType: AccountConstants.SALE_TYPE,
            });
          }}
          orders={this.state.orders}
          onCloseClick={(index: number) => {
            ActionsFactory.getAccountActions().removeRecentOrder(index);
          }}
        />,
      );
    }

    if (this.context.state.rewards) {
      promotions.push(
        UIManager.getHomeRewardSection(
          this.context.state.rewards,
          this.context.state.rewardSteps,
          styles.imageContainer,
          true,
        ),
      );
    }

    promotions.push(<View key={-7} style={styles.spacer} />);

    if (this.state.referralStat && this.state.referralCampaign) {
      promotions.push(
        <ReferralSection
          key={-6}
          onInviteClick={this.toReferralScreen}
          stats={this.state.referralStat}
        />,
      );
    }

    if (this.state.moblicoPromotions.length > 0) {
      promotions.push(
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels.current_offers}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.current_offers}, text`}
          key={-3}
          style={styles.promotionsLabel}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
        >
          {Localized.Labels.current_offers}
        </AVText>,
      );
    }

    promotions.push(
      <MoblicoPromotionsCarousel
        key={-4}
        promos={this.state.moblicoPromotions}
        promotionViewed={this.logPromotionViewedEvent}
      />,
    );

    if (
      AccountStore.isSendSnackEnabledForLocation() &&
      AccountStore.isConsumerSendSnackEnabled()
    ) {
      promotions.push(
        <SnackSection
          key={-9}
          onSendASnackClick={() => {
            NavActions.push(AppRoutes.SendSnack);
          }}
          onViewMoreGiftsClick={() => {
            NavActions.push(AppRoutes.GiftFeed);
          }}
          giftFeeds={this.props.giftFeeds}
        />,
      );
    }

    if (this.state.promotions.length > 0) {
      promotions.push(
        <AVText
          key={-5}
          accessible={true}
          accessibilityLabel={Localized.Labels.instant_discounts}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.instant_discounts}, text`}
          style={styles.promotionsLabel}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
        >
          {Localized.Labels.instant_discounts}
        </AVText>,
      );
    }

    promotions = promotions.concat(
      this.state.promotions.map((promotion) => (
        <Promotion
          promotionViewed={this.logPromotionViewedEvent}
          onClick={() => {
            this.logPromotionClickedEvent(promotion);
            this.context.actions.promotionSelected(
              promotion,
              this.discountItems,
            );
          }}
          navigation={this.props.navigation}
          baseUrl={this.state.mediaBaseUrl}
          key={promotion.discountHeaderId}
          promotion={promotion}
        />
      )),
    );

    promotions.push(
      <View key={999} style={styles.historyContainer}>
        <AVText
          style={styles.historyText}
          accessible={true}
          accessibilityLabel={Localized.Labels.latest_transactions}
          accessibilityRole="header"
          role="heading"
          aria-label={`${Localized.Labels.latest_transactions}`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
        >
          {Localized.Labels.latest_transactions}
        </AVText>

        {this.state.history.length > 0 &&
          this.state.history.map((item, index) => {
            return (
              <HistoryItem
                {...this.props}
                key={item.Id + item.Amount}
                historyItem={item}
                onPress={(historyItem) =>
                  AccountHistoryList.onItemPress(historyItem, false)
                }
                index={index}
              />
            );
          })}

        {this.state.history.length === 0 && this.state.refreshing === false && (
          <AVText
            accessible={true}
            accessibilityLabel={Localized.Labels.no_history_available}
            accessibilityRole="text"
            aria-label={`${Localized.Labels.no_history_available}, text`}
            style={styles.noHistoryText}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
          >
            No History Available
          </AVText>
        )}
        {this.state.history.length === 0 && this.state.refreshing === true && (
          <AVText
            accessible={true}
            accessibilityLabel={Localized.Labels.loading}
            accessibilityState={{busy: this.state.refreshing}}
            accessibilityRole="text"
            aria-busy={this.state.refreshing}
            aria-label={`${Localized.Labels.loading}, text`}
            style={styles.noHistoryText}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
          >
            Loading ... Please Wait
          </AVText>
        )}

        <RoundedButton
          containerStyle={styles.viewMoreButton}
          buttonType={ButtonType.outline}
          accessibilityLabel={Localized.Labels.view_more_transactions}
          accessibilityRole="button"
          role="button"
          aria-label={Localized.Labels.view_more_transactions}
          onPress={() => {
            NavActions.nestedPush(
              [AppRoutes.Main, AppRoutes.Settings],
              AppRoutes.PurchaseHistory,
            );
          }}
          text={Localized.Labels.view_more_transactions}
        />
      </View>,
    );

    if (Platform.OS === 'web') {
      return (
        <Header title={Localized.Labels.home}>
          <ScrollView style={styles.list}>
            <View style={Styles.Style.maxWidthContainer}>{promotions}</View>
          </ScrollView>
        </Header>
      );
    }

    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar
          barStyle={'dark-content'}
          backgroundColor={Styles.white}
        />

        <Animated.ScrollView
          style={styles.list}
          stickyHeaderIndices={UIManager.getPromotionsStickyHeaderIndices()}
          ref={(scrollView) => {
            this.scrollView = scrollView;
          }}
          scrollEventThrottle={16}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: this.props.scrollY,
                  },
                },
              },
            ],
            {
              useNativeDriver: true,
            },
          )}
          refreshControl={
            this.props.isConnected && (
              <RefreshControl
                accessible={true}
                accessibilityLabel={this.state.refreshing ? 'refreshing' : ''}
                aria-label={this.state.refreshing ? 'refreshing' : ''}
                refreshing={this.state.refreshing}
                onRefresh={this._onRefresh}
                tintColor={Styles.primaryColor}
                colors={[Styles.primaryColor]}
                titleColor={Styles.black}
              />
            )
          }
        >
          {UIManager.getScanLabel(Localized)}
          {UIManager.getPromotionsHeader(
            this.state.qrCode,
            this.context.state.balance,
            this.props.animationScrollY,
            Localized,
            this.props.globalize,
            this.props.isConnected,
            this.context,
          )}
          {promotions}
        </Animated.ScrollView>
      </SafeAreaView>
    );
  }

  toReferralScreen() {
    NavActions.navigate(AppRoutes.Referral, {
      referralCampaign: this.state.referralCampaign,
      onSuccess: () => {
        const {referralStat} = this.state;

        if (referralStat) {
          referralStat.referrals++;
          this.setState({
            referralStat,
          });
        }
      },
    });
  }

  _onRefresh = async () => {
    FirebaseAnalytic.trackEvent('_onRefresh', 'PromotionsScreen', {
      ...this.props,
      ...this.state,
    });
    this.started = moment();
    this.setState({
      refreshing: true,
    });
    try {
      clearTimeout(this.connectTimeout);

      this.connectTimeout = this.startConnectionTimeout(30000);
      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      });
      const duration = moment().diff(this.started, 'ms');

      // put in some delay for ux if necessary
      if (duration < 2000) {
        await Util.delay(2000 - duration);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'PromotionScreen:_onRefresh',
        error.message ? error.message : error.toString(),
        guid,
        {
          ...this.props,
          ...this.state,
        },
      );
    }
    this.setState({
      refreshing: false,
    });
  };
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    flex: 1,
  },
  historyContainer: {
    paddingBottom: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m3,
  },
  historyText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m0,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m1,
    textAlign: 'left',
  },
  noHistoryText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m0,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m1,
    textAlign: 'center',
  },
  imageContainer: {
    marginBottom: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m1,
  },
  list: {
    flex: 1,
    flexDirection: 'column',
  },
  spacer: {
    height: 10,
  },
  viewMoreButton: {
    alignSelf: 'center',
    marginTop: Styles.Spacing.m3,
  },
  promotionsLabel: {
    fontSize: Styles.Fonts.f2,
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
    fontWeight: 'bold',
  },
});
const enhance = compose(
  withState('scrollY', 'setScrollY', () => new Animated.Value(0)),
  withProps(({scrollY}) => ({
    animationScrollY: scrollY,
  })),
);
const ConnectedPromotionsScreen = connect((state: RootState) => ({
  receipt: state.account.savedReceipt,
  orders: state.account.orders,
  giftFeeds: state.snack.gifts.slice(0, 10),
}))(PromotionsScreen);

export default withForwardedNavigationParams()(
  withGlobalize(enhance(withIsConnected(ConnectedPromotionsScreen))),
);
