import React, {useState, useContext, useEffect} from 'react';
import {StyleSheet, KeyboardAvoidingView, View, Platform} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import BaseScreen from '../BaseScreen';
import withIsConnected from '../../hoc/withIsConnected';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import ActionsFactory from 'src/actions/ActionsFactory';
import Events from 'src/logging/Events';
import {alertError, alertSuccess} from 'src/components/helpers/AlertHelper';
import {ScreenProps} from 'src/types/Screen';
import ScreenContext from 'src/components/ScreenContext';
import {Identifier} from 'src/types/serverTypes/Account';
import AccountStore from 'src/stores/AccountStore';
import {LocationType} from 'src/types/Location';
import CartTypes from 'src/constants/cart/CartTypes';
import AccountConstants from 'src/constants/AccountConstants';
import {getPreviousRouteName} from 'src/Util';
import {useNavigation} from '@react-navigation/native';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type EnterDiscountCodeScreenProps = {
  locationId: string;
  cartType: CartTypes;
  consumerRoleIdentifier: Identifier;
  addDiscountToCart: () => void;
  location?: LocationType;
};

const EnterDiscountCodeScreen = ({
  locationId,
  consumerRoleIdentifier,
  addDiscountToCart,
  cartType,
  location,
}: EnterDiscountCodeScreenProps) => {
  const {actions}: ScreenProps = useContext(ScreenContext);
  const [previousRoute, setPreviousRoute] = useState('');
  const navigation = useNavigation();

  const [discountCode, setDiscountCode] = useState('');
  const [roleIdentifier, setRoleIdentifier] = useState(
    consumerRoleIdentifier ?? AccountStore.getConsumerIdentifier(),
  );

  useEffect(() => {
    const prevRoute = getPreviousRouteName(navigation?.getState()?.routes);
    setPreviousRoute(prevRoute);

    FirebaseAnalytic.trackEvent('useEffect', 'EnterDiscountCodeScreen', {
      prevRoute,
    });

    AccountStore.addChangeListener(onAccountStoreChanged);
    return () => {
      AccountStore.removeChangeListener(onAccountStoreChanged);
    };
  }, []);

  const onAccountStoreChanged = () => {
    setRoleIdentifier(AccountStore.getConsumerIdentifier());

    FirebaseAnalytic.trackEvent('useEffect', 'EnterDiscountCodeScreen', {
      roleIdentifier: AccountStore.getConsumerIdentifier(),
    });
  };

  const handleScanCode = () => {
    FirebaseAnalytic.trackEvent('handleScanCode', 'EnterDiscountCodeScreen', {
      locationId,
      roleIdentifier,
      addDiscountToCart,
      cartType,
      location,
    });
    NavActions.popToRoute(AppRoutes.ScanDiscountCode, {
      locationId,
      roleIdentifier,
      addDiscountToCart,
      cartType,
      location,
    });
  };

  const onSave = async () => {
    actions.showSpinner();
    FirebaseAnalytic.trackEvent('onSave', 'EnterDiscountCodeScreen', {
      locationId,
      consumerRoleIdentifier,
      addDiscountToCart,
      cartType,
      location,
    });

    try {
      const discountCodeResponse =
        await ActionsFactory.getAccountActions().validateDiscountCode(
          locationId,
          discountCode,
        );
      //api returns success statusCode = undefined
      if (discountCodeResponse?.statusCode !== undefined) {
        throw discountCodeResponse.message;
      }
      if (roleIdentifier?.value) {
        await ActionsFactory.getAccountActions().deleteAssociatedDiscountCode(
          roleIdentifier?.accountIdentifierId,
        );
      }
      const response =
        await ActionsFactory.getAccountActions().associateDiscountCode(
          discountCode,
        );

      FirebaseAnalytic.trackEvent(
        'onSave associateDiscountCode',
        'EnterDiscountCodeScreen',
        {
          locationId,
          consumerRoleIdentifier,
          addDiscountToCart,
          cartType,
          location,
          response,
        },
      );

      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      }),
        alertSuccess(
          Localized.Success.any_applicable_promotions_will_now_apply,
          () => {
            NavActions.navigate(AppRoutes.Scan, {
              locationId,
              cartType,
              marketName: location.name,
              locationType: AccountConstants.SOS_LOCATION_TYPE,
              beaconId: location.beaconId ?? location.locationId,
              location: location,
              discountCode,
            }),
              addDiscountToCart();
          },
          Localized.Success.discount_recognized,
        );
    } catch (error) {
      alertError(
        Localized.Errors.we_couldnt_recognize_that_code,
        null,
        () => {
          ActionsFactory.getAccountActions().reloadConsumerData({
            accountId: AccountStore.getAccountId(),
            accountBalanceId: AccountStore.getAccountBalanceId(),
            email: AccountStore.getEmail(),
            userInitiated: true,
          });
        },
        Localized.Errors.sorry,
      );
      Events.Error.trackEvent(
        'Exception',
        'EnterDiscountCodeScreen:onSave',
        error.message ? error.message : error.toString(),
      );
    } finally {
      actions.hideSpinner();
    }
  };

  return (
    <BaseScreen
      previousRoute={previousRoute}
      accessibilityLabel={Localized.Labels.back_arrow}
      accessibilityHint=""
      aria-label={Localized.Labels.back_arrow}
      title={Localized.Labels.add_discount}
    >
      <AVText
        accessible={true}
        accessibilityLabel={Localized.Labels.enter_your_discount_code}
        aria-label={Localized.Labels.enter_your_discount_code}
        style={styles.descriptionText}
      >
        {Localized.Labels.enter_your_discount_code}
      </AVText>
      <View style={styles.content}>
        <KeyboardAvoidingView behavior="height">
          <AllyTextInput
            label={Localized.Labels.discount_code}
            value={discountCode}
            accessible={true}
            accessibilityLabel={Localized.Labels.discount_code}
            accessibilityValue={{text: discountCode}}
            onChangeText={(text) => setDiscountCode(text)}
            autoCapitalize="none"
          />

          {Platform.OS !== 'web' && (
            <RoundedButton
              containerStyle={styles.btnScanCode}
              buttonType={ButtonType.outline}
              onPress={handleScanCode}
              accessible={true}
              accessibilityLabel={Localized.Labels.scan_code}
              accessibilityRole="button"
              role="button"
              aria-label={Localized.Labels.scan_code}
              text={Localized.Labels.scan_code}
            />
          )}
        </KeyboardAvoidingView>
      </View>
      <RoundedButton
        buttonType={ButtonType.action}
        accessible={true}
        accessibilityLabel={Localized.Buttons.save}
        accessibilityRole="button"
        role="button"
        aria-label={Localized.Buttons.save}
        text={Localized.Buttons.save}
        onPress={() => onSave()}
      />
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  descriptionText: {
    marginTop: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f2,
  },
  scanditContainer: {
    marginTop: Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m2,
    overflow: 'hidden',
    marginHorizontal: Styles.Spacing.m3,
    aspectRatio: 1,
  },
  btnScanCode: {
    alignSelf: 'flex-end',
    marginTop: Styles.Spacing.m3,
    marginRight: Styles.Spacing.m3,
  },
  content: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
  },
});

export default withForwardedNavigationParams()(
  withIsConnected(EnterDiscountCodeScreen),
);
