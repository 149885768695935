import moment from 'moment';
import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Util from 'src/Util';
import AVTextInput from '../../elements/AVTextInput';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withScrollToElement from '../../hoc/withScrollToElement';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import {ScrollToElementProps} from 'src/types/Screen';
import {SetupModel} from 'src/types/SetupModel';
import {getCurrency} from 'src/constants/Countries';
import UIManager from '../../elements/ui/UIManager';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import CreateAccountHandler from '../../elements/createAccount/CreateAccountHandler';
import {Referral} from 'src/types/Referral';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import PrivacyPolicyHandler from '../../elements/createAccount/CreateAccountPrivacyPolicyHandler';
import {getPreviousRouteName} from '../../../Util';
import {NavigationProp} from '@react-navigation/native';
import Switch from 'src/components/elements/Switch';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import AllyTextInput from 'src/components/elements/AllyTextInput';

type AccountSetupProps = ScrollToElementProps & {
  country: string;
  showPin: boolean;
  lastName: string;
  firstName: string;
  email: string;
  accountId: string;
  importId: string | null | undefined;
  locationId: string | null | undefined;
  referral: Referral | null | undefined;
  token: string | null | undefined;
  navigation?: NavigationProp<AccountSetupScreen>;
};
type AccountSetupState = {
  pinRequired: boolean;
  email: string;
  confirmEmail: string;
  accountId: string;
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
  pin: string;
  confirmPin: string;
  acceptUserAgreement: boolean;
  acceptPrivacyPolicy: boolean;
  previousRoute: string;
};
const MIN_PASSWORD_LENGTH = 8;
const MIN_PIN_LENGTH = 4;

class AccountSetupScreen extends React.Component<
  AccountSetupProps,
  AccountSetupState
> {
  pin: AVTextInput | null;
  confirmPassword: AVTextInput | null;
  scrollView: ScrollView | null;
  subscriptions: Array<any>;
  password: AVTextInput | null;
  lastName: AVTextInput | null;
  firstName: AVTextInput | null;
  confirmPin: AVTextInput | null;
  startTime: moment.Moment;
  createAccountHandler: CreateAccountHandler | null | undefined;
  privacyHandler: PrivacyPolicyHandler | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  static defaultProps = {
    country: 'USA',
    firstName: '',
    lastName: '',
    showPin: true,
  };

  constructor(props: AccountSetupProps) {
    super(props);
    this.state = {
      pinRequired: false,
      email: props.email,
      accountId: props.accountId,
      firstname: props.firstName,
      lastname: props.lastName,
      pin: '',
      confirmPin: '',
      acceptUserAgreement: false,
      acceptPrivacyPolicy: false,
      password: '',
      confirmPassword: '',
      confirmEmail: '',
      previousRoute: '',
    };
    this.startTime = moment();
    this.inputFocused = this.inputFocused.bind(this);
    this.validate = this.validate.bind(this);
    this.userAgreementPressed = this.userAgreementPressed.bind(this);
    this.privacyPolicyPressed = this.privacyPolicyPressed.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.displayAccountSetupError = this.displayAccountSetupError.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.focusInput = this.focusInput.bind(this);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'AccountSetupScreen', {
      ...this.props,
      ...this.state,
    });

    const previousRoute = getPreviousRouteName(
      this.props?.navigation?.getState()?.routes,
    );
    this.setState({previousRoute: previousRoute});
  }

  inputFocused(ref: AVTextInput | null) {
    this.props.scrollToElement(this.scrollView, ref);
  }

  focusInput(input: AVTextInput | null) {
    if (input) {
      input.focus();
    }
  }

  validate(setupModel: SetupModel) {
    FirebaseAnalytic.trackEvent('validate', 'AccountSetupScreen', {
      ...this.props,
      ...this.state,
      setupModel,
    });
    if (this.props.showPin && setupModel.pin !== this.state.confirmPin) {
      return Localized.Errors.pins_do_not_match;
    }

    if (
      this.props.showPin &&
      (!setupModel.pin || setupModel.pin.length < MIN_PIN_LENGTH)
    ) {
      return Localized.Errors.pin_has_to_be_four_digits;
    }

    if (
      !setupModel.firstName ||
      !setupModel.lastName ||
      (this.props.showPin && !setupModel.pin)
    ) {
      return Localized.Errors.all_fields_required;
    }

    if (!this.state.acceptUserAgreement) {
      return Localized.Errors.must_accept_terms;
    }

    if (!this.state.acceptPrivacyPolicy) {
      return Localized.Errors.must_accept_privacy_policy_toggle;
    }

    return null;
  }

  userAgreementPressed() {
    const url = Settings.getTermsUrl(
      Settings.getLocale(),
      AccountStore.getPrivacyTermsType(),
    );
    const backupUrl = Settings.getTermsUrl(
      Settings.getDefaultLocale(),
      AccountStore.getPrivacyTermsType(),
    );
    FirebaseAnalytic.trackEvent('userAgreementPressed', 'AccountSetupScreen', {
      ...this.props,
      ...this.state,
      title: Localized.Labels.terms,
      url,
      backupUrl,
      strings: Localized,
      navigate: AppRoutes.WebContent,
    });
    NavActions.push(AppRoutes.WebContent, {
      title: Localized.Labels.terms,
      url,
      backupUrl,
      strings: Localized,
    });
  }

  privacyPolicyPressed() {
    if (this.privacyHandler) {
      this.privacyHandler.showPrivacyPolicy();
    }
  }

  handleBack() {
    Events.AccountCreation.trackEvent(this.startTime, 'VerifyEmail');
  }

  displayAccountSetupError(error: string | any) {
    let message = Localized.Errors.problem_creating_account;

    FirebaseAnalytic.trackEvent(
      'displayAccountSetupError',
      'AccountSetupScreen',
      {
        ...this.props,
        ...this.state,
        error,
      },
    );
    if (
      typeof error === 'string' &&
      error.includes('specified already exists')
    ) {
      message += ` ${Localized.Errors.email_already_exists}`;
    } else if (typeof error === 'string' && error.includes('characters long')) {
      message += ` ${Localized.Errors.password_not_long_enough}`;
    } else {
      message += ` ${Localized.Errors.check_internet_connection}`;
    }

    if (!message.endsWith('.')) {
      message += '.';
    }

    alertError(message);
  }

  async handleClick() {
    this.context.actions.showSpinner();
    const {email, firstname, lastname, pin, accountId} = this.state;
    const street1 = '';
    const city = '';
    const state = '';
    const zip = '';
    const setupModel: SetupModel = {
      firstName: firstname,
      lastName: lastname,
      accountId,
      street1,
      city,
      state,
      zip,
      pin: this.props.showPin ? pin : null,
      locale: Settings.getLocale(),
      region: this.props.country,
      currency: getCurrency(this.props.country),
      locationId: this.props.locationId,
      token: this.props.token,
      referralId: this.props.referral?.referralId,
      payrollImportId: this.props.importId,
    };
    const errorMessage = this.validate(setupModel);
    this.context.actions.hideSpinner();

    FirebaseAnalytic.trackEvent('handleClick', 'AccountSetupScreen', {
      ...this.props,
      ...this.state,
      setupModel,
      errorMessage,
    });

    if (errorMessage) {
      alertError(errorMessage);
    } else if (this.props.country === 'ITA') {
      NavActions.push(AppRoutes.ItalyAccountInfo, {
        setupModel,
        email,
        pinRequired: this.state.pinRequired,
        referral: this.props.referral,
      });
    } else if (UIManager.showDemographicScreen()) {
      NavActions.push(AppRoutes.Demographic, {
        setupModel,
        email,
        pinRequired: this.state.pinRequired,
        referral: this.props.referral,
      });
    } else if (this.createAccountHandler) {
      this.createAccountHandler.handleCreateAccount(
        setupModel,
        email,
        this.state.pinRequired,
        this.props.referral,
      );
    }
  }

  render() {
    let pinSection;

    if (this.props.showPin) {
      pinSection = (
        <View style={styles.row}>
          <View style={styles.inputRowLeft}>
            <AllyTextInput
              label={Localized.Labels.enter_pin}
              value={this.state.pin}
              accessible={true}
              accessibilityLabel="PIN"
              // accessibilityValue={this.state.pin}
              accessibilityHint="Pin must be four digits"
              testID="pinField"
              helperText={Localized.Labels.pin_requirements}
              maxLength={4}
              secureTextEntry={true}
              onChangeText={(text) =>
                this.setState({pin: Util.onlyNumbers(text)})
              }
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
          </View>
          <View style={styles.inputRowRight}>
            <AllyTextInput
              label={Localized.Labels.re_enter_pin}
              value={this.state.confirmPin}
              accessible={true}
              accessibilityLabel="Confirm PIN"
              // accessibilityValue={this.state.confirmPin}
              accessibilityHint="Pin must be four digits"
              testID="confirmPinField"
              maxLength={4}
              secureTextEntry={true}
              onChangeText={(text) =>
                this.setState({confirmPin: Util.onlyNumbers(text)})
              }
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
          </View>
        </View>
      );
    }

    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.create_account}
        instructionText={Localized.Labels.enter_information}
        onBackSelect={this.handleBack}
        previousRoute={this.state.previousRoute}
      >
        {!UIManager.showDemographicScreen() && (
          <CreateAccountHandler
            context={this.context}
            ref={(createAccountHandler) => {
              this.createAccountHandler = createAccountHandler;
            }}
          />
        )}
        <PrivacyPolicyHandler
          isFromCreateAccount
          ref={(privacyHandler) => {
            this.privacyHandler = privacyHandler;
          }}
          onAcceptPrivacyCallback={() =>
            this.setState({
              acceptPrivacyPolicy: true,
            })
          }
        />
        <ScrollView
          style={styles.scrollView}
          ref={(ref) => {
            this.scrollView = ref;
          }}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.header}>
            <AVText
              accessible={true}
              accessibilityLabel={`Email ${this.state.email}`}
              accessibilityRole="text"
              aria-label={`Email ${this.state.email}`}
              style={styles.emailText}
            >
              {this.state.email}
            </AVText>
          </View>

          <View style={styles.inputsContainer}>
            <View style={styles.row}>
              <View style={styles.inputRowLeft}>
                <AllyTextInput
                  label={Localized.Labels.first_name}
                  value={this.state.firstname}
                  accessible={true}
                  accessibilityLabel="First name"
                  accessibilityValue={{text: this.state.firstname}}
                  testID="firstNameInput"
                  onChangeText={(text) => this.setState({firstname: text})}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                />
              </View>
              <View style={styles.inputRowRight}>
                <AllyTextInput
                  label={Localized.Labels.last_name}
                  value={this.state.lastname}
                  accessible={true}
                  accessibilityLabel="Last name"
                  accessibilityValue={{text: this.state.lastname}}
                  testID="lastNameInput"
                  onChangeText={(text) => this.setState({lastname: text})}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                />
              </View>
            </View>
            {pinSection}
            <View style={styles.rowOption}>
              <Switch
                testID="pinRequiredSwitch"
                text={Localized.Labels.pin_required}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                value={this.state.pinRequired}
                accessibilityLabel="PIN Required"
                accessibilityState={{checked: this.state.pinRequired}}
                onValueChange={(value: boolean) =>
                  this.setState({
                    pinRequired: value,
                  })
                }
              />
            </View>
            <View style={styles.rowOption}>
              <Switch
                testID="userAgreementSwitch"
                text={Localized.Labels.accept_the}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                linkText={Localized.Labels.terms}
                openLink={this.userAgreementPressed}
                value={this.state.acceptUserAgreement}
                accessibilityLabel="Accept User Agreement"
                accessibilityState={{checked: this.state.acceptUserAgreement}}
                onValueChange={(value: boolean) =>
                  this.setState({
                    acceptUserAgreement: !value,
                  })
                }
              />
            </View>
            <View style={[styles.rowOption, styles.bottomRow]}>
              <Switch
                testID="privacyPolicySwitch"
                text={Localized.Labels.accept_the}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                linkText={Localized.Labels.privacy_policy}
                openLink={this.privacyPolicyPressed}
                value={this.state.acceptPrivacyPolicy}
                accessibilityLabel="Accept Privacy Policy"
                accessibilityState={{checked: this.state.acceptPrivacyPolicy}}
                onValueChange={(value: boolean) =>
                  this.setState({
                    acceptPrivacyPolicy: !value,
                  })
                }
              />
            </View>
          </View>
        </ScrollView>
        <RoundedButton
          buttonType={ButtonType.action}
          accessible={true}
          accessibilityLabel="Next"
          accessibilityRole="button"
          aria-label="Next"
          role="button"
          onPress={this.handleClick}
          text={Localized.Buttons.next}
          textStyle={styles.nextButtonText}
        />
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  bottomRow: {
    marginBottom: Styles.Spacing.m5,
  },
  emailText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
  },
  goBack: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
  },
  header: {
    alignSelf: 'stretch',
  },
  inputRowLeft: {
    flex: 0.5,
    paddingRight: Styles.Spacing.m1,
  },
  inputRowRight: {
    flex: 0.5,
    paddingLeft: Styles.Spacing.m1,
  },
  inputsContainer: {
    flexDirection: 'column',
    marginTop: Styles.Spacing.m1,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowOption: {
    alignItems: 'center',
    flexDirection: 'row',
    height: Styles.Heights.h4,
    justifyContent: 'space-between',
  },
  scrollView: {
    alignSelf: 'stretch',
  },
  switch: {
    backgroundColor: Styles.lightGray,
    borderRadius: Styles.Spacing.m3,
    overflow: 'hidden',
  },
  switchText: {
    alignItems: 'center',
    flex: 1,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams()(
  withScrollToElement(AccountSetupScreen),
);
