import React from 'react';
import {
  Keyboard,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ActionsFactory from 'src/actions/ActionsFactory';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import BackSubheader from 'src/components/elements/BackSubheader';
import Localized from 'src/constants/AppStrings';
import {authStore} from 'src/init';
import Events from 'src/logging/Events';
import {getMobileAuth0Client, getWebAuth0Client} from 'src/nativeModules/Auth0';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import OneSignal from 'src/nativeModules/OneSignal';
import uuid from 'src/nativeModules/UUID';
import {store} from 'src/redux/store';
import DealRepository from 'src/services/aws/DealRepository';
import SyncHelper from 'src/services/SyncService';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import type {ScreenProps} from 'src/types/Screen';
import type {SnackAction} from 'src/types/Snack';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import AVText from '../elements/AVText';
import CreateAccountPrivacyPolicyHandler from '../elements/createAccount/CreateAccountPrivacyPolicyHandler';
import DeepLinkHandler from '../elements/DeepLinkHandler';
import FocusAwareStatusBar from '../elements/FocusAwareStatusBar';
import Logo from '../elements/Logo';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import SafeAreaView from '../elements/SafeAreaView';
import UIManager from '../elements/ui/UIManager';
import {alertError} from '../helpers/AlertHelper';
import ScreenContext from '../ScreenContext';
import Styles from '../Styles';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import moment from 'moment';

function sessionTimeOut() {
  setTimeout(
    () =>
      alertError(Localized.Errors.login_failure, null, () => {
        ActionsFactory.getAccountActions().logout().catch();
        NavActions.resetMultiple([AppRoutes.Welcome, AppRoutes.WelcomeAuth0]);
      }),
    300,
  );
}

type WelcomeAuth0Props = {
  snack?: SnackAction;
  referralId: string;
  token: string;
  navigation?: NavigationProp<WelcomeAuth0Screen>;
};

type WelcomeAuth0State = {
  fullVersion: string;
  previousRoute?: string;
};

export class WelcomeAuth0Screen extends React.Component<
  WelcomeAuth0Props,
  WelcomeAuth0State
> {
  privacyHandler: CreateAccountPrivacyPolicyHandler | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: WelcomeAuth0Props) {
    super(props);
    this.state = {
      fullVersion: '',
      previousRoute: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.webLoginAuth0 = this.webLoginAuth0.bind(this);
  }

  async componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props?.navigation?.getState()?.routes,
    );
    const fullVersion = await Settings.getFullDisplayVersion(Platform.OS);
    this.setState({
      fullVersion,
      previousRoute: previousRoute,
    });
  }

  async webLoginAuth0() {
    await getWebAuth0Client().loginWithPopup();
    const user = await getWebAuth0Client().getUser();

    if (!user) {
      return;
    }
    const accessToken = await getWebAuth0Client().getTokenSilently({
      detailedResponse: true,
    });
    const idInfo = await getWebAuth0Client().getIdTokenClaims();

    await authStore.storeSession({
      accessToken: accessToken.access_token,
      idToken: idInfo.__raw,
      expiresAt: moment().unix() + accessToken.expires_in,
      tokenType: 'Bearer',
      scope: '',
      refreshToken: 'web',
    });

    const account = await ActionsFactory.getAccountActions().loginLegacy();

    if (account.firstName === null && account.email) {
      this.context.actions.changeRootRoute(AppRoutes.Login);
      const params = {
        accountId: account.accountId,
        email: account.unverifiedEmail,
        firstName: account.firstName,
        lastName: account.lastName,
        showPin: !account.pinHash,
        referralId: this.props.referralId,
        token: this.props.token,
      };
      NavActions.push(AppRoutes.CountrySelection, params);
      return;
    } else if (account.unverifiedEmail) {
      sessionTimeOut();
      return;
    }

    await WelcomeAuth0Screen.onLoginSuccess(
      account,
      this.context,
      this.props.referralId,
      this.props.token,
      undefined,
      this.props.snack,
    );
  }

  async handleClick() {
    const Auth0 = getMobileAuth0Client();
    const env = store.getState().environment.env;

    try {
      const credentials = await Auth0.webAuth.authorize(
        {
          scope: 'openid offline_access profile email',
          audience: Settings.auth0[env].audience,
          organization: Settings.auth0[env].orgIdAuth0,
        },
        {
          ephemeralSession: true,
        },
      );

      if (credentials) {
        const {snack} = this.props;
        await authStore.storeSession(credentials);

        NavActions.replace(AppRoutes.Redirect, {
          context: this.context,
          initialTabRoute: undefined,
          snack,
        });
      }
    } catch (error) {
      // no error user cancelled
      if (error && error.message?.indexOf('user cancelled') >= 0) {
        return;
      }



      const guid = await uuid.getRandomUUID();

      Events.Error.trackEvent(
        'Exception',
        'Login:Error',
        error.toString(),
        guid,
      );

      alertError('Unable to login at this time, please try again later.');
    }
  }

  static async onLoginSuccess(
    account: any,
    context: ScreenProps,
    referralId: string,
    token: string,
    initialTabRoute?: AppRoutes,
    snack?: SnackAction,
  ) {
    DealRepository.clearDeals();
    if (!AccountStore.isDemo()) {
      OneSignal.sendTags({
        email: account.email,
        mka: account.accountId,
        env: store.getState().environment.env,
      });
      OneSignal.setExternalUserId(account.accountId);
    }

    if (account.accountId) {
      FirebaseAnalytic.setUserId(account.accountId, {
        accountId: account.accountId,
        email: account.email,
        unverifiedEmail: account.unverifiedEmail,
        mka: account.accountId,
        org: account.orgId,
        location: account.locationId,
        region: account.localization.region,
        city: account.city,
        zip: account.zip,
        currency: account.localization.currency,
        env: store.getState().environment.env,
        firstName: account.firstName,
        lastName: account.lastName,
        referralId: referralId,
      });
    }

    Events.AccountLogin.trackEvent(
      DeviceInfo.getModel(),
      account.localization.region,
      account.localization.currency,
    );

    if (snack) {
      context.actions.changeRootRoute(AppRoutes.App, initialTabRoute, {
        nestedNames: [AppRoutes.Inbox],
        name: AppRoutes.SnackDetail,
        params: {snackLocationId: snack.locationId, ...snack},
      });
    } else if (Platform.OS !== 'web') {
      //TODO: Follow up for information about email/unverifiedEmail
      //TODO: unverifiedEmail is null after clicking the link in the email to continue setting up account
      if (account.firstName === null && account.email) {
        NavActions.reset(AppRoutes.WelcomeAuth0);
        NavActions.push(AppRoutes.CountrySelection, {
          accountId: account.accountId,
          email: account.unverifiedEmail,
          firstName: account.firstName,
          lastName: account.lastName,
          showPin: !account.pinHash,
          referralId: referralId,
          token: token,
        });
      } else if (account.unverifiedEmail) {
        sessionTimeOut();
        return;
      } else {
        context.actions.changeRootRoute(
          AppRoutes.AppProductTour,
          initialTabRoute,
        );
      }
    } else {
      context.actions.changeRootRoute(AppRoutes.App, initialTabRoute);
    }

    SyncHelper.performSyncWithServer();
    ActionsFactory.getAccountActions().refreshMessages();
  }

  close() {
    NavActions.pop();
  }

  render() {
    if (Platform.OS === 'web') {
      return this.renderWeb();
    }
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.welcome_back}
      >
        <SafeAreaView style={styles.view}>
          <TouchableWithoutFeedback
            accessible={false}
            onPress={() => (Platform.OS !== 'web' ? Keyboard.dismiss : null)}
          >
            <>
              <FocusAwareStatusBar
                barStyle={UIManager.getLoginStatusBarStyle()}
                backgroundColor={Styles.loginBackground}
              />

              <DeepLinkHandler />
              <CreateAccountPrivacyPolicyHandler
                ref={(privacyHandler) => {
                  this.privacyHandler = privacyHandler;
                }}
              />

              <View style={styles.logoContainer}>
                <Logo />
              </View>
              <KeyboardAvoidingView style={styles.container} behavior="height">
                <ScrollView
                  style={styles.content}
                  keyboardDismissMode="interactive"
                  automaticallyAdjustContentInsets={false}
                  keyboardShouldPersistTaps="handled"
                >
                  <RoundedButton
                    buttonType={ButtonType.normal}
                    containerStyle={styles.topButton}
                    accessibilityLabel={Localized.Buttons.sign_in}
                    accessibilityHint={
                      'Opens a modal for the user to enter email and password'
                    }
                    accessibilityRole="button"
                    aria-label={Localized.Buttons.sign_in}
                    role="button"
                    onPress={this.handleClick}
                    text={Localized.Buttons.sign_in}
                    textStyle={styles.textStyle}
                  />
                </ScrollView>
                <AVText
                  style={styles.versionText}
                  accessible={true}
                  accessibilityLabel={`Application version: ${this.state.fullVersion}`}
                >
                  {this.state.fullVersion}
                </AVText>
              </KeyboardAvoidingView>
            </>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      </BackSubheader>
    );
  }

  renderWeb() {
    return (
      <BackSubheader title={Localized.Labels.welcome_back}>
        <CreateAccountPrivacyPolicyHandler
          ref={(privacyHandler) => {
            this.privacyHandler = privacyHandler;
          }}
        />
        <View
          style={styles.logoContainer}
          accessibilityLabel="365Pay Logo"
          nativeID="365Pay Logo"
        >
          <Logo />
        </View>
        <KeyboardAvoidingView style={styles.container} behavior="height">
          <ScrollView
            style={[styles.content, Styles.Style.maxWidthContainer]}
            keyboardDismissMode="interactive"
            automaticallyAdjustContentInsets={false}
            keyboardShouldPersistTaps="handled"
          >
            <AVText
              style={styles.browserMessage}
              accessibilityLabel="Chrome Preferred Message"
            >
              {Localized.Labels.browser_version_notice}
            </AVText>
            <RoundedButton
              buttonType={ButtonType.normal}
              containerStyle={styles.topButton}
              accessibilityLabel="Login"
              accessibilityHint={
                'Opens a modal for the user to enter their email and password'
              }
              onPress={this.webLoginAuth0}
              text={Localized.Buttons.sign_in}
              textStyle={styles.textStyle}
            />
          </ScrollView>
          <AVText
            style={styles.versionText}
            accessibilityLabel={`Application version: ${this.state.fullVersion}`}
          >
            {this.state.fullVersion}
          </AVText>
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  browserMessage: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    marginTop: Styles.Spacing.m2,
  },
  btnTextLight: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    marginVertical: Styles.Spacing.m1,
    textAlign: 'center',
  },
  container: {
    backgroundColor: Styles.loginBackground,
    flex: 1,
  },
  content: {
    flex: 1,
    marginBottom: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m4,
  },
  instructions: {
    color: Styles.loginText,
    fontSize: Styles.Fonts.f3,
    fontWeight: 'bold',
  },
  logoContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: Styles.loginBackground,
    height: Styles.Heights.h3,
    justifyContent: 'center',
    flex: 1,
  },
  topButton: {
    marginTop: Styles.Spacing.m3,
    alignSelf: 'auto',
    paddingVertical: Styles.Spacing.m1,
  },
  textStyle: {
    flex: 1,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
  },
  versionText: {
    alignSelf: 'flex-start',
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m3,
    marginLeft: Styles.Spacing.m4,
  },
  view: {
    backgroundColor: Styles.loginBackground,
    flex: 1,
    flexDirection: 'column',
  },
});

export default withForwardedNavigationParams<WelcomeAuth0Props>()(
  WelcomeAuth0Screen,
);
