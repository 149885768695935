import _ from 'lodash';
import moment from 'moment';
import MessageTypes from 'src/constants/MessageTypes';
import type {MessageType} from 'src/types/MessageType';
import type {
  AccountDetails,
  AccountInfo,
  AdmPromotionResponse,
  AutoFundResponse,
  PrivacyVersionResponse,
  ThresholdsResponse,
} from 'src/types/PersistentStorageTypes';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import type {DispatchAction} from 'src/dispatchers/DispatchTypes';
import AccountConstants from 'src/constants/AccountConstants';
import TransactionConstants from 'src/constants/TransactionConstants';
import {EventEmitter} from 'events';
import PersistentStore from 'src/services/PersistentStoreService';
import Settings from 'src/Settings';
import BalanceChangedEvent from 'src/logging/BalanceChangedEvent';
import UrlsChangedEvent from 'src/logging/UrlsChangedEvent';
import {MarketAccountIdentifierTypes} from 'src/types/MarketAccountIdentifier';
import type {HistoryItem, SaleOrder} from 'src/types/TransactionDetail';
import type {MoblicoPromotionType} from 'src/types/Promotions';
import type {MachineType} from 'src/types/MachineType';
import type {ReferralCampaign, ReferralStat} from 'src/types/Referral';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import LocationFeatures from 'src/models/LocationFeatures';
import {clearCache} from 'src/providers/cacheHelper';
import LockerTypes from 'src/constants/LockerTypes';
import {CreditCard} from 'src/models/CreditCard';
import {BalanceTypes, Identifier} from 'src/types/serverTypes/Account';
import {store} from '../redux/store';
import OneSignal from 'src/nativeModules/OneSignal';
import Localized from 'src/constants/AppStrings';

const qrCodePrefix = 'GMA:id:';
type AccountStoreData = {
  dateAccountUpdated: string;
  setupModel: any;
  creditCardError: boolean;
  purchaseHistory: Array<HistoryItem>;
  transactionDetail: any;
  loginErrorMessage: string;
  loginError: boolean;
  loadAccountInfoError: boolean;
  accountInfo: AccountInfo | null;
  accountBalance: number;
  accountRewards: number;
  accountId: string;
  accountBalanceId: string;
  qrValue: string;
  showPayroll: boolean;
  payrollAutoComplete: boolean;
  showEatItOrDeleteIt: boolean;
  eatItUrl: string;
  moblicoUrl: string;
  moblicoApiKey: string;
  consumerEngagementEnabled: boolean;
  locationUserKey: string;
  orgUserKey: string;
  urls: {[key: string]: string};
  isDebug: boolean;
  env: string;
  demo: boolean;
  bluetoothChecked: boolean;
  taxId: string;
  displayCurrency: string;
  region: string;
  city: string;
  zip: string;
  currency: string;
  locale?: string;
  username: string;
  pinRequired: boolean;
  org: string;
  locationId: string;
  locationName: string;
  pickupInstruction: string;
  orgName: string;
  consumerEngagementId: string;
  locationType: string;
  locationBroadcastId: string;
  locationFeedback: string;
  rememberUsername: boolean;
  authorizedDevices: Array<string>;
  loggedIn: boolean;
  environments: any;
  setupError: boolean;
  setupErrorMessage: string;
  verifyError: boolean;
  verifySuccess: boolean;
  visitedEatItOrDeleteItWelcomeScreen: boolean;
  fundingOptions: Array<number>;
  promotionsResponse: AdmPromotionResponse | null;
  moblicoPromosResponse: Array<MoblicoPromotionType>;
  thresholdsResponse: ThresholdsResponse | null;
  autoFundResponse: AutoFundResponse | null;
  privacyVersionResponse: PrivacyVersionResponse | null;
  linkedLocations: Array<MachineType>;
  nearbyLocations: Array<MachineType>;
  marketCard: string;
  referralStat?: ReferralStat;
  referralCampaign?: ReferralCampaign;
  orders?: Array<SaleOrder>;
  isSendSnackEnabledForLocation: boolean;
  isConsumerSendSnackEnabled: string;
  locationLockerType: number;
  gifts: Array<GiftItemType>;
  consumerIdentifier: Identifier | null;
};
const defaultUrls = {
  avweb: 'https://live.app.air-vend.com/',
  cartapi: 'https://tally.prod.readytouchpos.com/cartapi',
  tallyapi: 'https://tally.prod.readytouchpos.com/tallyapi',
  shqcartapi: 'https://tally.prod.readytouchpos.com/shqcartapi',
  avlive: 'https://tally.prod.readytouchpos.com/avapi',
  queapi: 'https://tally.prod.readytouchpos.com/queapi',
  gmaapi: 'https://tally.prod.readytouchpos.com/gmaapi',
  capsvr: 'https://tally.prod.readytouchpos.com/capsvr',
  bunnapi: 'https://api.bunn.com',
};

const defaultFundingOptions = [50, 40, 25, 15, 10];
const demoMessages = [
  {
    subject: 'Rewards',
    message:
      'You only need 2,000 more points to be eligible for $3 in account credit.',
    from: "Stan's Snacks",
    date: '2019-04-18 08:15',
    icon: 'star',
    read: false,
  },
  {
    subject: 'Coupon',
    message: 'Thanks for buying SunWater, have you tried MoonWater?',
    from: "Lou's Market",
    date: '2018-04-23 10:26',
    type: MessageTypes.qrcode,
    value: 'moonwater',
    icon: 'water',
    read: false,
  },
];
const currencySymbols = {
  USD: '$',
  // US Dollar
  EUR: '€',
  // Euro
  CRC: '₡',
  // Costa Rican Colón
  GBP: '£',
  // British Pound Sterling
  ILS: '₪',
  // Israeli New Sheqel
  INR: '₹',
  // Indian Rupee
  JPY: '¥',
  // Japanese Yen
  KRW: '₩',
  // South Korean Won
  NGN: '₦',
  // Nigerian Naira
  PHP: '₱',
  // Philippine Peso
  PLN: 'zł',
  // Polish Zloty
  PYG: '₲',
  // Paraguayan Guarani
  THB: '฿',
  // Thai Baht
  UAH: '₴',
  // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

class AccountStore {
  _emitter: EventEmitter = new EventEmitter();
  dispatchToken: number;
  asyncPromise: Promise<any>;
  itemTimeout: any;
  data: AccountStoreData = {
    dateAccountUpdated: '',
    setupModel: {},
    creditCardError: false,
    purchaseHistory: [],
    transactionDetail: {},
    loginErrorMessage: '',
    loginError: false,
    loadAccountInfoError: false,
    accountInfo: null,
    accountBalance: 0,
    accountRewards: 0,
    accountId: '-1',
    accountBalanceId: '',
    qrValue: '',
    showPayroll: false,
    payrollAutoComplete: false,
    showEatItOrDeleteIt: false,
    eatItUrl: '',
    moblicoUrl: '',
    moblicoApiKey: '',
    consumerEngagementEnabled: false,
    locationUserKey: '',
    orgUserKey: '',
    urls: defaultUrls,
    isDebug: false,
    env: Settings.env,
    demo: Settings.demo,
    bluetoothChecked: false,
    taxId: '',
    displayCurrency: '',
    region: '',
    city: '',
    zip: '',
    currency: '',
    locale: '',
    username: '',
    pinRequired: false,
    org: '',
    locationId: '',
    locationName: '',
    orgName: '',
    consumerEngagementId: '',
    locationType: '',
    locationBroadcastId: '',
    locationFeedback: '',
    rememberUsername: false,
    authorizedDevices: [],
    loggedIn: false,
    environments: null,
    setupError: false,
    setupErrorMessage: '',
    verifyError: false,
    verifySuccess: false,
    visitedEatItOrDeleteItWelcomeScreen: false,
    fundingOptions: defaultFundingOptions,
    promotionsResponse: null,
    moblicoPromosResponse: [],
    thresholdsResponse: null,
    autoFundResponse: null,
    privacyVersionResponse: null,
    linkedLocations: [],
    nearbyLocations: [],
    marketCard: '',
    referralCampaign: undefined,
    referralStat: undefined,
    isSendSnackEnabledForLocation: false,
    isConsumerSendSnackEnabled: 'N',
    locationLockerType: LockerTypes.None,
    gifts: [],
    pickupInstruction: '',
    consumerIdentifier: null,
  };

  constructor() {
    this.asyncPromise = Promise.all([
      PersistentStore.getUrlsAndEnv().then((storage) => {
        if (storage.urls) {
          this.data.urls = storage.urls;
          this.logUrlsChangedEvent(this.data.urls);
        }

        if (storage.env !== null && storage.env.length > 0) {
          this.data.env = storage.env;
        }
      }),
      PersistentStore.getStartupData().then((storage) => {
        _.mergeWith(this.data, storage, (a, b) => (b !== null ? b : a));
      }),
      PersistentStore.getAccountDetails()
        .then((storage: AccountDetails) => {
          if (storage.accountId !== null && storage.accountId !== '-1') {
            this.data.loggedIn = true;
          } else {
            storage.accountId = '';
          }

          // used to be stored as a string, need for backwards compatibility
          if (typeof storage.fundingOptions === 'string') {
            storage.fundingOptions = this.parseFundingOptions(
              storage.fundingOptions,
            );
          }

          if (
            storage.qrValue &&
            (storage.qrValue === null || !(storage.qrValue.trim().length > 0))
          ) {
            storage.qrValue = '';
          }

          _.mergeWith(this.data, storage, (a, b) => (b !== null ? b : a));

          return PersistentStore.getUserStartupData(this.data.username);
        })
        .then((storage) => {
          this.data.pinRequired = storage.pinRequired;
          this.data.visitedEatItOrDeleteItWelcomeScreen =
            storage.visitedEatItOrDeleteItWelcomeScreen || false;
          this.data.bluetoothChecked = storage.bluetoothChecked || false;
        }),
      PersistentStore.getGmaAccountDetails().then((storage) => {
        _.mergeWith(this.data, storage, (a, b) => (b !== null ? b : a));
      }),
      PersistentStore.getProcessorInfo().then((storage) => {
        _.mergeWith(this.data, storage, (a, b) => (b !== null ? b : a));
      }),
    ]).then(() => {
      this.emitChange();
      this.emitBalanceChanged();
    });
    this.dispatchToken = AppDispatcher.register(
      async (action: DispatchAction) => {
        if (action.actionType === AccountConstants.ADD_CREDIT_CARD_SUCCESS) {
          this.data.creditCardError = false;
          this.emitChange();
          this.emitCreditCardAdded();
        }

        if (action.actionType === AccountConstants.DELETE_CREDIT_CARD_SUCCESS) {
          this.data.creditCardError = false;
          this.emitChange();
          this.emitCreditCardDeleted(action.data.isDefault);
        }

        if (
          action.actionType === AccountConstants.UPDATE_DEFAULT_TOKEN_SUCCESS
        ) {
          this.emitDefaultTokenUpdated();
        }

        if (action.actionType === AccountConstants.DELETE_CREDIT_CARD_FAIL) {
          this.data.creditCardError = true;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.PIN_CHANGED) {
          this.data.pinRequired = action.data.pinRequired === true;
          let {username} = this.data;

          if (action.data.username) {
            username = action.data.username;
          }

          PersistentStore.setPinRequired(this.data.pinRequired, username);
          this.emitChange();
        }

        if (
          action.actionType ===
          AccountConstants.VISITED_EAT_IT_OR_DELETE_IT_WELCOME
        ) {
          this.data.visitedEatItOrDeleteItWelcomeScreen = true;
          PersistentStore.setVisitedEatItOrDeleteItWelcomeScreen(
            this.data.username,
            true,
          );
        }

        if (
          action.actionType ===
          AccountConstants.LOAD_PURCHASE_HISTORY_SUCCESS_GMA
        ) {
          const newItems: any[] = action.data.response.items.map((item) => {
            return {
              Amount: item.amount,
              Id: item.transactionId,
              Type: item.transactionType,
              Source: item.deviceName,
              TransDate: item.transactionDate,
              DateLong: item.dateCreated,
              TransactionTypeDisplay: item.transactionTypeDisplay,
              Points: item.points,
              BalanceTypeDisplay: item.balanceTypeDisplay,
              ...item,
            };
          });

          this.calculateHistoryBalance(newItems, action.data.page);

          if (action.data.page > 1) {
            this.data.purchaseHistory = [
              ...this.data.purchaseHistory,
              ...newItems,
            ];
          } else {
            newItems.forEach((item) => {
              if (
                !this.data.purchaseHistory.some(
                  (history) =>
                    history.Id === item.Id && history.Type === item.Type,
                )
              ) {
                this.data.purchaseHistory.push(item);
              }
            });
            this.data.purchaseHistory.sort(function (a, b) {
              return (
                moment(b.TransDate).valueOf() - moment(a.TransDate).valueOf()
              );
            });
          }

          if (
            this.data.purchaseHistory.length > 0 &&
            newItems.length < action.data.pageSize
          ) {
            this.data.purchaseHistory[
              this.data.purchaseHistory.length - 1
            ].lastItem = true;
          }

          this.emitHistoryChanged();
        }

        if (
          action.actionType === AccountConstants.LOAD_GIFT_FEED_SUCCESS_LOCATION
        ) {
          if (action.data.response && action.data.response.items.length > 0) {
            const newItems: GiftItemType[] = action.data.response.items.map(
              (item) => ({
                snackId: item.snackId,
                locationId: item.locationId,
                amount: item.amount,
                description: item.description,
                email: item.email,
                isPrivate: item.isPrivate,
                stickerId: item.stickerId,
                senderAccountId: item.senderAccountId,
                senderName: item.senderName,
                receiverAccountId: item.receiverAccountId,
                receiverName: item.receiverName,
                status: item.status,
                snackSent: item.snackSent,
                lastUpdated: item.lastUpdated,
              }),
            );

            if (action.data.offset > 0) {
              this.data.gifts = [...this.data.gifts, ...newItems];
            } else {
              this.data.gifts = newItems;
            }

            if (
              this.data.gifts.length > 0 &&
              newItems.length < action.data.limit
            ) {
              this.data.gifts[this.data.gifts.length - 1].lastItem = true;
            }
          } else {
            this.data.gifts = [];
          }

          this.emitGiftFeedChanged();
          this.emitChange();
        }

        if (action.actionType === AccountConstants.LOGOUT) {
          this.logout();
          clearCache();
          this.emitChange();
        }

        if (action.actionType === AccountConstants.LOGIN_SUCCESS) {
          const accountId = action.data.response.AccountId;
          const {rememberUsername} = action.data;
          const authorizedDevices = action.data.response.AuthorizedDevices;
          const qrValue = action.data.response.QRValue;
          const {username} = action.data;
          const consumerSnackEnabled = action.data.response.issendasnackenabled;
          const storage = await PersistentStore.getUserStartupData(
            this.data.username,
          );
          this.data.pinRequired = storage.pinRequired;
          this.data.visitedEatItOrDeleteItWelcomeScreen =
            storage.visitedEatItOrDeleteItWelcomeScreen || false;
          this.data.bluetoothChecked = storage.bluetoothChecked || false;

          if (accountId && accountId !== '-1') {
            this.data.loggedIn = true;
            this.data.accountId = accountId;
            this.data.username = username;
            this.data.rememberUsername = rememberUsername;
            this.data.authorizedDevices = authorizedDevices;
            this.data.showPayroll = action.data.showPayroll;
            this.data.payrollAutoComplete = action.data.payrollAutoComplete;
            this.data.showEatItOrDeleteIt = action.data.showEatItOrDeleteIt;
            this.data.eatItUrl = action.data.eatItUrl;
            this.data.moblicoUrl = action.data.moblicoUrl;
            this.data.moblicoApiKey = action.data.moblicoApiKey;
            this.data.consumerEngagementEnabled =
              action.data.consumerEngagementEnabled;
            this.data.locationUserKey = action.data.locationUserKey;
            this.data.orgUserKey = action.data.orgUserKey;
            this.data.fundingOptions = this.parseFundingOptions(
              action.data.fundingOptions,
            );
            this.data.isConsumerSendSnackEnabled = consumerSnackEnabled;
            this.data.qrValue = qrValue;
            this.data.region = 'USA';
            this.data.locale = 'en-US';
            this.data.displayCurrency = '$';
            this.data.currency = 'USD';
            PersistentStore.setAccountDetails(this.data);
          } else {
            this.data.loggedIn = false;
          }

          this.emitChange();
        }

        if (action.actionType === AccountConstants.LOGIN_GMA_SUCCESS) {
          this.data.rememberUsername = action.data.rememberUsername;
          this.processAccountUpdate(action.data.response, true);
        }

        if (action.actionType === AccountConstants.ACCOUNT_INFO_LOADED) {
          this.data.loadAccountInfoError = false;
          this.data.accountInfo = action.data.response;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.ACCOUNT_INFO_UPDATED) {
          this.data.locale = action.data.info.locale;

          if (this.data.accountInfo) {
            this.data.accountInfo.gender = action.data.info.gender;
            this.data.accountInfo.birthMonth = action.data.info.birthMonth;
            this.data.accountInfo.birthYear = action.data.info.birthYear;
            this.data.accountInfo.FirstName = action.data.info.firstName;
            this.data.accountInfo.LastName = action.data.info.lastName;
            this.data.accountInfo.jobTitle = action.data.info.jobTitle;
            PersistentStore.setAccountInfo(this.data.accountInfo);
            this.emitChange();
          }
        }

        if (
          action.actionType ===
          AccountConstants.ACCOUNT_SEND_SNACK_STATUS_UPDATED
        ) {
          this.data.isConsumerSendSnackEnabled = action.data.snackStatus
            ? 'Y'
            : 'N';
          PersistentStore.setConsumerSendSnackStatus(
            this.data.isConsumerSendSnackEnabled,
          );
          this.emitChange();
        }

        if (action.actionType === AccountConstants.ACCOUNT_INFO_ERROR) {
          this.data.loadAccountInfoError = true;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.GET_BALANCE_SUCCESS) {
          this.processAccountUpdate(action.data);
        }

        if (
          action.actionType === AccountConstants.CONSUMER_ENGAGEMENT_REGISTER
        ) {
          this.data.consumerEngagementId = action.data;
        }

        if (action.actionType === AccountConstants.LOCATION_UPDATED) {
          this.data.locationId = action.data.locationId;
          this.data.locationName = action.data.name;
          this.data.locationUserKey = action.data.userkey;
          this.data.locationType = action.data.type;
          this.data.locationBroadcastId = action.data.beaconId;
          this.data.orgUserKey = action.data.orgUserKey;
          const locationFeatures = new LocationFeatures(
            action.data.locationFeatures,
          );

          if (locationFeatures.HasSendSnack) {
            this.data.isSendSnackEnabledForLocation = true;
          }
          if (locationFeatures.LockerType) {
            this.data.locationLockerType = locationFeatures.LockerType;
          }
          PersistentStore.setLocationUserKey(this.data.locationUserKey);
          PersistentStore.setOrgUserKey(this.data.orgUserKey);
          PersistentStore.setLocationId(this.data.locationId);
          PersistentStore.setLocationName(this.data.locationName);
          PersistentStore.setLocationType(this.data.locationType);
          PersistentStore.setLocationBroadcastId(this.data.locationBroadcastId);
          this.emitChange();
        }

        if (action.actionType === AccountConstants.SELECT_SHOP) {
          this.data.pickupInstruction =
            action.data.onlineOrderConfig.pickupInstruction;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.ENVIRONMENT_CHANGED) {
          if (this.data.environments) {
            this.data.urls = this.data.environments[action.data.env];
            this.logUrlsChangedEvent(this.data.urls);
            this.data.env = action.data.env;
            PersistentStore.setUrlsAndEnv(this.data);
          }
        }

        if (action.actionType === AccountConstants.ENVIRONMENTS_FETCHED) {
          if (action.data.response.urls) {
            this.data.environments = action.data.response.urls;
          }
        }

        if (action.actionType === AccountConstants.DEBUG_CHANGED) {
          this.data.isDebug = action.data.isDebug;
          PersistentStore.setIsDebug(this.data.isDebug);
        }

        if (action.actionType === AccountConstants.DEMO_CHANGED) {
          this.data.demo = action.data.demo;
          PersistentStore.setDemo(this.data.demo);
        }

        if (
          action.actionType === AccountConstants.LOAD_SALE_DETAIL ||
          action.actionType === AccountConstants.LOAD_FUNDING_DETAIL
        ) {
          if (action.data.response.Status === 'ok') {
            this.data.transactionDetail = action.data.response.Data;
            this.emitChange();
          }
        }

        if (action.actionType === TransactionConstants.CHECKOUT) {
          if (action.data.response.Status === 'OK') {
            const total = action.data.response.Total * -1;
            const deposit = action.data.response.Deposit * 1;
            const points = action.data.response.Points * 1;
            const {balanceType} = action.data;
            if (balanceType === BalanceTypes.Default) {
              this.data.accountBalance -= action.data.accountAmount;
            }
            this.logBalanceChangedEvent('CHECKOUT', this.data.accountBalance);
            this.data.purchaseHistory.unshift({
              Balance: this.data.accountBalance,
              Amount: total,
              Location: action.data.locationName,
              Id: action.data.response.Transid,
              Type: AccountConstants.SALE_TYPE,
              past90Days: false,
              DateLong: Math.round(new Date().getTime() / 1000),
              Source: '',
              TransDate: action.data.transDate,
              reason: '',
              referenceId: '',
              balanceType: balanceType,
              Points: points,
              TransactionTypeDisplay: null,
              BalanceTypeDisplay: Localized.Labels.pending_upper_case,
            });

            this.data.transactionDetail = {
              Id: action.data.response.Transid,
              Amount: total,
              DateString: action.data.transDate,
              Deposit: deposit,
              Points: points,
              Location: action.data.locationName,
              Payments: action.data.response.Payments.map((payment) => ({
                Type: payment.Type,
                Amount: payment.Amount * 1,
              })),
              Items: action.data.response.Items.map((item) => ({
                Name: item.Description,
                Price: item.Price * 1,
              })),
              Tax: action.data.response.Taxes.map(
                (t) => t.TaxAmount * 1,
              ).reduce((prev, cur) => prev + cur, 0),
              Tax1Label: this.getTaxLabel(action.data.response.Taxes, 0),
              Tax1: this.getTax(action.data.response.Taxes, 0),
              Tax2Label: this.getTaxLabel(action.data.response.Taxes, 1),
              Tax2: this.getTax(action.data.response.Taxes, 1),
              Tax3Label: this.getTaxLabel(action.data.response.Taxes, 2),
              Tax3: this.getTax(action.data.response.Taxes, 2),
            };
            this.emitBalanceChanged();
            this.emitChange();
          }
        }

        if (action.actionType === AccountConstants.BLUETOOTH_CHECKED) {
          this.data.bluetoothChecked = action.data.bluetoothChecked;
          PersistentStore.setBluetoothChecked(
            this.data.username,
            this.data.bluetoothChecked,
          );
        }

        if (action.actionType === AccountConstants.MESSAGE_UPDATE) {
          this.emitChange();
        }

        if (action.actionType === AccountConstants.DELETE_MESSAGE) {
          const index = demoMessages.findIndex((message) =>
            this.messagesMatch(message, action.data.message),
          );

          if (index >= 0) {
            demoMessages.splice(index, 1);
            this.emitChange();
          }
        }

        if (action.actionType === AccountConstants.MESSAGE_READ) {
          const index = demoMessages.findIndex((message) =>
            this.messagesMatch(message, action.data.message),
          );

          if (index >= 0) {
            demoMessages[index].read = true;
            this.emitChange();
          }
        }

        if (action.actionType === AccountConstants.PROMOTIONS_LOADED) {
          this.data.promotionsResponse = action.data.response;
          PersistentStore.setPromotionsResponse(this.data.promotionsResponse);
          this.emitChange();
        }

        if (action.actionType === AccountConstants.MOBLICO_PROMOS_LOADED) {
          this.data.moblicoPromosResponse = action.data.response;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.THRESHOLDS_LOADED) {
          if (action.data.response.status === 'ok') {
            action.data.response.data.sort((a, b) => a.points - b.points);
          }

          this.data.thresholdsResponse = action.data.response;
          PersistentStore.setThresholdsResponse(this.data.thresholdsResponse);
          this.emitChange();
        }

        if (action.actionType === AccountConstants.AUTO_FUND_LOADED) {
          this.data.autoFundResponse = action.data.response;
          PersistentStore.setAutoFundResponse(this.data.autoFundResponse);
          this.emitChange();
        }

        if (action.actionType === AccountConstants.PRIVACY_VERSION_LOADED) {
          this.data.privacyVersionResponse = action.data.response;
          PersistentStore.setPrivacyVersionResponse(
            this.data.privacyVersionResponse,
          );
          this.emitChange();
        }

        if (action.actionType === AccountConstants.LINKED_LOCATIONS_FETCHED) {
          if (action.data.response) {
            this.data.linkedLocations = action.data.response.map(
              (location) => ({
                localType: 'market',
                ...location,
                datecreated: location.dateCreated,
                id: location.locationId,
                deviceId: location.locationId,
                org: location.orgId,
                broadcastid: location.broadcastId,
                locationType: 'sos',
                orderAhead: true,
              }),
            );
            this.emitChange();
          }
        }

        if (action.actionType === AccountConstants.NEARBY_LOCATIONS_FETCHED) {
          if (action.data.response.items) {
            this.data.nearbyLocations = action.data.response.items.map(
              (location) => ({
                localType: 'market',
                ...location,
                id: location.locationId,
                deviceId: location.locationId,
                org: location.orgId,
                locationType: location.type,
                orderAhead: true,
                broadcastid: location.beaconId,
                isNearby: true,
              }),
            );
            this.emitChange();
          }
        }

        if (action.actionType === AccountConstants.LOCATION_LINK_ADDED) {
          if (
            this.data.linkedLocations.every(({id}) => id !== action.data.id)
          ) {
            this.data.linkedLocations.push({
              localType: 'market',
              ...action.data,
              deviceId: action.data.id,
              locationType: 'sos',
              orderAhead: true,
            });
            this.emitChange();
          }
        }

        if (action.actionType === AccountConstants.LOCATION_LINK_REMOVED) {
          this.data.linkedLocations = this.data.linkedLocations.filter(
            (location) => location.deviceId !== action.data.deviceId,
          );
          this.emitChange();
        }

        if (action.actionType === AccountConstants.MARKET_CARD_UPDATED) {
          this.data.marketCard = action.data.marketCard;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.REFERRAL_DETAILS_LOADED) {
          this.data.referralCampaign = action.data.referralCampaign;
          this.data.referralStat = action.data.referralStat;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.RECENT_ORDERS_LOADED) {
          this.data.orders = action.data.orders;
          this.emitChange();
        }

        if (action.actionType === AccountConstants.REMOVE_RECENT_ORDER) {
          this.data.orders = this.data.orders?.filter(
            (o, i) => i !== action.data.index,
          );
          this.emitChange();
        }
      },
    );
  }

  parseFundingOptions(value: string): Array<number> {
    if (value && value.length > 0) {
      return value
        .split(',')
        .map((sepVal) => Number(sepVal))
        .filter((numVal) => numVal >= AccountConstants.MINIMUM_FUNDING_AMOUNT)
        .sort((a, b) => b - a);
    }

    return defaultFundingOptions;
  }

  logBalanceChangedEvent(source: string, newBalance: number) {
    BalanceChangedEvent.trackEvent(source, newBalance?.toFixed(2));
  }

  logUrlsChangedEvent(urls: any) {
    UrlsChangedEvent.trackEvent(
      this.getEmail() || '',
      this.getLocationId() || '',
      this.getLocationName() || '',
      store.getState().environment.env,
      Settings.buildType,
      urls,
    );
  }

  messagesMatch(message1: MessageType, message2: MessageType) {
    return (
      message1.date === message2.date &&
      message1.from === message2.from &&
      message1.message === message2.message
    );
  }

  getTax(taxes: Array<any>, index: number) {
    if (taxes && taxes.length > index) {
      return taxes[index].TaxAmount * 1;
    }

    return 0;
  }

  getTaxLabel(taxes: Array<any>, index: number) {
    if (taxes && taxes.length > index) {
      return taxes[index].Description;
    }

    return '';
  }

  adjustBalance(amount: number, reason: string) {
    this.data.accountBalance += amount;
    this.logBalanceChangedEvent(reason, this.data.accountBalance);
    this.emitBalanceChanged();
  }

  getRunningBalance(page: number) {
    if (page === 1) {
      return this.getAccountBalance();
    }

    const lastItem =
      this.data.purchaseHistory[this.data.purchaseHistory.length - 1];
    return lastItem.Balance + this.getBalanceAdjustment(lastItem);
  }

  getBalanceAdjustment(item: any): number {
    return item.amount * -1;
  }

  calculateHistoryBalance(items: any[], page: number) {
    const ninetyDaysAgo = moment().subtract({
      days: 90,
    });
    let runningBalance = this.getRunningBalance(page);

    for (let i = 0; i < items.length; i++) {
      const purchaseItem = items[i];
      purchaseItem.Balance = runningBalance;
      purchaseItem.past90Days = moment(purchaseItem.transDate).isBefore(
        ninetyDaysAgo,
      );
      runningBalance += this.getBalanceAdjustment(purchaseItem);
    }
  }

  async logout() {
    this.data.loggedIn = false;
    this.data.accountId = '-1';
    this.data.accountBalanceId = '';
    this.data.qrValue = '';
    this.data.pinRequired = false;
    this.data.accountInfo = {};
    this.data.accountBalance = 0;
    this.logBalanceChangedEvent('LOGOUT', this.data.accountBalance);
    this.data.accountRewards = 0;
    this.data.showPayroll = false;
    this.data.payrollAutoComplete = false;
    this.data.showEatItOrDeleteIt = false;
    this.data.eatItUrl = '';
    this.data.moblicoUrl = '';
    this.data.moblicoApiKey = '';
    this.data.consumerEngagementEnabled = false;
    this.data.locationUserKey = '';
    this.data.orgUserKey = '';
    this.data.taxId = '';
    this.data.region = '';
    this.data.city = '';
    this.data.zip = '';
    this.data.locale = '';
    this.data.displayCurrency = '';
    this.data.currency = '';
    this.data.org = '';
    this.data.locationId = '';
    this.data.locationName = '';
    this.data.orgName = '';
    this.data.consumerEngagementId = '';
    this.data.locationType = '';
    this.data.locationBroadcastId = '';
    this.data.fundingOptions = defaultFundingOptions;
    this.data.dateAccountUpdated = '';
    this.data.promotionsResponse = null;
    this.data.moblicoPromosResponse = [];
    this.data.thresholdsResponse = null;
    this.data.autoFundResponse = null;
    this.data.privacyVersionResponse = null;
    this.data.purchaseHistory = [];
    this.data.authorizedDevices = [];
    this.data.linkedLocations = [];
    this.data.nearbyLocations = [];
    this.data.isConsumerSendSnackEnabled = 'N';
    this.data.isSendSnackEnabledForLocation = false;
    this.data.locationLockerType = LockerTypes.None;
    this.data.gifts = [];
    this.data.consumerIdentifier = null;
    await PersistentStore.setAccountDetails(this.data);
    PersistentStore.setGmaAccountDetails(this.data);
    PersistentStore.setDemo(false);
    PersistentStore.setPromotionsResponse(this.data.promotionsResponse);
    PersistentStore.setAutoFundResponse(this.data.autoFundResponse);
    PersistentStore.setThresholdsResponse(this.data.thresholdsResponse);
    PersistentStore.setPrivacyVersionResponse(this.data.privacyVersionResponse);
    PersistentStore.removeSentExternalId();
    OneSignal.removeExternalUserId();
    this.clearAccountInfo();
  }

  // replace with new response gma v2 account fetch
  async processAccountUpdate(response: any, login = false) {
    if (response && response?.accountId) {
      let accountBalance;
      if (response?.balances && response?.balances.length > 0) {
        accountBalance = response?.balances.find(
          (i) => i.type === BalanceTypes.Default,
        );
        if (!accountBalance) {
          accountBalance = response?.balances.find(
            (i) => i.type === BalanceTypes.Stripe,
          );
        }
      }
      const balanceChanged =
        this.data.accountBalance !== accountBalance?.balance;
      const pointsChanged = this.data.accountRewards !== response.points;
      let qrCode = '';

      if (response.identifiers && response.identifiers.length > 0) {
        const qrCodeMkii = response.identifiers.find(
          (mkii) => mkii.type === MarketAccountIdentifierTypes.QrCode,
        );

        if (qrCodeMkii) {
          qrCode = qrCodeMkii.value;
        }
      }

      this.data.loggedIn = true;

      if (!login) {
        this.data.dateAccountUpdated = moment().format();
      }

      if (login) {
        this.data.accountId = response.accountId;
      }

      this.data.accountBalanceId = accountBalance?.accountBalanceId;
      this.data.username = response.email;
      this.data.org = response.orgId;
      this.data.locationId = response.locationId;
      this.data.locationName = response.name;
      this.data.locationType = response.type;
      this.data.orgName = response.orgName;
      this.data.consumerEngagementId = response.consumerEngagementId;
      this.data.locationBroadcastId = response.beaconId;
      this.data.locationFeedback = response.onlineOrderConfig?.feedback;
      this.data.authorizedDevices = [];
      this.data.showPayroll = response.locationFeatures?.showPayroll;
      this.data.payrollAutoComplete =
        response.locationFeatures?.payrollAutoComplete;
      this.data.showEatItOrDeleteIt =
        response.locationFeatures?.showEatItOrDeleteIt;
      this.data.eatItUrl = response.locationFeatures?.eatItOrDeleteIdUrl;
      this.data.moblicoUrl = response.moblicoUrl;
      this.data.moblicoApiKey = response.moblicoApiKey;
      this.data.consumerEngagementEnabled =
        response.locationFeatures?.consumerEngagementEnabled;
      this.data.locationUserKey = response.userKey;
      this.data.orgUserKey = response?.orgUserKey;
      this.data.accountInfo = {};
      this.data.accountBalance = accountBalance?.balance;
      this.data.accountRewards = response.points;
      this.data.accountInfo.Email = response.email;
      this.data.accountInfo.FirstName = response.firstName;
      this.data.accountInfo.LastName = response.lastName;
      this.data.accountInfo.gender = response.demographics.gender;
      this.data.accountInfo.jobTitle = response.demographics.jobTitle;
      this.data.accountInfo.birthMonth = response.demographics.birthMonth;
      this.data.accountInfo.birthYear = response.demographics.birthYear;
      this.data.fundingOptions = this.parseFundingOptions(
        response.locationFeatures?.fundingOptions,
      );
      this.data.isConsumerSendSnackEnabled = response.sendaSnackEnabled
        ? 'Y'
        : 'N';
      // Need to log this after the email has been set.
      this.logBalanceChangedEvent('SERVER', this.data.accountBalance);
      const storage = await PersistentStore.getUserStartupData(
        this.data.username,
      );
      this.data.pinRequired = storage.pinRequired;
      this.data.visitedEatItOrDeleteItWelcomeScreen =
        storage.visitedEatItOrDeleteItWelcomeScreen || false;
      this.data.bluetoothChecked = storage.bluetoothChecked || false;
      this.data.qrValue = qrCode;
      this.data.marketCard = response.identifiers?.find(
        (mkii) => mkii.type === MarketAccountIdentifierTypes.ScanCode,
      )?.value;
      this.data.consumerIdentifier = response.identifiers?.find(
        (identifier) =>
          identifier.type === MarketAccountIdentifierTypes.ConsumerRole,
      );
      this.data.currency = response.localization.currency || 'USD';
      this.data.displayCurrency =
        currencySymbols[
          response.localization.currency as keyof typeof currencySymbols
        ] || '$';
      this.data.region = response.localization.region || 'USA';
      this.data.city = response.city || this.data.city;
      this.data.zip = response.zip || this.data.zip;
      this.data.locale = response.localization.locale || 'en-US';
      this.data.taxId = response.taxid ? response.taxid : this.data.taxId;
      PersistentStore.setAccountDetails(this.data);
      PersistentStore.setGmaAccountDetails(this.data);

      if (balanceChanged || pointsChanged) {
        this.emitBalanceChanged();
      }
    } else if (login) {
      this.data.loggedIn = false;
    }

    this.emitChange();
  }

  // events for store data changes
  emitChange(changeType?: string) {
    this._emitter.emit('change', changeType);
  }

  addHistoryChangedListener(callback: () => any) {
    this._emitter.on('historyChange', callback);
  }

  removeHistoryChangedListener(callback: () => any) {
    this._emitter.removeListener('historyChange', callback);
  }

  emitHistoryChanged() {
    this._emitter.emit('historyChange');
  }

  addGiftFeedChangedListener(callback: () => any) {
    this._emitter.on('giftFeedChange', callback);
  }

  removeGiftFeedChangedListener(callback: () => any) {
    this._emitter.removeListener('giftFeedChange', callback);
  }

  emitGiftFeedChanged() {
    this._emitter.emit('giftFeedChange');
  }

  addChangeListener(callback: () => void) {
    return this._emitter.on('change', callback);
  }

  removeChangeListener(callback: () => void) {
    this._emitter.removeListener('change', callback);
  }

  emitCreditCardAdded() {
    this._emitter.emit('creditCardAdded');
  }

  addCreditCardAddedListener(callback: () => void) {
    this._emitter.on('creditCardAdded', callback);
  }

  removeCreditCardAddedListener(callback: () => void) {
    this._emitter.removeListener('creditCardAdded', callback);
  }

  emitCreditCardDeleted(isDefault?: boolean) {
    this._emitter.emit('creditCardDeleted', {isDefault});
  }

  addCreditCardDeletedListener(callback: () => void) {
    this._emitter.on('creditCardDeleted', callback);
  }

  removeCreditCardDeletedListener(callback: () => void) {
    this._emitter.removeListener('creditCardDeleted', callback);
  }

  emitDefaultTokenUpdated() {
    this._emitter.emit('defaultTokenUpdated');
  }

  addDefaultTokenUpdatedListener(callback: () => void) {
    this._emitter.on('defaultTokenUpdated', callback);
  }

  removeDefaultTokenUpdatedListener(callback: () => void) {
    this._emitter.removeListener('defaultTokenUpdated', callback);
  }

  emitBalanceChanged() {
    this._emitter.emit('balanceChanged');
  }

  addBalanceChangedListener(callback: () => void) {
    this._emitter.on('balanceChanged', callback);
  }

  removeBalanceChangedListener(callback: () => void) {
    this._emitter.removeListener('balanceChanged', callback);
  }

  isAutoFundingOn(): boolean {
    return this.data.autoFundResponse?.isDisabled === 'N';
  }

  getAutoFundFallBelowAmount(): number {
    if (this.isAutoFundingOn()) {
      return this.data.autoFundResponse?.balance || 0;
    }

    return 0;
  }

  getAutoFundAmount(): number {
    if (this.isAutoFundingOn()) {
      return this.data.autoFundResponse?.amount || 0;
    }

    return 0;
  }

  getAutoFundCardToken(): string {
    if (this.isAutoFundingOn()) {
      return this.data.autoFundResponse?.marketaccounttoken || '';
    }

    return '';
  }

  getPromotionsResponse(): AdmPromotionResponse | null {
    return this.data.promotionsResponse;
  }

  getMoblicoPromosResponse() {
    return this.data.moblicoPromosResponse;
  }

  getThresholdsResponse(): ThresholdsResponse | null {
    return this.data.thresholdsResponse;
  }

  getAutoFundResponse() {
    return this.data.autoFundResponse;
  }

  getPrivacyVersionResponse(): PrivacyVersionResponse | null {
    return this.data.privacyVersionResponse;
  }

  getDisplayDateAccountUpdated(): string {
    return moment(this.data.dateAccountUpdated).format('L LT');
  }

  isLoggedIn() {
    return this.data.loggedIn;
  }

  getPurchaseHistory(typeFilter: string, recordsToShow?: number): Array<any> {
    let records;
    if (typeFilter) {
      records = this.data.purchaseHistory
        .filter(
          (item: any) =>
            item.Type === typeFilter ||
            (typeFilter === AccountConstants.FUNDING_TYPE &&
              item.Type === 'addvalue') ||
            (typeFilter === AccountConstants.FUNDING_TYPE &&
              item.Type === AccountConstants.REFUND_TYPE) ||
            (typeFilter === AccountConstants.FUNDING_TYPE &&
              item.Type === AccountConstants.REDEMPTION_TYPE),
        )
        .sort((a: any, b: any) => a.DateLong - b.DateLong);
    } else {
      records = this.data.purchaseHistory;
    }
    if (recordsToShow && records != null) {
      return records.slice(0, recordsToShow);
    }

    return records;
  }

  getPurchaseHistoryItem(transactionId: string) {
    if (this.isDemo()) {
      return this.data.purchaseHistory[0];
    }

    return this.data.purchaseHistory.find((item) => item.Id === transactionId);
  }

  clearPurchaseHistory() {
    this.data.purchaseHistory = [];
  }

  // getGiftFeedList(recordsToShow?: number): Array<GiftItemType> {
  //   let gifts;
  //   gifts = this.data.gifts;
  //
  //   if (recordsToShow && gifts != null) {
  //     return gifts.slice(0, recordsToShow);
  //   }
  //
  //   return gifts;
  // }

  getSetupError() {
    return this.data.setupError;
  }

  getSetupErrorMessage() {
    return this.data.setupErrorMessage;
  }

  getVerifyError() {
    return this.data.verifyError;
  }

  getLoginError() {
    return this.data.loginError;
  }

  getLoginErrorMessage() {
    return this.data.loginErrorMessage;
  }

  getVerifySuccess() {
    return this.data.verifySuccess;
  }

  getCreditCardError() {
    return this.data.creditCardError;
  }

  getPinRequired(): boolean {
    return this.data.pinRequired;
  }

  hasVisitedEatItOrDeleteItWelcomeScreen() {
    return this.data.visitedEatItOrDeleteItWelcomeScreen;
  }

  getAccountBalance() {
    return this.data.accountBalance;
  }

  getAccountRewards() {
    return this.data.accountRewards;
  }

  getGender() {
    return this.data.accountInfo?.gender || '';
  }

  getJobTitle() {
    return this.data.accountInfo?.jobTitle || '';
  }

  getBirthMonth() {
    return _.padStart(this.data.accountInfo?.birthMonth, 2, '0');
  }

  getBirthYear() {
    return this.data.accountInfo?.birthYear || '';
  }

  getFirstName() {
    return this.data.accountInfo?.FirstName || '';
  }

  getLastName() {
    return this.data.accountInfo?.LastName || '';
  }

  getFullName() {
    let firstName = this.data.accountInfo?.FirstName || '';
    let lastName = this.data.accountInfo?.LastName || '';

    if (firstName === 'null') {
      firstName = '';
    }

    if (lastName === 'null') {
      lastName = '';
    }

    return `${firstName} ${lastName}`;
  }

  getEmail() {
    return this.data.accountInfo?.Email || '';
  }

  getDemoMessages() {
    return demoMessages;
  }

  clearAccountInfo() {
    this.data.accountInfo = {
      Email: '',
      FirstName: '',
      LastName: '',
      gender: '',
      birthMonth: '',
      birthYear: '',
      jobTitle: '',
    };
  }

  getRememberUsername() {
    return this.data.rememberUsername;
  }

  getUsername() {
    return this.data.username;
  }

  getOrg() {
    return this.data.org;
  }

  getLocationId() {
    return this.data.locationId;
  }

  getLocationName() {
    return this.data.locationName;
  }

  getOrgName() {
    return this.data.orgName;
  }

  getConsumerEngagementId() {
    return this.data.consumerEngagementId;
  }

  getLocationType() {
    return this.data.locationType;
  }

  getLocationBroadcastId() {
    return this.data.locationBroadcastId;
  }

  getLocationFeedback(): string {
    return this.data.locationFeedback;
  }

  getAuthorizedDevices() {
    return this.data.authorizedDevices;
  }

  // getEnvironment() {
  //   return this.data.env;
  // }

  addAuthorizedDevice(deviceId: string) {
    const index = this.data.authorizedDevices.indexOf(deviceId);

    if (index < 0) {
      this.data.authorizedDevices.push(deviceId);
      PersistentStore.setAuthorizedDevices(this.data.authorizedDevices);
    }
  }

  removeAuthorizedDevice(deviceId: string) {
    const index = this.data.authorizedDevices.indexOf(deviceId);

    if (index >= 0) {
      this.data.authorizedDevices.splice(index, 1);
      PersistentStore.setAuthorizedDevices(this.data.authorizedDevices);
    }
  }

  // getUrls() {
  //   if (Array.isArray(this.data.urls)) {
  //     this.data.urls = defaultUrls;
  //     this.logUrlsChangedEvent(this.data.urls);
  //     this.data.env = Settings.env;
  //     PersistentStore.setUrlsAndEnv(this.data);
  //   }
  //
  //   return this.data.urls;
  // }

  // getUrl(service: keyof typeof UrlTypes) {
  //   console.log('getUrls',{service,urls:this.data.urls});
  //   let url = this.getUrls()[service];
  //
  //   if (!url) {
  //     url = defaultUrls[service];
  //   }
  //
  //   return url;
  // }

  getPaymentIssuer(
    paymentMethodToken: string,
    creditCards: Array<CreditCard>,
  ): string {
    if (
      paymentMethodToken &&
      paymentMethodToken.toLowerCase() === AccountConstants.PAYROLL_TOKEN
    ) {
      return AccountConstants.PAYROLL_TOKEN;
    }

    if (paymentMethodToken === AccountConstants.APPLE_PAY_TOKEN) {
      return AccountConstants.APPLE_PAY_TOKEN;
    }

    if (paymentMethodToken === AccountConstants.GOOGLE_PAY_TOKEN) {
      return AccountConstants.GOOGLE_PAY_TOKEN;
    }

    if (creditCards && creditCards.length > 0) {
      const card = creditCards.find((c) => c.id === paymentMethodToken);

      if (card) {
        return card.cardIssuer;
      }
    }

    return '';
  }

  getAccountId() {
    return this.data.accountId;
  }

  getAccountBalanceId() {
    return this.data.accountBalanceId;
  }

  setAccountBalanceId(balanceId: string) {
    this.data.accountBalanceId = balanceId;
  }

  getQRValue() {
    return this.data.qrValue;
  }

  getShowPayroll() {
    return this.data.showPayroll;
  }

  getPayrollAutoComplete() {
    return this.data.payrollAutoComplete;
  }

  getShowEatItOrDeleteIt() {
    return this.data.showEatItOrDeleteIt;
  }

  getEatItUrl() {
    return this.data.eatItUrl;
  }

  getMoblicoUrl() {
    return this.data.moblicoUrl;
  }

  getMoblicoApiKey() {
    return this.data.moblicoApiKey;
  }

  isConsumerEngagementEnabled() {
    return this.data.consumerEngagementEnabled;
  }

  getLocationUserKey() {
    return this.data.locationUserKey;
  }

  getOrgUserKey() {
    return this.data.orgUserKey;
  }

  isDemo() {
    return this.data.demo;
  }

  isDebug() {
    return this.data.isDebug;
  }

  isConsumerSendSnackEnabled() {
    return this.data.isConsumerSendSnackEnabled === 'Y';
  }

  isSendSnackEnabledForLocation() {
    return this.data.isSendSnackEnabledForLocation;
  }

  getLocationLockerType() {
    return this.data.locationLockerType;
  }

  getQrCode() {
    return qrCodePrefix + this.getQRValue();
  }

  getTransactionDetail() {
    return this.data.transactionDetail;
  }

  getTaxId() {
    return this.data.taxId;
  }

  getDisplayCurrency() {
    return this.data.displayCurrency;
  }

  getCurrency() {
    return this.data.currency || 'USD';
  }

  getRegion() {
    return this.data.region;
  }

  getCity() {
    return this.data.city;
  }

  getZip() {
    return this.data.zip;
  }

  getLocale() {
    return this.data.locale ?? '';
  }

  waitForData() {
    return this.asyncPromise;
  }

  getBluetoothChecked() {
    return this.data.bluetoothChecked;
  }

  getFundingOptions() {
    return this.data.fundingOptions;
  }

  getLinkedLocations() {
    return this.data.linkedLocations;
  }

  getNearbyLocations() {
    return this.data.nearbyLocations;
  }

  getMarketCard() {
    return this.data.marketCard;
  }

  getConsumerIdentifier() {
    return this.data.consumerIdentifier;
  }

  getDefaultAmount(): number {
    const length = this.data.fundingOptions.length;
    // We want to use the second-lowest amount as the default
    let amount = 15;

    if (length > 1) {
      amount = this.data.fundingOptions[length - 2];
    } else if (length > 0) {
      amount = this.data.fundingOptions[0];
    }

    return amount;
  }

  getPrivacyTermsType(): string {
    const response = this.getPrivacyVersionResponse();

    if (response && response.status === 'ok') {
      return response.type || '';
    }

    return '';
  }

  getReferralStat(): ReferralStat | typeof undefined {
    return this.data.referralStat;
  }

  getReferralCampaign(): ReferralCampaign | typeof undefined {
    return this.data.referralCampaign;
  }

  getRecentOrders(): Array<SaleOrder> | typeof undefined {
    return this.data.orders;
  }

  getPickupInstruction(): string {
    return this.data.pickupInstruction;
  }
}

export default new AccountStore();
