import * as React from 'react';
import FontAwesome5Pro, {FontAwesomeIconType} from '../icons/FontAwesomeIcon';
import Amex from '../img/svg/cards/amex';
import ApplePay from '../img/svg/cards/applePay';
import Discover from '../img/svg/cards/discover';
import GooglePay from '../img/svg/cards/googlePay';
import MasterCard from '../img/svg/cards/mastercard';
import PaperBill from '../img/svg/cards/paperBill';
import Visa from '../img/svg/cards/visa';
import Styles from '../Styles';
import AccountConstants from 'src/constants/AccountConstants';
type CreditCardImageProps = {
  issuer: string;
};

class CreditCardImage extends React.Component<CreditCardImageProps> {
  render() {
    let cardType: FontAwesomeIconType | undefined = undefined;
    const color = Styles.primaryColor;

    if (this.props.issuer) {
      switch (this.props.issuer.toLowerCase()) {
        case 'visa':
          return <Visa size={26} />;

        case 'american-express':
        case 'american express':
        case 'amex':
          return <Amex size={26} />;

        case 'discover':
          return <Discover size={26} />;

        case 'master-card':
        case 'mastercard':
          return <MasterCard size={26} />;

        case AccountConstants.APPLE_PAY_TOKEN:
          return <ApplePay size={26} />;

        case AccountConstants.GOOGLE_PAY_TOKEN:
          return <GooglePay size={26} />;

        case AccountConstants.PAYROLL_TOKEN:
          return <PaperBill size={26} />;

        default:
          cardType = 'credit-card-front';
          break;
      }
    }

    if (cardType) {
      return <FontAwesome5Pro name={cardType} color={color} size={28} />;
    } else {
      return null;
    }
  }
}

export default CreditCardImage;
