import React from 'react';
import {StyleSheet, Platform, ScrollView, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import MainConsumerContext from '../../MainConsumerContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import PassKit from 'src/nativeModules/PassKit';
import Header from '../../elements/Header';
import MenuOption from '../../elements/MenuOption';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Section from '../../elements/Section';
import Styles from '../../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import UIManager from '../../elements/ui/UIManager';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {getPreviousRouteName} from 'src/Util';
import {NavigationProp} from '@react-navigation/native';
import uuid from '../../../nativeModules/UUID';

type AccountProps = Record<string, unknown> & {
  paymentCredentials?: PaymentCredentials;
  navigation: NavigationProp<Account>;
};

type AccountState = {
  fullVersion: string;
  email: string;
  name: string;
  locationName: string;
  feedback: string;
  sendSnackStatus: boolean;
  previousRoute: string;
};

class Account extends React.Component<AccountProps, AccountState> {
  static defaultProps = {
    passExists: false,
    canAddPass: false,
    rewards: 0,
    passUrl: '',
  };
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;
  currentPolicy: string;
  currentPolicyType: string;

  constructor(props: AccountProps) {
    super(props);
    this.state = {
      fullVersion: '',
      previousRoute: '',
      name: AccountStore.getFullName(),
      email: AccountStore.getEmail(),
      locationName: AccountStore.getLocationName(),
      feedback: AccountStore.getLocationFeedback(),
      sendSnackStatus: AccountStore.isConsumerSendSnackEnabled(),
    };
    this.onLogoutSelect = this.onLogoutSelect.bind(this);
    this.toPurchaseHistory = this.toPurchaseHistory.bind(this);
    this.toCards = this.toCards.bind(this);
    this.toBalances = this.toBalances.bind(this);
    this.toManageAccount = this.toManageAccount.bind(this);
    this.contactClicked = this.contactClicked.bind(this);
    this.rewardsClicked = this.rewardsClicked.bind(this);
    this.eatItOrDeleteItClicked = this.eatItOrDeleteItClicked.bind(this);
    this.connectClicked = this.connectClicked.bind(this);
    this.addPassClicked = this.addPassClicked.bind(this);
    this.handlePrivacyPolicy = this.handlePrivacyPolicy.bind(this);
    this.handleTerms = this.handleTerms.bind(this);
    this.handleChangePin = this.handleChangePin.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.feedbackClicked = this.feedbackClicked.bind(this);
    this.blockedUsersClicked = this.blockedUsersClicked.bind(this);
    this.onSendSnackStatusChange = this.onSendSnackStatusChange.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
  }

  async componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'AccountScreen', {
      ...this.props,
      ...this.state,
    });
    try {
      const previousRoute = getPreviousRouteName(
        this.props.navigation.getState().routes,
      );

      const fullVersion = await Settings.getFullDisplayVersion(Platform.OS);
      this.setState({
        fullVersion,
        previousRoute,
      });
      const response = AccountStore.getPrivacyVersionResponse();

      if (response && response.status === 'ok') {
        this.currentPolicy = response.version;
        this.currentPolicyType = response.type;
      }

      AccountStore.addChangeListener(this.onAccountChange);
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:componentDidMount',
        error.message ? error.message : error.toString(),
        guid,
      );
    }
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountChange);
  }

  onAccountChange() {
    try {
      const response = AccountStore.getPrivacyVersionResponse();

      if (response && response.status === 'ok') {
        this.currentPolicy = response.version;
        this.currentPolicyType = response.type;
      }

      this.setState({
        name: AccountStore.getFullName(),
        email: AccountStore.getEmail(),
        locationName: AccountStore.getLocationName(),
        sendSnackStatus: AccountStore.isConsumerSendSnackEnabled(),
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:onAccountChange',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async onLogoutSelect() {
    this.context.actions.showSpinner();
    Events.Logout.trackEvent();
    await ActionsFactory.getAccountActions().logout();
    this.context.actions.hideSpinner();
    this.context.actions.changeRootRoute(AppRoutes.Login);
  }

  toPurchaseHistory() {
    NavActions.push(AppRoutes.PurchaseHistory);
  }

  toBalances() {
    NavActions.push(AppRoutes.Balances);
  }

  toCards() {
    NavActions.push(AppRoutes.Cards);
  }

  toManageAccount() {
    NavActions.push(AppRoutes.ManageAccount);
  }

  handlePrivacyPolicy() {
    try {
      const url = Settings.getPrivacyPolicyUrl(
        Settings.getLocale(),
        this.currentPolicy,
        this.currentPolicyType,
      );
      const backupUrl = Settings.getPrivacyPolicyUrl(
        Settings.getDefaultLocale(),
        this.currentPolicy,
        this.currentPolicyType,
      );
      NavActions.push(AppRoutes.WebContent, {
        title: Localized.Labels.privacy_policy,
        url,
        backupUrl,
        strings: Localized,
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:handlePrivacyPolicy',
        error.message ? error.message : error.toString(),
      );
    }
  }

  handleTerms() {
    try {
      const url = Settings.getTermsUrl(
        Settings.getLocale(),
        AccountStore.getPrivacyTermsType(),
      );
      const backupUrl = Settings.getTermsUrl(
        Settings.getDefaultLocale(),
        AccountStore.getPrivacyTermsType(),
      );
      NavActions.push(AppRoutes.WebContent, {
        title: Localized.Labels.terms,
        url,
        backupUrl,
        strings: Localized,
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:handleTerms',
        error.message ? error.message : error.toString(),
      );
    }
  }

  handleChangePin() {
    NavActions.push(AppRoutes.ResetPin);
  }

  handleChangePassword() {
    NavActions.push(AppRoutes.ChangePassword);
  }

  async contactClicked() {
    NavActions.push(AppRoutes.Help, {
      strings: Localized,
    });
  }

  rewardsClicked() {
    NavActions.push(AppRoutes.Rewards);
  }

  handleMarketCard() {
    NavActions.push(AppRoutes.EditMarketCard);
  }

  eatItOrDeleteItClicked() {
    try {
      const location = AccountStore.getLocationId();
      const alreadyVisitedWelcomeScreen =
        AccountStore.hasVisitedEatItOrDeleteItWelcomeScreen();

      if (!location || !alreadyVisitedWelcomeScreen) {
        NavActions.push(AppRoutes.EatItOrDeleteItWelcome);
      } else {
        NavActions.push(AppRoutes.ProductFeedbackInstruction, {
          eatItUrl: AccountStore.getEatItUrl(),
        });
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:eatItOrDeleteItClicked',
        error.message ? error.message : error.toString(),
      );
    }
  }

  connectClicked() {
    NavActions.push(AppRoutes.Connect);
  }

  feedbackClicked() {
    NavActions.push(AppRoutes.Feedback, {
      feedback: this.state.feedback,
    });
  }

  handleDeleteAccount() {
    NavActions.push(AppRoutes.DeleteAccount);
  }

  async addPassClicked() {
    this.context.actions.showSpinner();

    try {
      const success = await PassKit.addPass(this.context.state.passUrl);

      FirebaseAnalytic.trackEvent('addPassClicked', 'AccountScreen', {
        ...this.props,
        ...this.state,
        success,
      });

      if (success) {
        const passExists = await PassKit.doesPassExist(
          this.context.state.passUrl,
        );

        if (passExists) {
          if (this.context.actions.passAdded) {
            this.context.actions.passAdded();
          }

          Events.Pass.trackEvent();
        }
      } else {
        alertError(Localized.Errors.failed_to_add_pass);
      }
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  blockedUsersClicked() {
    NavActions.push(AppRoutes.SnackBlockedUsers);
  }

  async onSendSnackStatusChange() {
    this.context.actions.showSpinner();

    try {
      const response =
        await ActionsFactory.getAccountActions().updateSendSnackStatus(
          AccountStore.getAccountId(),
          !this.state.sendSnackStatus,
        );

      if (response) {
        this.setState({
          sendSnackStatus: response.sendaSnackEnabled,
        });
      } else {
        alertError(Localized.Errors.error_saving_user_send_snack_status);
      }
    } catch (error) {
      alertError(Localized.Errors.error_saving_user_send_snack_status);
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:onSendSnackStatusChange',
        error.message ? error.message : error.toString(),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  render() {
    let paymentMethods;
    if (this.props.paymentCredentials?.paymentAvailable) {
      paymentMethods = (
        <MenuOption
          accessible={true}
          accessibilityLabel="Manage Cards"
          aria-label="Manage Cards"
          accessibilityHint="Double tap to navigate to the payment screen"
          onPress={this.toCards}
          label={Localized.Labels.payment}
          testID={Localized.Labels.payment}
        />
      );
    }

    let rewardOption;
    if (this.context.state.rewards) {
      rewardOption = (
        <MenuOption
          accessible={true}
          accessibilityLabel={Localized.Labels.rewards}
          accessibilityHint="Double tap to view available rewards"
          onPress={this.rewardsClicked}
          label={Localized.Labels.rewards}
        />
      );
    }

    let editMarketCard;
    if (AccountStore.getMarketCard()) {
      editMarketCard = (
        <MenuOption
          accessible={true}
          accessibilityLabel={Localized.Labels.edit_market_card}
          accessibilityHint="Double tap to edit your market card"
          onPress={this.handleMarketCard}
          label={Localized.Labels.edit_market_card}
        />
      );
    } else {
      editMarketCard = (
        <MenuOption
          accessible={true}
          accessibilityLabel={Localized.Labels.add_market_card}
          accessibilityHint="Double tap to add a market card"
          onPress={this.handleMarketCard}
          label={Localized.Labels.add_market_card}
        />
      );
    }

    let sendSnackOption;
    if (AccountStore.isSendSnackEnabledForLocation()) {
      let blockedUsers;

      if (AccountStore.isConsumerSendSnackEnabled()) {
        blockedUsers = (
          <MenuOption
            accessible={true}
            accessibilityLabel={Localized.Labels.blocked_users}
            accessibilityHint="Double tap to navigate to the blocked users screen"
            onPress={this.blockedUsersClicked}
            label={Localized.Labels.blocked_users}
          />
        );
      }

      sendSnackOption = (
        <Section
          title={Localized.Labels.send_a_snack_title}
          switchStatus={this.state.sendSnackStatus}
          onSwitchToggle={this.onSendSnackStatusChange}
        >
          {blockedUsers}
        </Section>
      );
    }

    let eatItOrDeleteItOption;
    if (this.context.state.showEatItOrDeleteIt) {
      eatItOrDeleteItOption = (
        <MenuOption
          accessible={true}
          accessibilityLabel={Localized.Labels.eat_it_or_delete_it}
          accessibilityHint="Double tap to navigate to the eat it or delete it screen"
          onPress={this.eatItOrDeleteItClicked}
          label={Localized.Labels.eat_it_or_delete_it}
        />
      );
    }

    let connectOption;
    if (Settings.buildType === BuildTypes.canteen) {
      connectOption = (
        <MenuOption
          accessible={true}
          accessibilityLabel={Localized.Labels.connect}
          accessibilityHint="Double tap to navigate to the connect canteen screen"
          onPress={this.connectClicked}
          label={Localized.Labels.connect}
        />
      );
    }

    let addToAppleWallet;
    if (!this.context.state.passExists && this.context.state.canAddPass) {
      addToAppleWallet = (
        <MenuOption
          accessible={true}
          accessibilityLabel={Localized.Labels.add_to_apple_wallet}
          accessibilityHint="Double tap to navigate to add your apple wallet"
          onPress={this.addPassClicked}
          label={Localized.Labels.add_to_apple_wallet}
        />
      );
    }

    const helpLabel = UIManager.getHelpLabel();
    return (
      <Header title={Localized.Labels.settings}>
        <ScrollView>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`Full Name: ${this.state.name}`}
            accessibilityRole="text"
            aria-label={`Full Name: ${this.state.name}, text`}
            style={[styles.nameText, styles.content]}
          >
            {this.state.name}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`Email: ${this.state.email}`}
            accessibilityRole="text"
            aria-label={`Email: ${this.state.email}, text`}
            style={[styles.emailText, styles.content]}
          >
            {this.state.email}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`Market Location: ${this.state.locationName}`}
            accessibilityRole="text"
            aria-label={`Market Location: ${this.state.locationName}, text`}
            style={[styles.locationText, styles.content]}
          >
            {this.state.locationName}
          </AVText>

          <Section title={Localized.Labels.account}>
            <MenuOption
              accessibilityLabel={Localized.Labels.personal_info}
              accessibilityHint="Double tap to view or update your personal information"
              onPress={this.toManageAccount}
              label={Localized.Labels.personal_info}
            />
            <MenuOption
              accessibilityLabel={Localized.Labels.history}
              accessibilityHint="Double tap to view your purchase history"
              onPress={this.toPurchaseHistory}
              label={Localized.Labels.history}
            />
            {paymentMethods}
            <MenuOption
              accessibilityLabel={Localized.Labels.balances}
              accessibilityHint="Double tap to view your balances"
              onPress={this.toBalances}
              label={Localized.Labels.balances}
            />
            {rewardOption}
            {editMarketCard}
          </Section>
          {sendSnackOption}
          <Section title={Localized.Labels.security}>
            <MenuOption
              accessibilityLabel={Localized.Labels.change_password}
              accessibilityHint="Double tap to change your password"
              onPress={this.handleChangePassword}
              label={Localized.Labels.change_password}
            />
            <MenuOption
              accessibilityLabel={Localized.Labels.change_pin}
              accessibilityHint="Double tap to change your pin"
              onPress={this.handleChangePin}
              label={Localized.Labels.change_pin}
            />
          </Section>
          <Section title={Localized.Labels.help_and_other}>
            {eatItOrDeleteItOption}
            {connectOption}
            <MenuOption
              accessibilityLabel={helpLabel}
              accessibilityHint="Double tap to navigate to the help screen"
              onPress={this.contactClicked}
              label={helpLabel}
            />
            <MenuOption
              accessibilityLabel={Localized.Labels.view_privacy_policy}
              accessibilityHint="Double tap to view the privacy policy"
              onPress={this.handlePrivacyPolicy}
              label={Localized.Labels.view_privacy_policy}
            />
            <MenuOption
              accessibilityLabel={Localized.Labels.view_terms_and_conditions}
              accessibilityHint="Double tap to view terms and conditions"
              onPress={this.handleTerms}
              label={Localized.Labels.view_terms_and_conditions}
            />
            {addToAppleWallet}
            <MenuOption
              accessibilityLabel={Localized.Labels.delete_account}
              accessibilityHint="Double tap to navigate to the delete account screen"
              onPress={this.handleDeleteAccount}
              label={Localized.Labels.delete_account}
            />
          </Section>

          <View style={styles.content}>
            <RoundedButton
              text={Localized.Buttons.signout}
              onPress={this.onLogoutSelect}
              containerStyle={styles.btnStyle}
              buttonType={ButtonType.outline}
              accessible={true}
              accessibilityLabel={Localized.Buttons.signout}
              accessibilityHint="Double tap to log out"
              accessibilityRole="button"
              role="button"
              aria-label={Localized.Buttons.signout}
            />
            <AVText
              style={styles.versionText}
              accessible={true}
              accessibilityLabel={`Application version: ${this.state.fullVersion}`}
              accessibilityRole="text"
              aria-label={`Application version: ${this.state.fullVersion}, text`}
            >
              {this.state.fullVersion}
            </AVText>
          </View>
        </ScrollView>
      </Header>
    );
  }
}

const styles = StyleSheet.create({
  btnStyle: {
    marginTop: Styles.Spacing.m2,
  },
  content: {
    paddingHorizontal: Styles.Spacing.m3,
  },
  emailText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
  },
  locationText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m0,
  },
  nameText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '600',
    marginTop: Styles.Spacing.m3,
  },
  versionText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m4,
  },
});
const ConnectedAccount = connect((state: RootState) => ({
  paymentCredentials: state.account.paymentCredentials,
}))(Account);
export default withForwardedNavigationParams<AccountProps>()(ConnectedAccount);
