import {createAsyncThunk} from '@reduxjs/toolkit';
import Events, {FundStatus} from 'src/logging/Events';
import {alertError} from 'src/components/helpers/AlertHelper';
import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import Localized from 'src/constants/AppStrings';
import {AppDispatch, RootState} from '../../store';
import Settings from 'src/Settings';
import Util from 'src/Util';
import PaymentApi from 'src/api/PaymentApi';
import AccountApi from 'src/api/AccountApi';
import AppApi from 'src/api/AppApi';
import {
  adjustDefaultBalance,
  fetchAccount,
  updateAccountBalanceId,
} from 'src/redux/slices/accountSlice';
import {handleNextAction, initStripe} from 'src/nativeModules/Stripe';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';

const PAYROLL_LIMIT_ERROR = 'insufficient-payroll-balance';

function addFundsFailed(msg?: string) {
  let errorMessage = Localized.Errors.failed_to_add_funds;

  if (msg === PAYROLL_LIMIT_ERROR) {
    errorMessage = Localized.Errors.reached_payroll_deduct_limit;
  }

  alertError(errorMessage);
}

async function addFundsSuccess({
  amount,
  cardToken,
  paymentCredentials,
  dispatch,
  accountId,
}) {
  Events.AddFunds.trackEvent(
    FundStatus.Success,
    amount,
    cardToken,
    paymentCredentials.type,
  );

  // TODO will remove
  await ActionsFactory.getAccountActions().getBalance(
    AccountStore.getAccountId(),
    true,
  );

  await dispatch(
    adjustDefaultBalance({
      amount,
      reason: Localized.Labels.add_funds,
    }),
  );
  await dispatch(fetchAccount(accountId));
}

export interface IAddFundsCardParams {
  amount: number;
  tokenId: string;
  addFundSuccess?: () => void;
}

export const addFundsCard = createAsyncThunk<
  void,
  IAddFundsCardParams,
  {dispatch: AppDispatch; state: RootState}
>(
  'funding/addFundsCard',
  async ({amount, tokenId, addFundSuccess}, {dispatch, getState}) => {
    dispatch(
      showSpinner({
        spinnerText: `${Localized.Labels.adding_funds}...`,
      }),
    );

    try {
      const {paymentCredentials, account, accountBalanceId} =
        getState().account;
      const processor = Settings.processors[paymentCredentials.type];
      if (processor === Settings.processors.stripe) {
        const paymentIntentResponse = await PaymentApi.createPaymentIntent(
          account.id,
          tokenId,
          amount,
          Util.getCurrentDate(),
        );

        if (
          paymentIntentResponse.status === 'ok' &&
          !paymentIntentResponse.data
        ) {
          await addFundsSuccess({
            amount,
            cardToken: tokenId,
            paymentCredentials,
            dispatch,
            accountId: account.id,
          });
          addFundSuccess && addFundSuccess();
          return;
        }

        await initStripe({
          publishableKey: paymentCredentials.key,
        });

        const {error, paymentIntent} = await handleNextAction(
          paymentIntentResponse.data.clientSecret,
        );

        if (error) {
          Events.Error.trackEvent(
            'Exception',
            'FundingService:AddFunds',
            error.message ? error.message : JSON.stringify(error),
          );
          addFundsFailed();
        } else {
          await PaymentApi.confirmPaymentIntent(
            account.id,
            paymentIntent.id,
            paymentIntentResponse.data.transId,
          );
          await addFundsSuccess({
            amount,
            cardToken: tokenId,
            paymentCredentials,
            dispatch,
            accountId: account.id,
          });
          addFundSuccess && addFundSuccess();
        }
      } else {
        let balanceId = accountBalanceId;
        if (!balanceId) {
          const balance = await AccountApi.convertLegacyBalanceAndTokens(
            account.id,
            false,
          );

          dispatch(updateAccountBalanceId(balance.accountBalanceId));
          balanceId = balance.accountBalanceId;
        }
        const result = await AppApi.addFundsToken(
          account.id,
          balanceId,
          tokenId,
          amount,
          Util.getCurrentDate(),
        );

        if (result.accountId) {
          await addFundsSuccess({
            amount,
            cardToken: tokenId,
            paymentCredentials,
            dispatch,
            accountId: account.id,
          });
          addFundSuccess && addFundSuccess();
        } else {
          throw new Error(result.message);
        }
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'FundingService:AddFunds',
        error.message ? error.message : JSON.stringify(error),
      );
      addFundsFailed();
    } finally {
      dispatch(hideSpinner());
    }
  },
);
