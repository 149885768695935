import {IApplePay} from './interfaces/IApplePay';
declare global {
  interface Window {
    ApplePaySession: any;
  }
}

class ApplePay implements IApplePay {
  async canMakePaymentsApplePay(processorType: string) {
    if (window.ApplePaySession) {
      try {
        return await window.ApplePaySession.canMakePayments();
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  async presentApplePay(
    amount: number,
    countryCode: string,
    currencyCode: string,
    sandbox: boolean,
    processor: number,
    processorKey?: string,
    merchantId?: string,
  ) {
    return '';
  }

  paymentSucceeded(processor: number) {}

  paymentFailed(processor: number) {}
}

export default new ApplePay();
