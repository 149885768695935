import * as React from 'react';
import {
  StyleSheet,
  View,
  InteractionManager,
  Platform,
  ScrollView,
  Text,
  // Switch,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import BackSubheader from '../../elements/BackSubheader';
import CreditCardInput from '../../elements/funding/CreditCardInput';
import AVTextInput from '../../elements/AVTextInput';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import type {CCModel} from 'src/types/CCModel';
import ActionsFactory from 'src/actions/ActionsFactory';
import PersistentStore from 'src/services/PersistentStoreService';
import CreditCardHelper from 'src/services/CreditCardService';
import {CardIOUtilities, CardIOView} from 'src/nativeModules/CardIO';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import {CreditCard} from 'src/models/CreditCard';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import Switch from 'src/components/elements/Switch';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type CreditCardScreenState = {
  addManual: boolean;
  creditCard: string;
  expireMonth: string;
  cvv: string;
  expireYear: string;
  issuer: string;
  redactedCardNumber: string;
  zip: string;
  cardScanned: boolean;
  defaultTokenStatus: boolean;
  isDefaultTokenDisabled: boolean;
  previousRoute: string | null;
};
export type CreditCardScreenProps = {
  cardAdded: () => void;
  buttonText: string;
  paymentCredentials: PaymentCredentials;
  creditCards: Array<CreditCard>;
  navigation?: NavigationProp<CreditCardScreen>;
};

class CreditCardScreen extends React.Component<
  CreditCardScreenProps,
  CreditCardScreenState
> {
  scrollView: ScrollView | null;
  year: AVTextInput | null;
  month: AVTextInput | null;
  cvv: AVTextInput | null;
  zipCode: AVTextInput | null;

  static defaultProps = {
    buttonText: Localized.Buttons.save,
    cardAdded: null,
  };
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: CreditCardScreenProps) {
    super(props);
    this.state = {
      addManual: false,
      creditCard: '',
      issuer: '',
      expireMonth: '',
      expireYear: '',
      cvv: '',
      redactedCardNumber: '',
      zip: '',
      cardScanned: false,
      defaultTokenStatus: this.props.creditCards.length === 0,
      isDefaultTokenDisabled: this.props.creditCards.length === 0,
      previousRoute: null,
    };
    this.validate = this.validate.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onAddManuallyPress = this.onAddManuallyPress.bind(this);
    this.scanClick = this.scanClick.bind(this);
    this.didScanCard = this.didScanCard.bind(this);
    this.onCloseSelect = this.onCloseSelect.bind(this);
  }

  componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});

    InteractionManager.runAfterInteractions(() => {
      if (CardIOUtilities && CardIOUtilities.preload) {
        CardIOUtilities.preload();
      }
    });
  }

  onAddManuallyPress() {
    this.setState({
      addManual: true,
    });
    FirebaseAnalytic.trackEvent('AddCreditCardManually', 'CreditCardScreen', {
      ...this.props,
      ...this.state,
    });
  }

  onCloseSelect() {
    Events.AddCard.trackEvent('CANCEL');
    NavActions.pop();
  }

  validate(ccModel: CCModel) {
    if (
      !ccModel.CardNumber ||
      !ccModel.ExpirationMonth ||
      !ccModel.ExpirationYear ||
      !ccModel.CvvCode ||
      !ccModel.BillingZip
    ) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  async handleClick() {
    const cardNumber = this.state.creditCard;
    const {expireMonth, cvv} = this.state;
    let {expireYear, issuer} = this.state;
    const accountId = await PersistentStore.getAccountId();

    if (expireYear && expireYear.length === 2) {
      expireYear = `20${expireYear}`;
    }

    if (!issuer && cardNumber) {
      issuer = CreditCardHelper.getCardType(cardNumber);
    }

    const ccModel = {
      CardNumber: cardNumber,
      ExpirationMonth: expireMonth as unknown as number,
      ExpirationYear: expireYear as unknown as number,
      CvvCode: cvv,
      CardNumberMask: this.state.redactedCardNumber,
      BillingZip: this.state.zip,
      AccountId: accountId || '',
      Issuer: issuer,
    };
    const errorMessage = this.validate(ccModel);

    if (errorMessage) {
      alertError(errorMessage);
      Events.Error.trackEvent(
        'Exception',
        'CreditCardScreen: validate(ccModel)',
        errorMessage,
      );
    } else {
      this.context.actions.showSpinner();

      try {
        const response = await ActionsFactory.getAccountActions().addCreditCard(
          ccModel,
          this.props.paymentCredentials,
        );

        if (response) {
          Events.AddCard.trackEvent('ADDED');
          if (this.state.defaultTokenStatus) {
            await ActionsFactory.getAccountActions().updateDefaultToken(
              response.accountId,
              response.accountBalanceId,
              response.balanceTokenId,
            );
          }
          alertSuccess(Localized.Success.credit_card_added);
          this.context.actions.hideSpinner();

          if (this.props.cardAdded) {
            this.props.cardAdded();
          } else {
            NavActions.pop();
          }
        } else {
          throw new Error('Invalid Token Response');
        }
      } catch (error) {
        if (error.statusCode === 429) {
          alertError(Localized.Errors.please_try_again_shortly);
          return;
        }

        const guid = await uuid.getRandomUUID();
        Events.Error.trackEvent(
          'Exception',
          'CreditCardScreen:HandleClick',
          error.message ? error.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.error_adding_credit_card, guid);
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  scanClick() {
    this.setState({
      addManual: false,
    });
    FirebaseAnalytic.trackEvent('ScanClick', 'CreditCardScreen', {
      ...this.props,
      ...this.state,
    });
  }

  didScanCard(result: any) {
    this.setState({
      creditCard: result.cardNumber,
      cardScanned: true,
      expireMonth: result.expiryMonth,
      expireYear: result.expiryYear,
      cvv: result.cvv,
      redactedCardNumber: result.redactedCardNumber,
      issuer: result.cardType,
    });
  }

  focusInput(input: AVTextInput | null) {
    if (input) {
      input.focus();
    }
  }

  render() {
    if (this.state.cardScanned) {
      return (
        <BackSubheader
          previousRoute={this.state.previousRoute}
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
          title={Localized.Labels.add_card}
          onBackSelect={this.onCloseSelect}
        >
          <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
            <ScrollView
              contentContainerStyle={styles.scrollView}
              style={Styles.Style.maxWidthContainer}
              ref={(scrollView) => {
                this.scrollView = scrollView;
              }}
              automaticallyAdjustContentInsets={false}
            >
              <View style={styles.inputContainer}>
                <CreditCardInput
                  accessibilityLabel={Localized.Labels.card_number}
                  editable={false}
                  issuer={this.state.issuer}
                  value={this.state.redactedCardNumber}
                  label={Localized.Labels.card_number}
                />
                <View style={styles.row}>
                  <View style={styles.inputRowLeft}>
                    <AllyTextInput
                      keyboardType="numeric"
                      useHalfSize={true}
                      label={Localized.Labels.expiration_month}
                      value={this.state.expireMonth}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.expiration_month}
                      accessibilityValue={{text: this.state.expireMonth}}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                      onChangeText={(text) =>
                        this.setState({expireMonth: text})
                      }
                    />
                  </View>
                  <View style={styles.inputRowRight}>
                    <AllyTextInput
                      keyboardType="numeric"
                      useHalfSize={true}
                      label={Localized.Labels.expiration_year + 'please'}
                      value={this.state.expireYear}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.expiration_year}
                      accessibilityValue={{text: this.state.expireYear}}
                      onChangeText={(text) => this.setState({expireYear: text})}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                    />
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.inputRowLeft}>
                    <AllyTextInput
                      keyboardType="numeric"
                      useHalfSize={true}
                      label={Localized.Labels.cvv}
                      value={this.state.cvv}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.cvv}
                      accessibilityValue={{text: this.state.cvv}}
                      accessibilityHint="CVV is found on the back of most credit cards"
                      onChangeText={(text) =>
                        this.setState({
                          cvv: text,
                        })
                      }
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    />
                  </View>

                  <View style={styles.inputRowRight}>
                    <AllyTextInput
                      keyboardType="numeric"
                      useHalfSize={true}
                      label={Localized.Labels.zip_code}
                      value={this.state.zip}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.zip_code}
                      accessibilityValue={{text: this.state.zip}}
                      onChangeText={(text) => this.setState({zip: text})}
                      onSubmitEditing={this.handleClick}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.defaultStatusView}>
                <Switch
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  accessibilityLabel={Localized.Labels.set_as_default}
                  testID="setAsDefaultScanNewCard"
                  text={Localized.Labels.set_as_default}
                  accessibilityState={{
                    checked: this.state.defaultTokenStatus,
                    disabled: this.state.isDefaultTokenDisabled,
                  }}
                  disabled={this.state.isDefaultTokenDisabled}
                  value={this.state.defaultTokenStatus}
                  onValueChange={(value) => {
                    this.setState({defaultTokenStatus: value});
                  }}
                />
              </View>
            </ScrollView>
            <RoundedButton
              accessible={true}
              accessibilityLabel={`${this.props.buttonText}`}
              accessibilityRole="button"
              aria-label={`${this.props.buttonText}`}
              role="button"
              buttonType={ButtonType.action}
              onPress={this.handleClick}
              text={this.props.buttonText}
            />
          </KeyboardAvoidingView>
        </BackSubheader>
      );
    } else if (
      !this.state.addManual &&
      Platform.OS === 'ios' &&
      CardIOUtilities.CAN_READ_CARD_WITH_CAMERA
    ) {
      return (
        <BackSubheader
          previousRoute={this.state.previousRoute}
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
          title={Localized.Labels.add_card}
        >
          <View style={styles.cardView}>
            <View style={styles.cardViewContainer}>
              <View
                accessible={true}
                accessibilityLabel="Scan new card"
                aria-label="Scan new card"
              >
                <CardIOView
                  style={styles.cardView}
                  scanExpiry
                  hideCardIOLogo
                  detectionMode={CardIOView.cardImageAndNumber}
                  didScanCard={this.didScanCard}
                  scanInstructions=""
                  allowFreelyRotatingCardGuide={false}
                  guideColor={Styles.white}
                />
              </View>

              <View style={styles.absoluteTextContainer}>
                <Text
                  style={styles.instructionText}
                  accessible={true}
                  accessibilityLabel={Localized.Labels.position_card_in_frame}
                  accessibilityRole="text"
                  aria-label={`${Localized.Labels.position_card_in_frame}, text`}
                >
                  {Localized.Labels.position_card_in_frame}
                </Text>
              </View>
            </View>
            <RoundedButton
              accessible={true}
              accessibilityLabel="Add Card Manually"
              accessibilityHint="Double tap to enter your card details manually"
              accessibilityRole="button"
              aria-label="Add Card Manually"
              role="button"
              buttonType={ButtonType.outline}
              containerStyle={styles.tetriaryBtn}
              onPress={this.onAddManuallyPress}
              text={Localized.Buttons.manual_card_entry}
            />
          </View>
        </BackSubheader>
      );
    }

    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.add_card}
      >
        <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
          <ScrollView
            style={Styles.Style.maxWidthContainer}
            ref={(scrollView) => {
              this.scrollView = scrollView;
            }}
            keyboardDismissMode="interactive"
            automaticallyAdjustContentInsets={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={styles.inputContainer}>
              <CreditCardInput
                accessibilityLabel={Localized.Labels.card_number}
                onChangeText={(text) =>
                  this.setState({
                    creditCard: text,
                  })
                }
                onSubmitEditing={() => this.focusInput(this.month)}
                label={Localized.Labels.card_number}
                value={this.state.creditCard}
              />
              <View style={styles.row}>
                <View style={styles.inputRowLeft}>
                  <AllyTextInput
                    useHalfSize={true}
                    keyboardType="numeric"
                    label={Localized.Labels.expiration_month}
                    value={this.state.expireMonth}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.expiration_month}
                    accessibilityValue={{text: this.state.expireMonth}}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    onChangeText={(text) => this.setState({expireMonth: text})}
                  />
                </View>
                <View style={styles.inputRowRight}>
                  <AllyTextInput
                    useHalfSize={true}
                    keyboardType="numeric"
                    label={Localized.Labels.expiration_year}
                    value={this.state.expireYear}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.expiration_year}
                    accessibilityValue={{text: this.state.expireYear}}
                    onChangeText={(text) => this.setState({expireYear: text})}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  />
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.inputRowLeft}>
                  <AllyTextInput
                    useHalfSize={true}
                    keyboardType="numeric"
                    label={Localized.Labels.cvv}
                    value={this.state.cvv}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.cvv}
                    accessibilityValue={{text: this.state.cvv}}
                    onChangeText={(text) => this.setState({cvv: text})}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  />
                </View>
                <View style={styles.inputRowRight}>
                  <AllyTextInput
                    useHalfSize={true}
                    keyboardType="numeric"
                    label={Localized.Labels.zip_code}
                    value={this.state.zip}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.zip_code}
                    accessibilityValue={{text: this.state.zip}}
                    onChangeText={(text) => this.setState({zip: text})}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  />
                </View>
              </View>
              <View style={styles.defaultStatusView}>
                <Switch
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  accessibilityLabel={Localized.Labels.set_as_default}
                  testID="setAsDefaultNewCard"
                  text={Localized.Labels.set_as_default}
                  accessibilityState={{
                    checked: this.state.defaultTokenStatus,
                    disabled: this.state.isDefaultTokenDisabled,
                  }}
                  disabled={this.state.isDefaultTokenDisabled}
                  value={this.state.defaultTokenStatus}
                  onValueChange={(value) => {
                    this.setState({defaultTokenStatus: value});
                  }}
                />
              </View>
              {Platform.OS === 'ios' &&
              CardIOUtilities.CAN_READ_CARD_WITH_CAMERA ? (
                <RoundedButton
                  buttonType={ButtonType.outline}
                  onPress={this.scanClick}
                  accessible={true}
                  accessibilityLabel="Scan card"
                  accessibilityHint="Double tap to scan your card with your device's camera"
                  accessibilityRole="button"
                  aria-label="Scan card"
                  role="button"
                  containerStyle={styles.scanBtn}
                  text={Localized.Buttons.scan_card}
                />
              ) : null}
            </View>
          </ScrollView>
          <RoundedButton
            accessible={true}
            accessibilityLabel="Save card"
            accessibilityHint="Double tap to save your card information"
            accessibilityRole="button"
            aria-label="Save card"
            role="button"
            buttonType={ButtonType.action}
            onPress={this.handleClick}
            text={this.props.buttonText}
          />
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  absoluteTextContainer: {
    backgroundColor: Styles.white,
    bottom: 0,
    height: 300,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: 2,
  },
  cardView: {
    flex: 1,
  },
  cardViewContainer: {
    flex: 1,
    marginTop: -300,
    zIndex: 1,
  },
  ccInput: {},
  input: {},
  inputContainer: {
    flexDirection: 'column',
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m1,
    paddingBottom: Styles.Spacing.m5,
  },
  inputRowLeft: {
    flex: 0.5,
    paddingRight: Styles.Spacing.m2,
  },
  inputRowRight: {
    flex: 0.5,
    paddingLeft: Styles.Spacing.m1,
  },
  instructionText: {
    alignSelf: 'center',
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m2,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  scanBtn: {
    marginTop: Styles.Spacing.m2,
  },
  scrollView: {
    alignSelf: 'stretch',
  },
  tetriaryBtn: {
    alignSelf: 'center',
    bottom: Styles.Spacing.m4,
    position: 'absolute',
    zIndex: 3,
  },
  defaultStatusView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
  },
  defaultSwitch: {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  },
  setDefaultLabel: {
    fontSize: Styles.Fonts.f1,
  },
});

const ConnectedCardsScreen = connect((state: RootState) => ({
  paymentCredentials: state.account.paymentCredentials,
  creditCards: state.account.creditCards,
}))(CreditCardScreen);

export default withForwardedNavigationParams<any>()(ConnectedCardsScreen);
