import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import AppApi from 'src/api/AppApi';
import PlatformApi from 'src/api/PlatformApi';
import Events from 'src/logging/Events';
import {
  Account as ServerAccount,
  Balance as ServerBalance,
  BalanceTypes,
  PaymentCredentials as ServerPaymentCredentials,
  PaymentToken,
  PayrollDeductInfo,
} from 'src/types/serverTypes/Account';
import PaymentCredentialsBuilder, {
  PaymentCredentials,
} from 'src/models/PaymentCredentials';
import AccountApi from 'src/api/AccountApi';
import CreditCardBuilder, {CreditCard} from 'src/models/CreditCard';
import AccountBuilder, {Account, defaultAccount} from 'src/models/Account';
import {AppDispatch, RootState} from '../store';
import BalanceBuilder, {Balance} from 'src/models/Balance';
import AccountConstants from 'src/constants/AccountConstants';
import TimeUtils from 'src/services/TimeUtils';
import PayrollDeductInformationBuilder, {
  PayrollDeductInformation,
} from 'src/models/PayrollDeductInformation';
import BalanceChangedEvent from 'src/logging/BalanceChangedEvent';
import {hideSpinner, showSpinner} from './screenSlice';
import Localized from 'src/constants/AppStrings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import {savedReceipt} from 'src/components/elements/DeepLinkFunctions';
import {alertError, alertSuccess} from 'src/components/helpers/AlertHelper';
import moment from 'moment';
import uuid from 'src/nativeModules/UUID';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import Util from '../../Util';

export interface IAccountState {
  paymentCredentials: PaymentCredentials;
  creditCards: Array<CreditCard>;
  canAddFunds: boolean;
  account: Account;
  defaultPaymentToken: string;
  defaultNonNfcPaymentToken: string;
  payrollDeductInformation: PayrollDeductInformation;
  savedReceipt: savedReceipt;
  beaconPopup: boolean;
  //check
  accountBalanceId: string;
}

const defaultPaymentCredentials = {
  paymentAvailable: false,
  applePayAvailable: false,
  googlePayAvailable: false,
} as PaymentCredentials;

export const initialState: IAccountState = {
  paymentCredentials: defaultPaymentCredentials,
  creditCards: [],
  canAddFunds: false,
  account: defaultAccount,
  defaultPaymentToken: '',
  defaultNonNfcPaymentToken: '',
  payrollDeductInformation: null,
  savedReceipt: null,
  beaconPopup: false,
  //check
  accountBalanceId: '',
};

const getPayrollDeductGroupId = async (account: Account) => {
  let payrollDeductGroupId = account.payrollDeductGroupId;
  const locationId = account.locationId;

  if (!payrollDeductGroupId && locationId) {
    const payrollDeductGroup: {payrollDeductGroupId?: string} =
      await PlatformApi.fetchPayrollDeductGroup(locationId);

    if (payrollDeductGroup) {
      payrollDeductGroupId = payrollDeductGroup.payrollDeductGroupId;
    }
  }

  return payrollDeductGroupId;
};

export const payrollDeductOptInChanged = createAsyncThunk<
  boolean,
  boolean,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'account/payrollDeductOptInChanged',
  async (optIn: boolean, {getState, dispatch}) => {
    const account = getState().account.account;
    const payrollDeductGroupId = await getPayrollDeductGroupId(account);
    if (payrollDeductGroupId) {
      await AccountApi.setPayrollDeductOptIn(
        account.id,
        optIn,
        payrollDeductGroupId,
      );

      if (!account.hasPayrollBalance && optIn) {
        dispatch(fetchAccountBalances(account.id));
      }
    }
    return optIn;
  },
);

export const fetchAccountBalances = createAsyncThunk<
  Array<Balance>,
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>('account/fetchAccountBalances', async (accountId: string, {getState}) => {
  try {
    const {account} = getState().account;
    const balances: Array<ServerBalance> = await AccountApi.fetchBalances(
      accountId || account.id,
    );
    const mostRecentBalanceUpdate = balances
      .map((balance) => balance.lastUpdated)
      .sort((a, b) => moment(a).diff(moment(b)))[0];
    if (
      moment(account.accountBalancesLastUpdated).isAfter(
        moment(mostRecentBalanceUpdate),
      )
    ) {
      account.accountBalancesLastUpdated = moment().toString();
    }
    return balances
      ? balances.map((balance) => BalanceBuilder.build(balance))
      : account.balances;
  } catch (error) {
    Events.Error.trackEvent(
      'Exception',
      'accountSlice:fetchAccountBalances',
      error.message ? error.message : error.toString(),
    );
  }
});

export const fetchPaymentCredentials = createAsyncThunk(
  'account/fetchPaymentCredentials',
  async (accountId: string) => {
    try {
      if (!accountId || accountId === '-1') {
        throw new Error('Invalid Account ID');
      }
      const serverCredentials: ServerPaymentCredentials =
        await AppApi.getPaymentCredentials(accountId);
      FirebaseAnalytic.trackEvent(
        'accountSlice:fetchPaymentCredentials',
        'accountSlice',
        {
          serverCredentials,
          accountId,
          url: serverCredentials.url,
          key: serverCredentials.key,
          test: serverCredentials.test,
          merchantId: serverCredentials.merchantId,
          type: serverCredentials.type,
          useConnect: serverCredentials.useConnect,
        },
      );
      return await PaymentCredentialsBuilder.build(serverCredentials);
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'accountSlice:fetchPaymentCredentials',
        error.message ? error.message : error.toString(),
        guid,
        {
          accountId,
        },
      );
    }
  },
);

export const fetchAccountBalanceCreditCards = createAsyncThunk(
  'account/fetchAccountBalanceCreditCards',
  async (data: {accountId: string; accountBalanceId: string}) => {
    try {
      const {accountId, accountBalanceId} = data;
      const paymentTokens: Array<PaymentToken> = await AccountApi.fetchTokens(
        accountId,
        accountBalanceId,
      );
      return paymentTokens.map((token) => CreditCardBuilder.build(token));
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'accountSlice:fetchAccountBalanceCreditCards',
        error.message ? error.message : error.toString(),
      );
    }
  },
);

export const fetchAccount = createAsyncThunk<
  Partial<IAccountState>,
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>('account/fetchAccount', async (accountId: string, {getState}) => {
  try {
    const {account} = getState().account;
    const serverAccount: ServerAccount = await AccountApi.fetchAccount(
      accountId,
    );
    let accountModel: Account;
    if (
      !account.lastUpdated ||
      moment(serverAccount.lastUpdated).isAfter(
        moment(account.accountlastUpdated),
      )
    ) {
      accountModel = AccountBuilder.build(serverAccount);
    } else {
      accountModel = account;
    }
    let creditCards: Array<CreditCard> = [];
    if (accountModel.defaultBalance) {
      const paymentTokens: Array<PaymentToken> = await AccountApi.fetchTokens(
        accountId,
        accountModel.defaultBalance.id,
      );
      creditCards = paymentTokens.map((token) =>
        CreditCardBuilder.build(token),
      );
    }
    let payrollDeductInformation = initialState.payrollDeductInformation;
    if (accountModel.payrollDeductGroupId || accountModel.locationId) {
      if (accountModel.hasPayrollBalance) {
        const payrollData: PayrollDeductInfo =
          await PlatformApi.retrievePayrollDeductData(
            TimeUtils.getCurrentServerDate(),
            accountModel.payrollDeductGroupId,
            accountModel.locationId,
          );

        payrollDeductInformation = PayrollDeductInformationBuilder.build(
          payrollData,
          accountModel.balances.find(
            (balance) => balance.type === BalanceTypes.Payroll,
          ),
        );
      }
    }
    return {
      account: accountModel,
      creditCards,
      payrollDeductInformation,
    };
  } catch (error) {
    Events.Error.trackEvent(
      'Exception',
      'accountSlice:fetchAccount',
      error.message ? error.message : error.toString(),
    );
  }
});

export const scanGiftCard = createAsyncThunk<
  void,
  {code: string; callback: () => void},
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>('account/scanGiftCard', async ({code, callback}, {getState, dispatch}) => {
  try {
    dispatch(showSpinner());
    const giftCard = await AccountApi.searchEGiftCard(
      code.replace('GMA:id:', ''),
    );

    if (giftCard.items.length > 0) {
      const giftCardDetail = giftCard.items.find((gift) => gift);
      const balance = giftCardDetail.balances.find((b) => b);
      if (
        balance.expirationDate &&
        TimeUtils.isDateSmallerThanToday(balance.expirationDate)
      ) {
        alertError(Localized.Errors.eGift_card_expired);
      } else if (Number(balance.balance) <= 0) {
        alertError(Localized.Errors.eGift_card_already_used);
      } else {
        const response = await AccountApi.transferEGiftCardBalance({
          giftCardBalanceId: balance.accountBalanceId,
          giftCardAccountId: balance.accountId,
          amount: balance.balance,
        }).catch(() => {
          alertError(Localized.Errors.funding_unavailable);
        });

        if (response) {
          const newSuccessMessage = `$${balance.balance} ${Localized.Success.gift_card_added}`;
          alertSuccess(newSuccessMessage, () => {
            NavActions.popToRoute(AppRoutes.Cards);
          });
        }
      }
    } else {
      alertError(Localized.Errors.failed_to_add_funds);
    }
    dispatch(hideSpinner());
  } catch (error) {
    Events.Error.trackEvent(
      'Exception',
      'accountSlice:scanGiftCard',
      error.message ? error.message : error.toString(),
    );
  }
});

const canAddFunds = (state: IAccountState): boolean => {
  return (
    state.creditCards.length > 0 ||
    state.account.isPayrollAvailable ||
    state.paymentCredentials.applePayAvailable ||
    state.paymentCredentials.googlePayAvailable
  );
};

const getDefaultPaymentMethodToken = (state: IAccountState): string => {
  const creditCardsFiltered = Util.filterExpiredCreditCards(state.creditCards);
  const hasCards = creditCardsFiltered.length > 0;

  if (state.account.isPayrollAvailable) {
    return AccountConstants.PAYROLL_TOKEN;
  } else if (state.paymentCredentials.applePayAvailable) {
    return AccountConstants.APPLE_PAY_TOKEN;
  } else if (state.paymentCredentials.googlePayAvailable) {
    return AccountConstants.GOOGLE_PAY_TOKEN;
  } else if (hasCards) {
    return state.creditCards[0].id || '';
  }

  return '';
};

const getDefaultNonNfcPaymentMethodToken = (state: IAccountState): string => {
  const hasCards = state.creditCards.length > 0;

  if (state.account.isPayrollAvailable) {
    return AccountConstants.PAYROLL_TOKEN;
  } else if (hasCards) {
    return state.creditCards[0].id || '';
  }

  return '';
};

const logBalanceChangedEvent = (
  state: IAccountState,
  source: string,
  newBalance: number,
) => {
  BalanceChangedEvent.trackEvent(source, newBalance?.toFixed(2));
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout(state) {
      state.creditCards = [];
      state.paymentCredentials = defaultPaymentCredentials;
      state.account = defaultAccount;
      state.canAddFunds = false;
      state.defaultNonNfcPaymentToken = '';
      state.defaultPaymentToken = '';
      state.payrollDeductInformation = null;
    },
    adjustDefaultBalance(
      state,
      action: PayloadAction<{amount: number; reason: string}>,
    ) {
      if (state.account.defaultBalance) {
        const newBalance =
          state.account.defaultBalance.balance + action.payload.amount;
        state.account.defaultBalance.balance = Math.max(newBalance, 0);
        const newBalances = state.account.displayBalances.map((balance) => {
          if (balance.isDefault) {
            return {...balance, balance: Math.max(newBalance, 0)};
          }
          return balance;
        });
        state.account.displayBalances = newBalances;
        logBalanceChangedEvent(
          state,
          action.payload.reason,
          state.account.defaultBalance.balance,
        );
      }
    },
    storeReceipt(state, action: PayloadAction<savedReceipt>) {
      state.savedReceipt = action.payload;
    },
    deleteReceipt(state) {
      state.savedReceipt = null;
    },
    setBeaconPopupOff(state) {
      state.beaconPopup = true;
    },
    adjustPoints(state, action: PayloadAction<{points: number}>) {
      if (state.account.points) {
        state.account.points -= action.payload.points;
      }
    },
    //checked
    updateAccountBalanceId(state, action: PayloadAction<string>) {
      state.accountBalanceId = action.payload;
    },
  }, // used for sync actions
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountBalanceCreditCards.fulfilled, (state, action) => {
        state.creditCards = action.payload;
        state.defaultNonNfcPaymentToken =
          getDefaultNonNfcPaymentMethodToken(state);
        state.defaultPaymentToken = getDefaultPaymentMethodToken(state);
        state.canAddFunds = canAddFunds(state);
      })
      .addCase(fetchAccountBalanceCreditCards.rejected, (_, action) => {
        const error = action.error;
        Events.Error.trackEvent(
          'Exception',
          'AccountSlice:FetchAccountBalanceCreditCards',
          error.message ? error.message : error.toString(),
        );
      })
      .addCase(fetchAccountBalances.fulfilled, (state, action) => {
        state.account.balances = action.payload;
        state.account.displayBalances = action.payload.filter(
          (balance) =>
            balance.type !== BalanceTypes.Payroll && !balance.isDisabled,
        );
      })
      .addCase(fetchAccountBalances.rejected, (_, action) => {
        const error = action.error;
        Events.Error.trackEvent(
          'Exception',
          'AccountSlice:FetchAccountBalances',
          error.message ? error.message : error.toString(),
        );
      })
      .addCase(payrollDeductOptInChanged.fulfilled, (state, action) => {
        state.account.isPayrollAvailable =
          state.account.payrollIdentifier?.value?.length > 0 && action.payload;
        state.account.isPayrollDeductOptIn = action.payload;
        state.canAddFunds = canAddFunds(state);
        state.defaultNonNfcPaymentToken =
          getDefaultNonNfcPaymentMethodToken(state);
        state.defaultPaymentToken = getDefaultPaymentMethodToken(state);
      })
      .addCase(payrollDeductOptInChanged.rejected, (_, action) => {
        const error = action.error;
        Events.Error.trackEvent(
          'Exception',
          'AccountSlice:PayrollDeductOptInChanged',
          error.message ? error.message : error.toString(),
        );
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        if (!action.payload?.account) {
          return;
        }

        state.account = action.payload.account;
        state.creditCards = action.payload.creditCards;
        state.payrollDeductInformation =
          action.payload.payrollDeductInformation;
        state.canAddFunds = canAddFunds(state);
        state.defaultNonNfcPaymentToken =
          getDefaultNonNfcPaymentMethodToken(state);
        state.defaultPaymentToken = getDefaultPaymentMethodToken(state);
      })
      .addCase(fetchAccount.rejected, (_, action) => {
        const error = action.error;
        Events.Error.trackEvent(
          'Exception',
          'AccountSlice:FetchAccount',
          error.message ? error.message : error.toString(),
        );
      })
      .addCase(fetchPaymentCredentials.fulfilled, (state, action) => {
        state.paymentCredentials = action.payload;
        state.canAddFunds = canAddFunds(state);
        state.defaultNonNfcPaymentToken =
          getDefaultNonNfcPaymentMethodToken(state);
        state.defaultPaymentToken = getDefaultPaymentMethodToken(state);
      })
      .addCase(fetchPaymentCredentials.rejected, (_, action) => {
        const error = action.error;
        Events.Error.trackEvent(
          'Exception',
          'AccountSlice:FetchPaymentCredentials',
          error.message ? error.message : error.toString(),
        );
      });
  },
});
export const {
  logout,
  storeReceipt,
  deleteReceipt,
  setBeaconPopupOff,
  adjustDefaultBalance,
  adjustPoints,
  //check
  updateAccountBalanceId,
} = accountSlice.actions;
export default accountSlice.reducer;
