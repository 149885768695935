//@ts-nocheck
import * as React from 'react';
import Svg, {ClipPath, Defs, G, Mask, Path, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
}; // tslint:disable

export default class GooglePay extends React.Component<LogoProps> {
  render() {
    return (
      <Svg
        width={(32 / 21) * this.props.size}
        height={this.props.size}
        style={{isolation: 'isolate'}}
        viewBox="0 0 32 21"
      >
        <Defs>
          <ClipPath id="_clipPath_UrWv8vX9eJGjLYdl1Cdo2xXebuhHU7ST">
            <Rect
              width={(32 / 21) * this.props.size}
              height={this.props.size}
            />
          </ClipPath>
        </Defs>
        <G clipPath="url(#_clipPath_UrWv8vX9eJGjLYdl1Cdo2xXebuhHU7ST)">
          <ClipPath id="_clipPath_1DJHpPpcglXDfWSqDN0M73BPaGHeyL3c">
            <Rect
              x="0"
              y="0"
              width={(32 / 21) * this.props.size}
              height={this.props.size}
              transform="matrix(1,0,0,1,0,0)"
              fill="rgb(255,255,255)"
            />
          </ClipPath>
          <G clipPath="url(#_clipPath_1DJHpPpcglXDfWSqDN0M73BPaGHeyL3c)">
            <G>
              <Mask
                id="_mask_cFFK2ZMmQDVopAEEjuFYsMHaNwr6mBxo"
                x="-200%"
                y="-200%"
                width="400%"
                height="400%"
              >
                <Rect
                  x="-200%"
                  y="-200%"
                  width="400%"
                  height="400%"
                  style={{fill: 'white'}}
                />
                <Path
                  d=" M 27.08 20.5 L 2.92 20.5 C 2.283 20.505 1.671 20.257 1.217 19.811 C 0.763 19.365 0.505 18.756 0.5 18.12 L 0.5 3.88 C 0.505 3.244 0.763 2.635 1.217 2.189 C 1.671 1.743 2.283 1.495 2.92 1.5 L 27.08 1.5 C 27.717 1.495 28.329 1.743 28.783 2.189 C 29.237 2.635 29.495 3.244 29.5 3.88 L 29.5 18.13 C 29.484 19.452 28.401 20.511 27.08 20.5 L 27.08 20.5 Z "
                  fill="black"
                  stroke="none"
                />
              </Mask>
              <Path
                d=" M 27.08 20.5 L 2.92 20.5 C 2.283 20.505 1.671 20.257 1.217 19.811 C 0.763 19.365 0.505 18.756 0.5 18.12 L 0.5 3.88 C 0.505 3.244 0.763 2.635 1.217 2.189 C 1.671 1.743 2.283 1.495 2.92 1.5 L 27.08 1.5 C 27.717 1.495 28.329 1.743 28.783 2.189 C 29.237 2.635 29.495 3.244 29.5 3.88 L 29.5 18.13 C 29.484 19.452 28.401 20.511 27.08 20.5 L 27.08 20.5 Z "
                fill="rgb(255,255,255)"
                mask="url(#_mask_cFFK2ZMmQDVopAEEjuFYsMHaNwr6mBxo)"
                vectorEffect="non-scaling-stroke"
                strokeWidth="1"
                stroke="rgb(95,99,104)"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
              />
              <Path
                d=" M 27.08 20.5 L 2.92 20.5 C 2.283 20.505 1.671 20.257 1.217 19.811 C 0.763 19.365 0.505 18.756 0.5 18.12 L 0.5 3.88 C 0.505 3.244 0.763 2.635 1.217 2.189 C 1.671 1.743 2.283 1.495 2.92 1.5 L 27.08 1.5 C 27.717 1.495 28.329 1.743 28.783 2.189 C 29.237 2.635 29.495 3.244 29.5 3.88 L 29.5 18.13 C 29.484 19.452 28.401 20.511 27.08 20.5 L 27.08 20.5 Z "
                fill="rgb(255,255,255)"
              />
            </G>
          </G>
          <Path
            d=" M 14.373 11.076 L 14.373 14.124 L 13.407 14.124 L 13.407 6.6 L 15.969 6.6 C 16.587 6.588 17.181 6.822 17.631 7.254 C 18.081 7.656 18.333 8.238 18.321 8.838 C 18.333 9.444 18.081 10.026 17.631 10.434 C 17.181 10.86 16.629 11.076 15.975 11.076 L 14.373 11.076 L 14.373 11.076 Z  M 14.373 7.524 L 14.373 10.152 L 15.993 10.152 C 16.353 10.164 16.701 10.02 16.947 9.762 C 17.457 9.27 17.463 8.46 16.971 7.95 L 16.947 7.926 C 16.701 7.662 16.353 7.518 15.993 7.53 L 14.373 7.524 Z  M 20.547 8.808 C 21.261 8.808 21.825 9 22.239 9.378 C 22.653 9.762 22.857 10.284 22.857 10.95 L 22.857 14.118 L 21.933 14.118 L 21.933 13.404 L 21.891 13.404 C 21.489 13.992 20.961 14.286 20.295 14.286 C 19.731 14.286 19.251 14.118 18.873 13.782 C 18.501 13.47 18.291 13.008 18.303 12.522 C 18.303 11.988 18.507 11.568 18.909 11.25 C 19.311 10.932 19.851 10.776 20.523 10.776 C 21.099 10.776 21.567 10.884 21.939 11.088 L 21.939 10.866 C 21.939 10.536 21.795 10.224 21.543 10.014 C 21.285 9.786 20.955 9.66 20.613 9.66 C 20.073 9.66 19.647 9.888 19.329 10.344 L 18.477 9.81 C 18.939 9.144 19.629 8.808 20.547 8.808 L 20.547 8.808 Z  M 19.299 12.546 C 19.299 12.798 19.419 13.032 19.617 13.176 C 19.833 13.344 20.097 13.434 20.367 13.428 C 20.775 13.428 21.165 13.266 21.453 12.978 C 21.771 12.678 21.933 12.324 21.933 11.916 C 21.633 11.676 21.213 11.556 20.673 11.556 C 20.283 11.556 19.953 11.652 19.689 11.838 C 19.431 12.03 19.299 12.264 19.299 12.546 L 19.299 12.546 Z  M 28.161 8.976 L 24.933 16.392 L 23.937 16.392 L 25.137 13.8 L 23.013 8.982 L 24.063 8.982 L 25.593 12.678 L 25.617 12.678 L 27.111 8.982 L 28.161 8.976 Z "
            fill="rgb(95,99,104)"
          />
          <Path
            d=" M 10.467 10.416 C 10.467 10.122 10.443 9.828 10.395 9.54 L 6.321 9.54 L 6.321 11.202 L 8.655 11.202 C 8.559 11.736 8.247 12.216 7.791 12.516 L 7.791 13.596 L 9.183 13.596 C 9.999 12.846 10.467 11.736 10.467 10.416 Z "
            fill="rgb(66,133,244)"
          />
          <Path
            d=" M 6.321 14.64 C 7.485 14.64 8.469 14.256 9.183 13.596 L 7.791 12.516 C 7.401 12.78 6.903 12.93 6.321 12.93 C 5.193 12.93 4.239 12.168 3.897 11.148 L 2.463 11.148 L 2.463 12.264 C 3.195 13.716 4.689 14.64 6.321 14.64 Z "
            fill="rgb(52,168,83)"
          />
          <Path
            d=" M 3.897 11.148 C 3.717 10.614 3.717 10.032 3.897 9.492 L 3.897 8.382 L 2.463 8.382 C 1.846 9.6 1.846 11.04 2.463 12.258 L 3.897 11.148 Z "
            fill="rgb(251,188,4)"
          />
          <Path
            d=" M 6.321 7.71 C 6.939 7.698 7.533 7.932 7.977 8.358 L 9.207 7.128 C 8.427 6.39 7.395 5.988 6.321 6 C 4.689 6 3.195 6.924 2.463 8.382 L 3.897 9.498 C 4.239 8.472 5.193 7.71 6.321 7.71 Z "
            fill="rgb(234,67,53)"
          />
        </G>
      </Svg>
    );
  }
}
