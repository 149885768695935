import moment from 'moment';
import React from 'react';
import {
  FlatList,
  StyleSheet,
  View,
  ActivityIndicator,
  ListRenderItemInfo,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import BackSubheader from '../../elements/BackSubheader';
import ProductCategories from '../../elements/cart/ProductCategories';
import Styles from '../../Styles';
import type {ProductCategory} from 'src/types/ProductCategory';
import SearchProduct from '../../elements/cart/SearchProduct';
import SearchField from '../../elements/cart/SearchField';
import TransactionActions from 'src/actions/TransactionActions';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {ProductType} from 'src/api/CartApi';

type ProductCategorySeachScreenProps = {
  locationId: string;
  itemSelected: (scancode: string) => void;
};

type ProductCategorySeachScreenState = {
  products: Array<ProductType>;
  query: string;
  categories: Array<ProductCategory>;
  selectedCategoryKey: string | number;
  selectedCategoryName: string;
};

class ProductCategorySeachScreen extends React.Component<
  ProductCategorySeachScreenProps,
  ProductCategorySeachScreenState
> {
  PAGE_SIZE = 50;
  fetching = false;
  ALL_KEY = 'internal-all';
  startTime: moment.Moment;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ProductCategorySeachScreenProps) {
    super(props);
    this.state = {
      products: [],
      query: '',
      categories: [
        {
          name: Localized.Labels.all,
          key: this.ALL_KEY,
        },
      ],
      selectedCategoryKey: this.ALL_KEY,
      selectedCategoryName: Localized.Labels.all,
    };
    this.startTime = moment();
    this.fetchProducts = this.fetchProducts.bind(this);
    this.fetchCategories = this.fetchCategories.bind(this);
    this.onCategoryPress = this.onCategoryPress.bind(this);
    this.onSearchChanged = this.onSearchChanged.bind(this);
    this.itemSelected = this.itemSelected.bind(this);
    this.renderProduct = this.renderProduct.bind(this);
    this.onBackSelect = this.onBackSelect.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
    this.fetchProducts();

    FirebaseAnalytic.trackEvent(
      'componentDidMount',
      'ProductCategorySearchScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
  }

  async fetchCategories() {
    FirebaseAnalytic.trackEvent(
      'fetchCategories',
      'ProductCategorySearchScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    try {
      let categories = await TransactionActions.getCategories(
        this.props.locationId,
      );
      const categoryKeys = [];
      categories = categories.filter((category) => {
        if (categoryKeys.indexOf(category.key) < 0) {
          categoryKeys.push(category.key);
          return true;
        }

        return false;
      });
      categories.unshift({
        name: Localized.Labels.all,
        key: this.ALL_KEY,
      });
      this.setState({
        categories,
      });

      FirebaseAnalytic.trackEvent(
        'fetchCategories getCategories',
        'ProductCategorySearchScreen',
        {
          ...this.props,
          ...this.state,
          categoryKeys,
          categories,
        },
      );
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ProductCategorySearchScreen:FetchCategories',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async fetchProducts() {
    FirebaseAnalytic.trackEvent(
      'fetchProducts',
      'ProductCategorySearchScreen',
      {
        ...this.props,
        ...this.state,
        fetching: this.fetching,
      },
    );
    if (!this.fetching && this.state.products.length % this.PAGE_SIZE === 0) {
      this.fetching = true;
      let category = this.state.selectedCategoryKey;

      if (category === this.ALL_KEY) {
        category = '';
      }

      try {
        const result = await TransactionActions.searchProducts(
          this.props.locationId,
          this.state.query,
          category,
          this.state.products.length,
          this.PAGE_SIZE,
        );

        if (result && result.length > 0) {
          this.setState({
            products: this.state.products.concat(result),
          });
        }

        this.fetching = false;

        FirebaseAnalytic.trackEvent(
          'fetchProducts',
          'ProductCategorySearchScreen',
          {
            ...this.props,
            ...this.state,
            fetching: this.fetching,
          },
        );
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'ProductCategorySearchScreen:FetchProducts',
          error.message ? error.message : error.toString(),
        );
        this.fetching = false;
      }
    }
  }

  onBackSelect() {
    Events.CartSearch.trackEvent(this.startTime, '', true, '');
  }

  onCategoryPress(category: ProductCategory) {
    FirebaseAnalytic.trackEvent(
      'onCategoryPress',
      'ProductCategorySearchScreen',
      {
        ...this.props,
        ...this.state,
        category,
      },
    );
    if (this.state.selectedCategoryKey !== category.key) {
      Events.CartSearch.trackEvent(this.startTime, '', false, category.name);
      this.setState(
        {
          selectedCategoryKey: category.key,
          selectedCategoryName: category.name,
          products: [],
        },
        () => this.fetchProducts(),
      );
    }
  }

  onSearchChanged(search: string) {
    FirebaseAnalytic.trackEvent(
      'onCategoryPress',
      'ProductCategorySearchScreen',
      {
        ...this.props,
        ...this.state,
        search,
      },
    );
    if (this.state.query !== search) {
      this.setState(
        {
          query: search,
          products: [],
        },
        () => this.fetchProducts(),
      );
    }
  }

  itemSelected(item: ProductType) {
    Events.CartSearch.trackEvent(this.startTime, item.name);
    FirebaseAnalytic.trackEvent('itemSelected', 'ProductCategorySearchScreen', {
      ...this.props,
      ...this.state,
      item,
    });
    NavActions.pop();
    this.props.itemSelected(item.scancode);
  }

  renderProduct({item}: ListRenderItemInfo<ProductType>) {
    return (
      <SearchProduct
        onPress={() => this.itemSelected(item)}
        price={item.price}
        name={item.name}
      />
    );
  }

  render() {
    return (
      <BackSubheader
        title={Localized.Labels.search}
        onBackSelect={this.onBackSelect}
      >
        <View style={styles.content}>
          <SearchField
            onChangeText={this.onSearchChanged}
            strings={Localized}
            value={this.state.query}
          />
          <ProductCategories
            categories={this.state.categories}
            selectedCategory={this.state.selectedCategoryKey}
            style={styles.categories}
            onCategoryPress={this.onCategoryPress}
          />
          <View style={styles.banner}>
            <AVText style={styles.bannerText}>
              {this.state.selectedCategoryName}
            </AVText>
          </View>
          <FlatList
            style={styles.list}
            data={this.state.products}
            onEndReachedThreshold={0.5}
            onEndReached={() => this.fetchProducts()}
            renderItem={this.renderProduct}
            keyExtractor={(product) => product.id}
            ListEmptyComponent={
              <View style={styles.loadingContainer}>
                <ActivityIndicator color={Styles.primaryColor} size="large" />
              </View>
            }
          />
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  banner: {
    alignItems: 'flex-start',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: Styles.borderSeparatorColor,
    justifyContent: 'center',
    marginTop: Styles.Spacing.m1,
  },
  bannerText: {
    color: Styles.black,
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    marginBottom: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m3,
  },
  categories: {
    marginVertical: Styles.Spacing.m2,
  },
  content: {
    flex: 1,
    paddingTop: Styles.Spacing.m2,
  },
  list: {
    backgroundColor: Styles.white,
    flex: 1,
  },
  loadingContainer: {
    marginTop: Styles.Spacing.m3,
  },
  searchField: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m1,
  },
  searchText: {
    flex: 1,
    fontSize: Styles.Fonts.f1,
    marginLeft: Styles.Spacing.m2,
  },
});

export default withForwardedNavigationParams<ProductCategorySeachScreenProps>()(
  ProductCategorySeachScreen,
);
