import React from 'react';
import CreateAccountPrivacyPolicyHandler from '../elements/createAccount/CreateAccountPrivacyPolicyHandler';
import ScreenContext from '../ScreenContext';
import type {SnackAction} from 'src/types/Snack';
import TextInput from '../elements/AVTextInput';
import {AppDispatch} from 'src/redux/store';
import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  Linking,
  AppState,
} from 'react-native';
import Styles from 'src/components/Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import PersistentStore from 'src/services/PersistentStoreService';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import AppRoutes from 'src/AppRoutes';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {ScreenWidth} from 'react-native-elements/dist/helpers';
import FocusAwareStatusBar from 'src/components/elements/FocusAwareStatusBar';
import UIManager from 'src/components/elements/ui/UIManager';
import DeepLinkHandler from 'src/components/elements/DeepLinkHandler';
import Logo from 'src/components/elements/Logo';
import KeyboardAvoidingView from 'src/components/elements/365KeyboardAvoidingView';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import NavActions from 'src/actions/NavActions';
import Header from 'src/components/elements/Header';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {EnvironmentKey} from 'src/models/Environment';
import {RootState} from 'src/redux/store';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {confirm, confirmNonDismissable} from '../helpers/AlertHelper';
import {fetchRemoteConfig} from 'src/redux/slices/firebaseRemoteConfigSlice';

type WelcomeProps = {
  snack?: SnackAction;
  env: EnvironmentKey;
  firebaseConfig: object;
  dispatch: AppDispatch;
};

type WelcomeState = {
  fullVersion: string;
  touches: number;
  appState: string;
};

type SettingsModel = {
  demo: boolean;
  env: string;
  gma: boolean;
  debug: boolean;
  retry: boolean;
};

const backgroundColor = Platform.select({
  web: 'white',
  default: Styles.loginBackground,
});

const BACKGROUND_WIDTH = ScreenWidth * 1.1;
let appUpdateAlertShown = false;

export class WelcomeScreen extends React.Component<WelcomeProps, WelcomeState> {
  password: TextInput | null;
  privacyHandler: CreateAccountPrivacyPolicyHandler | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  static saveSettingChanges(model: SettingsModel) {
    //TODO will move static func to EnvironmentScreen in componentWillUnmount
    // replace for this.props.saveChanges(this.state);
    ActionsFactory.getAccountActions().isDemoChanged(model.demo);
    // ActionsFactory.getAccountActions().environmentChanged(model.env);
    ActionsFactory.getAccountActions().useGmaChanged(model.gma);
    ActionsFactory.getAccountActions().isDebugChanged(model.debug);
  }

  constructor(props: WelcomeProps) {
    super(props);
    this.state = {
      touches: 0,
      fullVersion: '',
      appState: AppState.currentState,
    };

    this.logoPress = this.logoPress.bind(this);
    this.goToEnvironmentSettings = this.goToEnvironmentSettings.bind(this);
  }

  _isNoAppUpdateAlertShowing = () => !appUpdateAlertShown;

  async componentDidMount() {
    const loggedIn = await this.checkIfLoggedIn();
    FirebaseAnalytic.trackEvent('componentDidMount', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      loggedIn,
    });
    if (!loggedIn) {
      const fullVersion = await Settings.getFullDisplayVersion(Platform.OS);
      this.setState({
        fullVersion,
      });
    }
    AppState.addEventListener('change', this._handleAppStateChange);

    this.props.dispatch(fetchRemoteConfig(false));
  }

  _handleAppStateChange = (nextAppState) => {
    switch (nextAppState) {
      case 'active':
        if (
          this._isNoAppUpdateAlertShowing() &&
          this._enableShowAlertAfterMinimized()
        ) {
          this.props.dispatch(fetchRemoteConfig(true));
        }

        break;
    }
  };

  _enableShowAlertAfterMinimized = () => Platform.OS == 'ios';

  logoPress() {
    const touches = this.state.touches + 1;
    FirebaseAnalytic.trackEvent('logoPress', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      touches,
    });
    if (touches >= 5) {
      this.setState({
        touches: 0,
      });
      this.goToEnvironmentSettings();
    } else {
      this.setState({
        touches,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<WelcomeProps>): void {
    this.showAppUpdateAlert(prevProps);
  }

  showAppUpdateAlert = (prevProps) => {
    const firebaseConfig = this.props.firebaseConfig;
    if (
      firebaseConfig !== null &&
      prevProps.firebaseConfig !== firebaseConfig
    ) {
      if (firebaseConfig['softUpdate'] && this._isNoAppUpdateAlertShowing()) {
        appUpdateAlertShown = true;
        confirm(
          firebaseConfig['alertMessage'],
          () => {
            appUpdateAlertShown = false;
            Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
            this.trackEvent('softUpdateAlertLinkClick', 'WelcomeScreen');
          },
          () => {
            appUpdateAlertShown = false;
            this.trackEvent('softUpdateAlertCloseClick', 'WelcomeScreen');
          },
          firebaseConfig['alertTitle'],
          firebaseConfig['alertCancelButtonTitle'],
          firebaseConfig['alertUpdateButtonTitle'],
        );
        this.trackEvent('softUpdateAlert', 'WelcomeScreen');
      } else {
        this.showForceUpdateAlert(firebaseConfig);
      }
    }
  };

  trackEvent(event: string, screen: string) {
    FirebaseAnalytic.trackEvent(event, screen, {
      ...this.props,
      ...this.state,
    });
  }

  showForceUpdateAlert(firebaseConfig) {
    appUpdateAlertShown = true;
    confirmNonDismissable(
      firebaseConfig['alertMessage'],
      () => {
        this.showForceUpdateAlert(firebaseConfig);
        Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
        this.trackEvent('forceUpdateAlertLinkClick', 'WelcomeScreen');
      },
      firebaseConfig['alertTitle'],
      firebaseConfig['alertUpdateButtonTitle'],
    );
    this.trackEvent('forceUpdateAlert', 'WelcomeScreen');
  }

  goToEnvironmentSettings() {
    const env = this.props.env;
    FirebaseAnalytic.trackEvent('goToEnvironmentSettings', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
    });
    NavActions.push(AppRoutes.Environment, {
      saveChanges: WelcomeScreen.saveSettingChanges,
      env,
      demo: AccountStore.isDemo(),
      debug: AccountStore.isDebug(),
      showRetry: Platform.OS === 'android',
      showGma: true,
      showDemo: true,
      showDebug: true,
      showLanguage: true,
      showCreateAccount: true,
      onCountrySelectionPress: this.onCountrySelectionPress,
    });
  }

  onCountrySelectionPress = () => NavActions.push(AppRoutes.CountrySelection);

  async checkIfLoggedIn() {
    const accDetail = await PersistentStore.getAccountDetails();
    FirebaseAnalytic.trackEvent('checkIfLoggedIn', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      accDetail,
    });
    if (accDetail && accDetail.accountId !== '-1' && accDetail.username) {
      this.context.actions.changeRootRoute(AppRoutes.App);
      return true;
    }

    return false;
  }

  onUsedApp = () => NavActions.push(AppRoutes.WelcomeAuth0);

  onNeverUsedApp = () => NavActions.push(AppRoutes.NeverUsedApp);

  render() {
    let Wrapper: React.FC<React.PropsWithChildren<object>> = ({children}) => (
      <View style={Styles.Style.flex}>{children}</View>
    );
    if (Platform.OS === 'web') {
      Wrapper = ({children}) => (
        <Header title={Localized.Labels.welcome_back}>{children}</Header>
      );
    }

    return (
      <Wrapper>
        <View style={styles.view}>
          <FocusAwareStatusBar
            barStyle={UIManager.getLoginStatusBarStyle()}
            backgroundColor={Styles.loginBackground}
          />
          <DeepLinkHandler />
          <CreateAccountPrivacyPolicyHandler
            ref={(privacyHandler) => {
              this.privacyHandler = privacyHandler;
            }}
          />

          <View style={styles.logoContainer}>
            <TouchableWithoutFeedback
              accessible={false}
              onPress={this.logoPress}
            >
              <View>
                <Logo />
              </View>
            </TouchableWithoutFeedback>
          </View>

          <KeyboardAvoidingView style={styles.container} behavior="height">
            <ScrollView
              horizontal={false}
              contentContainerStyle={styles.content}
              keyboardDismissMode="interactive"
              automaticallyAdjustContentInsets={false}
              keyboardShouldPersistTaps="handled"
            >
              <View style={styles.welcomeView}>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                  testID="welcomeHeaderText"
                  accessible={true}
                  accessibilityLabel="welcome"
                  accessibilityRole="header"
                  style={styles.instructions}
                >
                  {Localized.Labels.welcome}
                </AVText>
                <RoundedButton
                  accessible={true}
                  testID={'usedThisAppButton'}
                  accessibilityHint="Go to the login screen"
                  accessibilityRole="button"
                  buttonType={ButtonType.outline}
                  color={Styles.white}
                  containerStyle={styles.topButton}
                  textStyle={styles.textStyle}
                  onPress={this.onUsedApp}
                  text={Localized.Buttons.used_this_app}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                />
                <RoundedButton
                  accessible={true}
                  testID="neverUsedThisAppButton"
                  accessibilityHint="Create a new account"
                  accessibilityRole="button"
                  buttonType={ButtonType.outline}
                  color={Styles.white}
                  containerStyle={styles.topButton}
                  textStyle={styles.textStyle}
                  onPress={this.onNeverUsedApp}
                  text={Localized.Buttons.never_used_this_app}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
                />
              </View>
            </ScrollView>
            <AVText
              accessible={true}
              accessibilityLabel={`Application version: ${this.state.fullVersion}`}
              style={styles.versionText}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            >
              {this.state.fullVersion}
            </AVText>
          </KeyboardAvoidingView>
        </View>
      </Wrapper>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    backgroundColor,
    flex: 1,
  },
  logoContainer: {
    alignSelf: 'center',
    height: Styles.Heights.h3,
    justifyContent: 'center',
    paddingTop: Styles.Spacing.m3,
    flex: 1,
  },
  container: Platform.select({
    default: {
      backgroundColor: Styles.primaryColor,
      flex: 1,
      borderTopLeftRadius: BACKGROUND_WIDTH,
      borderTopRightRadius: BACKGROUND_WIDTH,
      width: BACKGROUND_WIDTH * 2,
      marginLeft: -(BACKGROUND_WIDTH * 2 - ScreenWidth) / 2,
      alignItems: 'center',
    },
    web: {
      backgroundColor: Styles.primaryColor,
      flex: 1,
      borderTopLeftRadius: '50%',
      borderTopRightRadius: '50%',
      width: '180%',
      marginLeft: '-40%',
      alignItems: 'center',
    } as any,
  }),
  content: {
    marginBottom: Styles.Spacing.m5,
    width: ScreenWidth,
  },
  instructions: {
    color: Styles.white,
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: Styles.Spacing.m3,
  },

  versionText: Platform.select({
    default: {
      color: Styles.white,
      backgroundColor: Styles.transparent,
      fontSize: Styles.Fonts.f0,
      marginTop: Styles.Spacing.m5,
      marginBottom: Styles.Spacing.m3,
      marginLeft: '-20%',
    },
    web: {
      color: Styles.white,
      backgroundColor: Styles.transparent,
      fontSize: Styles.Fonts.f0,
      marginBottom: Styles.Spacing.m3,
      marginLeft: '-40%',
    } as any,
  }),

  topButton: Platform.select({
    default: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'auto',
      paddingVertical: Styles.Spacing.m1,
    },
    web: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'center',
      paddingVertical: Styles.Spacing.m1,
      minWidth: '30%',
    },
  }),
  textStyle: {
    flex: 1,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color: Styles.white,
  },
  welcomeView: {
    marginHorizontal: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m5,
  },
});

export default compose(
  withForwardedNavigationParams<WelcomeProps>(),
  connect((state: RootState) => ({
    env: state.environment?.env,
    firebaseConfig: state.firebaseConfig?.data,
  })),
)(WelcomeScreen);
