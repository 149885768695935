import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useGlobalize} from 'react-native-globalize';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import NavActions from 'src/actions/NavActions';
import ReferralActions from 'src/actions/ReferralActions';
import AppRoutes from 'src/AppRoutes';
import AVText from 'src/components/elements/AVText';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import SafeAreaView from 'src/components/elements/SafeAreaView';
import LoadingScreen from 'src/components/screens/LoadingScreen';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import AccountStore from 'src/stores/AccountStore';
import {Referral} from 'src/types/Referral';

type AccountReadyProps = {
  referralId: string;
};

const AccountReadyScreen: React.FC<AccountReadyProps> = ({referralId}) => {
  const {formatCurrency} = useGlobalize();
  const [referral, setReferral] = useState<Referral>();

  useEffect(() => {
    const fetchReferral = async () => {
      try {
        const referralDetails = await ReferralActions.getReferralDetails(
          referralId,
        );
        setReferral(referralDetails);
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'ReferralLandingScreen:GetReferralDetails',
          error.message ? error.message : error.toString(),
        );
      }
    };
    fetchReferral();
  }, []);

  const handleClick = () => NavActions.reset(AppRoutes.WelcomeAuth0);

  return !referral ? (
    <LoadingScreen />
  ) : (
    <SafeAreaView style={Styles.Style.flex}>
      <View style={styles.container}>
        <FontAwesome5Pro
          name="ribbon"
          color={Styles.balanceColor}
          size={Styles.Fonts.f6 * 4}
          solid
        />
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels.your_account_is_ready}
          accessibilityRole="text"
          aria-label={Localized.Labels.your_account_is_ready}
          style={styles.title}
        >
          {Localized.Labels.your_account_is_ready}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels.dont_forget_to_check_inbox}
          accessibilityRole="text"
          aria-label={Localized.Labels.dont_forget_to_check_inbox}
          style={styles.text}
        >
          {Localized.Labels.dont_forget_to_check_inbox}{' '}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={`Currency ${formatCurrency(
            referral?.amount ?? 0,
            AccountStore.getCurrency(),
          )}`}
          accessibilityRole="text"
          aria-label={`Currency ${formatCurrency(
            referral?.amount ?? 0,
            AccountStore.getCurrency(),
          )}`}
          style={styles.amount}
        >
          {formatCurrency(referral?.amount ?? 0, AccountStore.getCurrency())}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels.have_fun_shopping}
          accessibilityRole="text"
          aria-label={Localized.Labels.have_fun_shopping}
          style={styles.text}
        >
          {Localized.Labels.have_fun_shopping}
        </AVText>

        <View style={styles.bottomContainer}>
          <View style={styles.buttonContainer}>
            <RoundedButton
              buttonType={ButtonType.normal}
              onPress={() => handleClick()}
              containerStyle={[
                styles.button,
                {
                  marginRight: Styles.Spacing.m3,
                },
              ]}
              accessible={true}
              accessibilityLabel={Localized.Buttons.just_get_me_started}
              accessibilityRole="button"
              role="button"
              aria-label={Localized.Buttons.just_get_me_started}
              text={Localized.Buttons.just_get_me_started}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.white,
    alignItems: 'center',
    padding: Styles.Spacing.m4,
  },
  title: {
    fontWeight: 'bold',
    fontSize: Styles.Fonts.f2,
    textAlign: 'center',
    marginTop: Styles.Spacing.m3,
  },
  amount: {
    fontSize: Styles.Fonts.f6,
    fontWeight: 'bold',
    color: Styles.balanceColor,
  },
  text: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
  },
  bottomContainer: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    padding: Styles.Spacing.m1,
  },
});

export default withForwardedNavigationParams<AccountReadyProps>()(
  AccountReadyScreen,
);
