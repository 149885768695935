import {createAsyncThunk} from '@reduxjs/toolkit';
import MenuService from 'src/services/MenuService';
import PlatformApi from 'src/api/PlatformApi';
import {RecentOrderType} from 'src/types/RecentOrders';
import {RootState} from '../../store';

export type GetRecentOrdersParams = {
  locationId: string;
  numDays?: number;
};
export const getRecentOrders = createAsyncThunk<
  Array<RecentOrderType>,
  GetRecentOrdersParams,
  {state: RootState}
>('menu/getRecentOrders', async ({locationId, numDays}, {getState}) => {
  const accountId = getState().account.account.id;
  const response = await PlatformApi.fetchSalesOrders(
    accountId,
    locationId,
    numDays,
  );
  if (response.length > 0) {
    return MenuService.updateOrdersFromMenu(response);
  }

  return [];
});
